"use client";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  type AnimationPlaybackControls,
  type Easing,
  animate,
  useMotionValue,
} from "motion/react";

type DelimiterType = "char" | "word";

interface AnimatedTextConfig {
  duration?: number;
  delay?: number;
  delimiterType?: DelimiterType;
  ease?: Easing;
  skipAnimation?: boolean; // Add skipAnimation option
}

const defaultConfig: Required<Omit<AnimatedTextConfig, "skipAnimation">> = {
  duration: 3,
  delay: 0,
  delimiterType: "char",
  ease: "easeOut",
};

const getDelimiter = (type: DelimiterType): string => {
  return type === "char" ? "" : " ";
};

export function useAnimatedText(
  text: string,
  config: AnimatedTextConfig = {}
): [string, () => Promise<void>] {
  const { duration, delay, delimiterType, ease, skipAnimation } = {
    ...defaultConfig,
    ...config,
  };
  const delimiter = getDelimiter(delimiterType);
  const splitText = useMemo(() => text.split(delimiter), [text, delimiter]);
  const [displayedText, setDisplayedText] = useState("");
  const progress = useMotionValue(0);
  const controlsRef = useRef<AnimationPlaybackControls | null>(null);

  const reset = useCallback(async () => {
    if (controlsRef.current) {
      controlsRef.current.stop();
    }

    // If skipping animation, just show the full text
    if (skipAnimation) {
      setDisplayedText(text);
      progress.set(1);
      return;
    }

    setDisplayedText("");
    progress.set(0);

    if (!text) return;

    controlsRef.current = animate(progress, 1, {
      duration,
      delay,
      ease,
      onUpdate(latest) {
        const index = Math.floor(latest * splitText.length);
        if (index >= 2) {
          setDisplayedText(splitText.slice(0, index + 1).join(delimiter));
        }
      },
      onComplete() {
        setDisplayedText(text);
      },
    });
  }, [
    text,
    splitText,
    delimiter,
    duration,
    delay,
    ease,
    progress,
    skipAnimation,
  ]);

  useEffect(() => {
    reset();
    return () => {
      controlsRef.current?.stop();
    };
  }, [text, splitText, delimiter, duration, delay, ease, skipAnimation, reset]);

  return [
    displayedText.length >= 2 ? displayedText : skipAnimation ? text : " ",
    reset,
  ];
}
