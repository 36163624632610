import { cartOptionalSchema } from "../schemas/cartSchema";
import { childDetailsOptionalSchema } from "../schemas/childDetailsSchema";
import { customerDetailsOptionalSchema } from "../schemas/customerDetailsSchema";
import { dedicationMessageSchema } from "../schemas/dedicationMessageSchema";
import { FullStories, fullSoriesSchema } from "../schemas/fullStoriesSchema";
import { occasionOptionalSchema } from "../schemas/occasionSchema";
import { personalisationSchema } from "../schemas/personalisationSchema";
import { similarityOptionalSchema } from "../schemas/similaritySchema";
import { StoryIdeas, storyIdeasSchema } from "../schemas/storyIdeasSchema";
import { themeSchema } from "../schemas/themeSchema";
import {
  UploadedImage,
  uploadedImagesSchema,
} from "../schemas/uploadedImageSchema";
import { createLocalStorageMiddleware } from "./localStorageMiddleware";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const orderFormDataOptionalSchema = childDetailsOptionalSchema
  .merge(occasionOptionalSchema)
  .merge(themeSchema)
  .merge(personalisationSchema)
  .merge(dedicationMessageSchema)
  .merge(customerDetailsOptionalSchema)
  .merge(similarityOptionalSchema)
  .merge(cartOptionalSchema);

export type OrderFormDataOptional = z.infer<typeof orderFormDataOptionalSchema>;

interface GlobalStore {
  deviceId: string;
  setDeviceId: (deviceId: string) => void;
  images: UploadedImage[];
  storyIdeas: StoryIdeas;
  fullStories?: FullStories | null;
  setFullStories: (stories: FullStories) => void;
  updateStoryIdeas: (data: Partial<StoryIdeas>) => void;
  setStoryIdeas: (data: Partial<StoryIdeas>) => void;
  resetStoryIdeas: () => void;
  resetSingleStoryIdea: (storyNumber: number) => void;
  addStoryPersonalisationTag: (storyNumber: number, tag: string) => void;
  removeStoryPersonalisationTag: (storyNumber: number, tag: string) => void;
  order_reference_id: string;
  addImage: (image: UploadedImage) => void;
  removeImage: (name: string) => void;
  setImages: (images: UploadedImage[]) => void;
  formData: OrderFormDataOptional;
  getFormData: <T>(schema: z.Schema<T>) => T | undefined;
  saveFormData: (data: OrderFormDataOptional) => void;
  lastUpdate: number;
  resetStore: (order_reference_id?: string, callback?: () => void) => void;
  // Edited stories
  editedStories: Record<
    number,
    {
      title: string;
      text: string;
      originalTitle: string;
      originalText: string;
      story_id?: number;
    }
  >;
  updateEditedStory: (
    storyIndex: number,
    title: string,
    text: string,
    originalTitle: string,
    originalText: string,
    story_id?: number
  ) => void;
  revertEditedStory: (storyIndex: number) => void;
  revertAllEditedStories: () => void;
}

const initialStoryIdeasState = {
  story_title_1: "",
  story_summary_1: "",
  story_1_personalisation_tags: "",
  story_title_2: "",
  story_summary_2: "",
  story_2_personalisation_tags: "",
  story_title_3: "",
  story_summary_3: "",
  story_3_personalisation_tags: "",
  story_title_4: "",
  story_summary_4: "",
  story_4_personalisation_tags: "",
  story_title_5: "",
  story_summary_5: "",
  story_5_personalisation_tags: "",
  story_title_6: "",
  story_summary_6: "",
  story_6_personalisation_tags: "",
};

export const createNewOrderFormState = (order_reference_id = uuidv4()) => {
  const orderFormState = {
    deviceId: "",
    images: [],
    fullStories: [],
    storyIdeas: initialStoryIdeasState,
    order_reference_id: order_reference_id,
    formData: {},
    lastUpdate: Date.now(),
    editedStories: {},
  };

  Sentry.setTag("order_reference_id", orderFormState.order_reference_id);
  return orderFormState;
};

const orderFormStateSchema = z.object({
  deviceId: z.string(),
  images: uploadedImagesSchema,
  fullStories: fullSoriesSchema.optional().nullable(),
  storyIdeas: storyIdeasSchema,
  order_reference_id: z.string(),
  formData: orderFormDataOptionalSchema,
  lastUpdate: z.number(),
  // Edited stories
  editedStories: z
    .record(
      z.string(),
      z.object({
        title: z.string(),
        text: z.string(),
        originalTitle: z.string(),
        originalText: z.string(),
        story_id: z.number().optional(),
      })
    )
    .optional()
    .default({}),
});

const loadInitialState = () => {
  let orderFormState;
  try {
    const storedForm = localStorage.getItem("br_order_form");
    if (!storedForm) {
      orderFormState = createNewOrderFormState();
      return orderFormState;
    }
    const formData = JSON.parse(storedForm!);
    orderFormState = orderFormStateSchema.parse(formData);
    if (process.env.NODE_ENV === "development") {
      console.log("Loaded initial state from localStorage:", orderFormState);
    }
    Sentry.setTag("order_reference_id", orderFormState.order_reference_id);
  } catch (error) {
    if (process.env.NODE_ENV === "development") {
      console.error("Failed to load initial state from localStorage:", error);
    }
    orderFormState = createNewOrderFormState();
  }
  return orderFormState;
};

export const useGlobalStore = create<GlobalStore>()(
  devtools(
    createLocalStorageMiddleware("br_order_form")((set, get) => ({
      ...loadInitialState(),
      setDeviceId: (deviceId) => set(() => ({ deviceId })),
      updateStoryIdeas: (data) =>
        set((state) => {
          const update: Partial<StoryIdeas> = {};
          Object.keys(data).forEach((key) => {
            const typedKey = key as keyof StoryIdeas;
            update[typedKey] = state.storyIdeas[typedKey] + data[typedKey];
          });
          const updatedStoryIdeas = { ...state.storyIdeas, ...update };
          return {
            storyIdeas: updatedStoryIdeas,
            formData: {
              ...state.formData,
              story_ideas: updatedStoryIdeas,
            },
          };
        }),
      setFullStories: (stories: FullStories) =>
        set(() => ({ fullStories: stories })),
      setStoryIdeas: (data) =>
        set((state) => ({
          storyIdeas: data,
          formData: {
            ...state.formData,
            story_ideas: data,
          },
          lastUpdate: Date.now(),
        })),
      resetStoryIdeas: () =>
        set((state) => ({
          storyIdeas: initialStoryIdeasState,
          formData: {
            ...state.formData,
            story_ideas: initialStoryIdeasState,
          },
          lastUpdate: Date.now(),
        })),
      resetSingleStoryIdea: (storyNumber: number) =>
        set((state) => {
          const updatedStoryIdeas = {
            ...state.storyIdeas,
            [`story_title_${storyNumber}`]: "",
            [`story_summary_${storyNumber}`]: "",
          };
          return {
            storyIdeas: updatedStoryIdeas,
            formData: {
              ...state.formData,
              story_ideas: updatedStoryIdeas,
            },
            lastUpdate: Date.now(),
          };
        }),
      addImage: (image) =>
        set((state) => {
          const existingImageIndex = state.images.findIndex(
            (img) => img.original_file_name === image.original_file_name
          );
          if (existingImageIndex > -1) {
            return {
              images: [
                ...state.images.slice(0, existingImageIndex),
                image,
                ...state.images.slice(existingImageIndex + 1),
              ],
            };
          }
          return {
            images: [...state.images, image],
          };
        }),
      setImages: (images) => set(() => ({ images })),
      removeImage: (name) =>
        set((state) => ({
          images: state.images.filter(
            (image) => image.original_file_name !== name
          ),
        })),
      saveFormData: (update) =>
        set((state) => ({
          formData: { ...state.formData, ...update },
          lastUpdate: Date.now(),
        })),
      getFormData: (schema) => {
        const state = get();
        const formData = {
          ...state.formData,
          story_ideas: state.storyIdeas, // Inject the current storyIdeas
        };
        const result = schema.safeParse(formData);
        if (!result.success) {
          console.error(
            "Form data validation failed:",
            JSON.stringify(result.error.issues, null, 2)
          );
        }
        return result.data;
      },
      resetStore: (order_reference_id, callback) => {
        if (process.env.NODE_ENV === "development") {
          const storedData = localStorage.getItem("br_order_form");
          console.log("Resetting store...", order_reference_id, storedData);
        }
        localStorage.removeItem("br_order_form");
        localStorage.removeItem("br_opened_checkout");
        set(() => ({ ...createNewOrderFormState(order_reference_id) }));
        if (callback) setTimeout(callback, 0);
      },
      addStoryPersonalisationTag: (storyNumber: number, tag: string) =>
        set((state) => {
          const key =
            `story_${storyNumber}_personalisation_tags` as keyof StoryIdeas;
          const currentTags = state.storyIdeas[key];
          const tagsArray = currentTags ? currentTags.split("|") : [];
          if (!tagsArray.includes(tag)) {
            return {
              storyIdeas: {
                ...state.storyIdeas,
                [key]: tagsArray.length > 0 ? `${currentTags}|${tag}` : tag,
              },
            };
          }
          return state;
        }),
      removeStoryPersonalisationTag: (storyNumber: number, tag: string) =>
        set((state) => {
          const key =
            `story_${storyNumber}_personalisation_tags` as keyof StoryIdeas;
          const currentTags = state.storyIdeas[key];
          const tagsArray = currentTags
            ? currentTags.split("|").filter((t: string) => t !== tag)
            : [];
          return {
            storyIdeas: {
              ...state.storyIdeas,
              [key]: tagsArray.join("|"),
            },
          };
        }),
      // Edited stories
      updateEditedStory: (
        storyIndex: number,
        title: string,
        text: string,
        originalTitle: string,
        originalText: string,
        story_id?: number
      ) =>
        set((state) => {
          // If this story already has an entry, preserve the original values
          const existingEntry = state.editedStories[storyIndex];
          return {
            editedStories: {
              ...state.editedStories,
              [storyIndex]: {
                title,
                text,
                // If there's an existing entry, use its original values, otherwise use the provided ones
                originalTitle: existingEntry
                  ? existingEntry.originalTitle
                  : originalTitle,
                originalText: existingEntry
                  ? existingEntry.originalText
                  : originalText,
                story_id: story_id || existingEntry?.story_id,
              },
            },
          };
        }),

      revertEditedStory: (storyId: number) =>
        set((state) => {
          // Create a new object without the story being reverted
          const newEditedStories = { ...state.editedStories };
          delete newEditedStories[storyId];

          return {
            editedStories: newEditedStories,
            lastUpdate: Date.now(),
          };
        }),

      revertAllEditedStories: () => set({ editedStories: {} }),
    }))
  )
);

export const setOpenedCheckout = () => {
  localStorage.setItem("br_opened_checkout", "true");
};

export const isOpenedCheckout = () => {
  return localStorage.getItem("br_opened_checkout") === "true";
};

export const removeOpenedCheckout = () => {
  localStorage.removeItem("br_opened_checkout");
};
