import { useCallback, useEffect, useRef, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { ChevronDown } from "lucide-react";
import { AnimatePresence, motion } from "motion/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { FormBody } from "@/components/FormBody";
import { FormHeader } from "@/components/FormHeader";
import { ContinueWhereYouLeftOff } from "@/components/orderForm/continueWhereYouLeft";
import { Layout } from "@/components/ui/Layout";
import { Button } from "@/components/ui/button";
import { STEPS } from "@/lib/config/steps";
import { useStoryIdeasStreaming } from "@/lib/hooks/useStoryIdeas";
import { useSubmitFormData } from "@/lib/hooks/useSubmitFormData";
import { childDetailsSchema } from "@/lib/schemas/childDetailsSchema";
import { occasionSchema } from "@/lib/schemas/occasionSchema";
import {
  Personalisation,
  personalisationSchema,
} from "@/lib/schemas/personalisationSchema";
import { useGlobalStore } from "@/lib/store/global";
import { useStepNavigation } from "@/lib/utils/useStepNavigation";
import { StoryElements } from "@/modules/personalisation/story-elements";
import {
  StoryOutlines,
  hasSomeStorieOutlinesGenerated,
} from "@/modules/personalisation/story-outlines";
import type { Categories } from "@/modules/personalisation/types";

const CURRENT_STEP = STEPS.STEP_3 as keyof typeof STEPS;

export const personalisationPageSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(personalisationSchema);

export function PersonalisationPage() {
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);
  const { getFormData } = useGlobalStore();
  const data = getFormData(personalisationPageSchema);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <PersonalisationForm data={data} /> : null;
}

export function PersonalisationForm({
  data,
}: Readonly<{
  data: z.infer<typeof personalisationPageSchema>;
}>) {
  const {
    getFormData,
    saveFormData,
    order_reference_id,
    formData,
    storyIdeas,
  } = useGlobalStore();
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending } = useSubmitFormData(CURRENT_STEP);
  const { startStoryIdeasStream, isStreamingInProgress } =
    useStoryIdeasStreaming();

  const queryClient = useQueryClient();

  const [generationIndex, setGenerationIndex] = useState(0);
  const formRef = useRef<HTMLFormElement | null>(null);
  // Add state for available tags
  const [availableTags, setAvailableTags] = useState<
    Record<Categories, string[]>
  >({
    themes: [],
    people: [],
    animals: [],
    interests: [],
    goals: [],
    places: [],
    styles: [],
  });

  const { handleSubmit, formState, setValue } = useForm<Personalisation>({
    resolver: zodResolver(personalisationSchema),
    defaultValues: data,
  });

  const onSubmit: SubmitHandler<Personalisation> = useCallback(
    async (data) => {
      const persistedFormData = getFormData(personalisationPageSchema);
      const allFormData = { ...persistedFormData, ...data };

      // Remove empty arrays and strings from the form data
      const cleanFormData = Object.entries(allFormData).reduce(
        (acc, [key, value]) => {
          if (Array.isArray(value) && value.length === 0) {
            return acc;
          }
          if (typeof value === "string" && value.trim() === "") {
            return acc;
          }
          return { ...acc, [key]: value };
        },
        {}
      );

      const finalData = { ...cleanFormData, story_ideas: storyIdeas };

      // FIXME: Because of the way the form data is received, the storyIdeas are
      // saved with the form data and currently duplicated in the local storage.
      // @see lib/services/suggestions/shared/suggestionService.ts
      saveFormData(finalData);
      await submitData();

      // Restore the form data without the story ideas after submission
      // to avoid schema validation errors with other API services (suggestions).
      saveFormData(cleanFormData);

      // Call the API to start writing stories
      if (order_reference_id && storyIdeas) {
        try {
          const writeStoriesInput = formatWriteStoriesInput(
            finalData,
            storyIdeas
          );
          await startWriteStoriesTask(
            order_reference_id,
            writeStoriesInput,
            queryClient
          );
        } catch (error) {
          console.error("Failed to start write stories task:", error);
          // Might want to show an error toast or notification here
        }
      }

      goToNextStep();
    },
    [
      getFormData,
      saveFormData,
      storyIdeas,
      submitData,
      goToNextStep,
      order_reference_id,
      queryClient,
    ]
  );

  const handleContinue = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      handleSubmit(onSubmit)();
    },
    [handleSubmit, onSubmit]
  );

  // const storyOutlinesRef = useRef<HTMLDivElement | null>(null);
  const scrollTimeoutRef = useRef<number | null>(null);

  const handleStartStoryIdeasStream = () => {
    setGenerationIndex((prev) => prev + 1);
    startStoryIdeasStream();

    // if (storyOutlinesRef.current) {
    //   scrollTimeoutRef.current = window.setTimeout(() => {
    //     storyOutlinesRef.current?.scrollIntoView({
    //       behavior: "smooth",
    //       block: "start",
    //     });
    //   }, 1000);
    // }
  };

  useEffect(() => {
    const scrollTimoue = scrollTimeoutRef.current;
    return () => {
      if (scrollTimoue) {
        clearTimeout(scrollTimoue);
      }
    };
  }, []);

  // Initialize available tags with selected tags when component mounts
  useEffect(() => {
    const initialAvailableTags = Object.keys(availableTags).reduce(
      (acc, category) => {
        const categoryKey = category as Categories;
        // Start with empty array for each category
        acc[categoryKey] = [];
        return acc;
      },
      {} as Record<Categories, string[]>
    );

    setAvailableTags(initialAvailableTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPersonalisationTag = (category: Categories, value: string) => {
    if (value && !data[category]?.includes(value)) {
      const newData = {
        ...data,
        [category]: [...(data[category] ?? []), value],
      };

      const tagsInCategory = Array.isArray(newData[category])
        ? newData[category]
        : [];
      if (category in personalisationSchema.shape) {
        setValue(category as keyof Personalisation, tagsInCategory);
      }

      // Remove the tag from available tags if it exists there
      setAvailableTags((prev) => ({
        ...prev,
        [category]: prev[category].filter((tag) => tag !== value),
      }));

      saveFormData(newData);
    }
  };

  const removePersonalisationTag = (category: Categories, value: string) => {
    const newData = {
      ...data,
      [category]: Array.isArray(data[category])
        ? data[category].filter((tag) => tag !== value)
        : [],
    };

    const tagsInCategory = Array.isArray(newData[category])
      ? newData[category]
      : [];
    if (category in personalisationSchema.shape) {
      setValue(category as keyof Personalisation, tagsInCategory);
    }

    // Add the removed tag back to available tags if it's not a custom tag
    if (!value.includes("[custom]")) {
      setAvailableTags((prev) => ({
        ...prev,
        [category]: [...prev[category], value],
      }));
    }

    saveFormData(newData);
  };

  const hasStoryOutlinesGenerated =
    storyIdeas[`story_title_1`]?.length > 0 &&
    storyIdeas[`story_summary_1`]?.length > 0;

  const hasOutlinesGenerated = hasSomeStorieOutlinesGenerated(storyIdeas);

  return (
    <>
      <Layout>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <FormHeader
            title="Stories Personalisation"
            currentStep={CURRENT_STEP}
            onBack={goToPreviousStep}
          />
          <FormBody>
            <div>
              <StoryElements
                data={formData}
                saveFormData={saveFormData}
                addPersonalisationTag={addPersonalisationTag}
                removePersonalisationTag={removePersonalisationTag}
                availableTags={availableTags}
              >
                {!hasOutlinesGenerated && !isStreamingInProgress && (
                  <div className="mt-6">
                    <Button
                      type="button"
                      className="sticky bottom-0 z-10 w-full uppercase"
                      variant="tertiary"
                      disabled={isStreamingInProgress}
                      onClick={handleStartStoryIdeasStream}
                    >
                      {isStreamingInProgress ? (
                        <div>Writing in Progress</div>
                      ) : (
                        `Write Story Summaries`
                      )}
                    </Button>
                  </div>
                )}
              </StoryElements>

              <StoryOutlines
                data={formData}
                storyIdeas={storyIdeas}
                generationIndex={generationIndex}
                isStreamingInProgress={isStreamingInProgress}
                handleStartStoryIdeasStream={handleStartStoryIdeasStream}
              >
                {(hasOutlinesGenerated || isStreamingInProgress) && (
                  <Button
                    key="re-write-stories"
                    type="button"
                    className="sticky bottom-0 z-10 w-full uppercase"
                    variant="tertiary"
                    disabled={isStreamingInProgress}
                    onClick={handleStartStoryIdeasStream}
                  >
                    {`Re-Write Story Summaries`}
                  </Button>
                )}
              </StoryOutlines>
            </div>

            <AnimatePresence>
              {hasStoryOutlinesGenerated && !isStreamingInProgress && (
                <motion.div
                  className="mt-8"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                >
                  <Button
                    variant="tertiary"
                    onClick={handleContinue}
                    disabled={isPending}
                    className="w-full uppercase"
                  >
                    Continue
                  </Button>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {hasStoryOutlinesGenerated && !isStreamingInProgress && (
                <ScrollToBottom />
              )}
            </AnimatePresence>
          </FormBody>
        </form>

        {!formState.isSubmitting && !formState.isSubmitted && (
          <ContinueWhereYouLeftOff currentStep={CURRENT_STEP} />
        )}
      </Layout>

      {/* Temporarily replaced with ScrollToBottom component */}
      {/* <div className="pt-64 sm:pt-52">
        <AnimatePresence>
          {!isStreamingInProgress && (
            <ContinueBanner
              key="continue"
              className="fixed bottom-0 left-0 right-0 z-50 mt-4 border-t"
              isLoading={isPending}
              onContinue={handleContinue}
            />
          )}
        </AnimatePresence>
      </div> */}
    </>
  );
}

function ScrollToBottom() {
  const [isAtBottom, setIsAtBottom] = useState(false);

  const checkScrollPosition = useCallback(() => {
    // Consider "at bottom" when within 100px of the bottom
    const scrollPosition = window.innerHeight + window.scrollY;
    const bodyHeight = document.body.offsetHeight;
    const isBottom = scrollPosition >= bodyHeight - 100;
    setIsAtBottom(isBottom);
  }, []);

  useEffect(() => {
    checkScrollPosition();
    window.addEventListener("scroll", checkScrollPosition);
    return () => window.removeEventListener("scroll", checkScrollPosition);
  }, [checkScrollPosition]);

  const scrollToBottom = useCallback(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }, []);

  if (isAtBottom) return null;

  return (
    <motion.div
      className="pointer-events-none fixed bottom-0 left-0 right-0 z-50"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
    >
      <div className="mx-auto flex justify-end px-5 py-3">
        <Button
          type="button"
          onClick={() => scrollToBottom()}
          className="pointer-events-auto mr-3 h-10 w-10 rounded-full p-0 transition-transform ease-in-out hover:scale-110"
        >
          <div className="animate-bounce-subtle">
            <ChevronDown className="h-6 w-6" />
          </div>
        </Button>
      </div>
    </motion.div>
  );
}

/**
 * API call to start the write stories task
 */

type WriteStoriesInput = {
  num_words_per_story: number;
  country_iso2: string;
  ideas: {
    [key: string]: string;
  };
  hero_name: string;
  hero_dob: string;
  hero_gender: string;
  hero_location: string;
  occasion: string;
  personalisation_note: string;
  themes: string[];
  people: string[];
  animals: string[];
  interests: string[];
  goals: string[];
  places: string[];
  styles: string[];
};

async function startWriteStoriesTask(
  order_reference_id: string,
  storyData: WriteStoriesInput,
  queryClient: QueryClient
) {
  return queryClient.fetchQuery({
    queryKey: ["writeStories", order_reference_id, storyData],
    queryFn: async () => {
      const url = new URL(
        "/preview/order-assistant",
        import.meta.env.VITE_API_URL
      );
      url.searchParams.append("task", "write_stories");
      url.searchParams.append("order_reference_id", order_reference_id);

      const params = {
        write_stories_inputs: storyData,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params),
      });

      if (!response.ok) {
        throw new Error("Failed to start write stories task");
      }

      return await response.json();
    },
  });
}

function formatWriteStoriesInput(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any,
  storyIdeas: Record<string, string>
): WriteStoriesInput {
  return {
    num_words_per_story: 210, // Default value
    country_iso2: formData.country_iso2 || "gb",
    ideas: storyIdeas, // formatStoryIdeasToArray(storyIdeas),
    hero_name: formData.hero_name || "",
    hero_dob: formData.hero_dob || "",
    hero_gender: formData.hero_gender || "",
    hero_location: formData.hero_location || "",
    occasion: formData.occasion || "",
    personalisation_note: formData.personalisation_note || formData.note || "",
    themes: Array.isArray(formData.themes) ? formData.themes : [],
    people: Array.isArray(formData.people) ? formData.people : [],
    animals: Array.isArray(formData.animals) ? formData.animals : [],
    interests: Array.isArray(formData.interests) ? formData.interests : [],
    goals: Array.isArray(formData.goals) ? formData.goals : [],
    places: Array.isArray(formData.places) ? formData.places : [],
    styles: Array.isArray(formData.styles) ? formData.styles : [],
  };
}
