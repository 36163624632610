import { Gender } from "@/lib/schemas/genderSchema";
import { cn } from "@/lib/utils";

type GenderSelectProps = {
  value?: Gender;
  onChange: (value: Gender) => void;
  isError?: boolean;
};

export const GenderSelect = ({
  value,
  onChange,
  isError,
}: GenderSelectProps) => {
  return (
    <div className="flex w-full flex-row gap-2">
      <GenderCard
        name="Boy"
        img="/icons/mars.svg"
        onClick={() => onChange("boy")}
        selected={value === "boy"}
        error={isError}
      />
      <GenderCard
        name="Girl"
        img="/icons/venus.svg"
        onClick={() => onChange("girl")}
        selected={value === "girl"}
        error={isError}
      />
    </div>
  );
};

const GenderCard = ({
  name,
  img,
  onClick,
  selected,
  error,
}: {
  name: string;
  img: string;
  onClick: () => void;
  selected?: boolean;
  error?: boolean;
}) => {
  return (
    <button
      type="button"
      className={cn(
        "flex w-full cursor-pointer flex-row items-center justify-center gap-3 rounded-md border border-border bg-white/40 p-2 text-muted-foreground hover:bg-border hover:bg-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
        selected &&
          "-my-[1px] border-2 border-primary/40 bg-white text-foreground",
        error && "ring-2 ring-destructive/60"
      )}
      onClick={onClick}
    >
      <img
        src={img}
        alt=""
        className={cn(
          "-ml-4 h-6 object-contain contrast-75",
          selected && "contrast-100"
        )}
      />
      <p className="text-xl">{name}</p>
    </button>
  );
};
