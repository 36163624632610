import { useEffect } from "react";
import { STEPS } from "../config/steps";
import { Gender } from "../schemas/genderSchema";
import { UploadedImage } from "../schemas/uploadedImageSchema";
import { useGlobalStore } from "../store/global";
import { FetchError } from "../utils/FetchError";
import { useDisplayErrorToast } from "../utils/useDisplayErrorToast";
import { queryKeys } from "./queryKeys";
import { getStoriesSchema, useStories } from "./useStories";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

// Max polling time 5 minutes (+ latency and response time x300 times)
const MAX_FETCH_ATTEMPTS = 300;
const FETCH_POLL_INTERVAL = 1000;

type GetHeroImagesParams = {
  story_title: string;
  story_text: string;
  hero_gender: Gender;
  hero_image_urls: string[];
};

const heroImagesSchema = z.object({
  hero_preview_image_urls_low_res: z.array(z.string().min(1)).length(4),
  hero_preview_image_urls_high_res: z.array(z.string().min(1)).length(4),
  enhanced: z
    .object({
      image_urls: z.array(z.string().min(1)).length(4).optional(),
    })
    .optional(),
  timestamp: z.number(),
});

export const useHeroImages = () => {
  const { order_reference_id, storyIdeas, images } = useGlobalStore();

  return useQuery({
    queryKey: [
      queryKeys.GET_HERO_IMAGES,
      order_reference_id,
      storyIdeas,
      images,
    ],
    queryFn: () => getHeroImages(order_reference_id),
    retry: shouldRetry,
    retryDelay: FETCH_POLL_INTERVAL,
  });
};

export const useHeroImagesGeneration = ({ start }: { start: boolean }) => {
  const { order_reference_id, getFormData, images, saveFormData } =
    useGlobalStore();
  const queryClient = useQueryClient();
  const dataStories = getFormData(getStoriesSchema);
  const { data: stories, isLoading: isLoadingStories } = useStories({ start });

  const params =
    stories &&
    getParams({
      story_title: stories[0]?.title,
      story_text: stories[0]?.text,
      hero_gender: dataStories!.hero_gender,
      images,
    });

  const keys =
    stories &&
    getHeroImagesQueryKey(
      order_reference_id,
      stories[0]?.title,
      stories[0]?.text,
      images
    );

  const { isError, isSuccess, isLoading, isFetching } = useQuery({
    queryKey: [queryKeys.GENERATE_HERO_IMAGES, keys],
    queryFn: () => startHeroImagesGeneration(order_reference_id, params!),
    enabled: !!params && start,
  });

  useDisplayErrorToast({ isError: start && isError });

  useEffect(() => {
    if (isFetching) {
      queryClient.resetQueries({
        queryKey: [queryKeys.GET_HERO_IMAGES, order_reference_id],
      });
      saveFormData({
        hero_similarity_image_number: undefined,
        illustration_feedback_choice: undefined,
        illustration_feedback: undefined,
      });
    }
  }, [isFetching, order_reference_id, queryClient, saveFormData]);

  return {
    isStartHeroImagesGenerationLoading: isLoading || isLoadingStories,
    isStartHeroImagesGenerationSuccess: isSuccess,
    isStartHeroImagesGenerationError: isError,
  };
};

export async function startHeroImagesGeneration(
  order_reference_id: string,
  params: GetHeroImagesParams
) {
  const url = new URL("/preview/order-assistant", import.meta.env.VITE_API_URL);
  url.searchParams.append("task", "hero_images");
  url.searchParams.append("order_reference_id", order_reference_id);

  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      hero_images_inputs: params,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to start hero images generation");
  }

  return true;
}

export async function getHeroImages(order_reference_id: string) {
  const url = new URL(
    "/preview/order-assistant/get-results",
    import.meta.env.VITE_API_URL
  );
  url.searchParams.append("task", "hero_images");
  url.searchParams.append("order_reference_id", order_reference_id);

  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });

  if (!response.ok) {
    throw new FetchError("Failed to fetch hero images", STEPS.STEP_1, true);
  }

  const data = await response.json();

  const lastItem = data[data.length - 1];

  if (
    lastItem.status === "completed" &&
    lastItem.results &&
    Object.keys(lastItem.results).length === 0
  ) {
    if (lastItem?.error?.message === "Not enough images with faces detected") {
      throw new FetchError(
        "Not enough images with faces detected",
        STEPS.STEP_6,
        true,
        "IMAGE_QUALITY"
      );
    } else if (
      lastItem?.error?.message === "Input images denied due to image filters"
    ) {
      throw new FetchError(
        "Input images denied due to image filters",
        STEPS.STEP_6,
        true,
        "IMAGE_FILTERS"
      );
    } else {
      throw new FetchError(
        "Hero image generation has failed, need to restart it",
        STEPS.STEP_6,
        true
      );
    }
  }

  if (lastItem.status === "pending") {
    // Don't set abortRetry to true for pending status - this is the normal polling case
    throw new FetchError("Hero images are still being generated", STEPS.STEP_6);
  }

  if (lastItem.results.length === 0) {
    // Set abortRetry to true as empty results after a non-pending status indicates an issue
    throw new FetchError("Hero images do not exist", STEPS.STEP_6, true);
  }

  return heroImagesSchema.parse(lastItem.results);
}

export const getParams = ({
  story_title,
  story_text,
  hero_gender,
  images,
}: {
  story_title: string;
  story_text: string;
  hero_gender: "boy" | "girl";
  images: UploadedImage[];
}) => {
  const params: GetHeroImagesParams | undefined =
    story_title && story_text && hero_gender && images
      ? {
          story_title,
          story_text,
          hero_gender,
          hero_image_urls: images.map((img) => img.presigned_url),
        }
      : undefined;

  return params;
};

export const getHeroImagesQueryKey = (
  orderReferenceId: string,
  storyTitle: string,
  storyText: string,
  images: UploadedImage[]
) => ({
  orderReferenceId,
  storyTitle,
  storyText,
  images,
});

const shouldRetry = (failureCount: number, error: FetchError) => {
  if (failureCount >= MAX_FETCH_ATTEMPTS) {
    console.error("Exceeded max fetch attempts for 'getHeroImages'");
    return false;
  }

  if (error.abortRetry) {
    console.error(error);
    return false;
  }

  return true;
};
