import { z } from 'zod';
import { useGlobalStore } from '../store/global';
import {
  ChildDetails,
  childDetailsSchema,
} from '../schemas/childDetailsSchema';
import { Occasion, occasionSchema } from '../schemas/occasionSchema';
import { themeSchema } from '../schemas/themeSchema';
import {
  Personalisation,
  personalisationSchema,
} from '../schemas/personalisationSchema';
import { dedicationMessageSchema } from '../schemas/dedicationMessageSchema';
import { FetchError } from '../utils/FetchError';
import { STEPS } from '../config/steps';
import { useState } from 'react';
import { createStreamReader } from '../utils/streamReader';

type GetStoriesParams = ChildDetails & Occasion & Personalisation;

export const getStoryIdeasSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(themeSchema)
  .merge(personalisationSchema)
  .merge(dedicationMessageSchema);

export const storyIdeasSchema = z.object({
  story_title_1: z.string(),
  story_summary_1: z.string(),
  story_1_personalisation_tags: z.array(z.string()),
  story_title_2: z.string(),
  story_summary_2: z.string(),
  story_2_personalisation_tags: z.array(z.string()),
  story_title_3: z.string(),
  story_summary_3: z.string(),
  story_3_personalisation_tags: z.array(z.string()),
  story_title_4: z.string(),
  story_summary_4: z.string(),
  story_4_personalisation_tags: z.array(z.string()),
  story_title_5: z.string(),
  story_summary_5: z.string(),
  story_5_personalisation_tags: z.array(z.string()),
  story_title_6: z.string(),
  story_summary_6: z.string(),
  story_6_personalisation_tags: z.array(z.string()),
});

export async function getStoryIdeas(order_reference_id: string) {
  const url = new URL(
    '/preview/order-assistant/get-results',
    import.meta.env.VITE_API_URL,
  );
  url.searchParams.append('task', 'write_story_ideas');
  url.searchParams.append('order_reference_id', order_reference_id);

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });

  if (!response.ok) {
    throw new FetchError('Failed to fetch story ideas', STEPS.STEP_1);
  }

  const data = await response.json();
  if (!data || Object.keys(data).length === 0) {
    throw new FetchError('Story ideas do not exist', STEPS.STEP_1);
  }

  return storyIdeasSchema.parse(data);
}

const getParams = (data: z.infer<typeof getStoryIdeasSchema>) => {
  const params: GetStoriesParams | undefined = data
    ? {
        hero_name: data.hero_name,
        hero_gender: data.hero_gender,
        hero_dob: data.hero_dob,
        hero_location: data.hero_location,
        occasion: data.occasion,
        note: data.personalisation_note ?? data.note ?? "",
        themes: data.themes ?? [],
        people: data.people ?? [],
        animals: data.animals ?? [],
        interests: data.interests ?? [],
        goals: data.goals ?? [],
        places: data.places ?? [],
      }
    : undefined;

  return params;
};

export const useStoryIdeasStreaming = () => {
  const { order_reference_id, getFormData, updateStoryIdeas, resetStoryIdeas } =
    useGlobalStore();
  const dataForStories = getFormData(getStoryIdeasSchema);
  const params = getParams(dataForStories!);
  const [isStreamingInProgress, setIsStreamingInProgress] = useState(false);

  const url = new URL('/preview/order-assistant', import.meta.env.VITE_API_URL);
  url.searchParams.append('task', 'write_story_ideas');
  url.searchParams.append('order_reference_id', order_reference_id);

  const startStoryIdeasStream = () => {
    resetStoryIdeas();
    setIsStreamingInProgress(true);

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        write_story_ideas_inputs: {
          country_iso2: 'uk',
          ...params,
        },
      }),
    })
      .then((response) => {
        const { read } = createStreamReader({
          onData: updateStoryIdeas,
          onComplete: () => {
            // console.info("Stream complete");
            setIsStreamingInProgress(false);
          },
        });
        return read(response.body!.getReader());
      })
      .catch((error) => {
        console.error(error);
        setIsStreamingInProgress(false);
      });
  };

  return {
    startStoryIdeasStream,
    isStreamingInProgress,
  };
};

export const useSingleStoryIdeaStreaming = () => {
  const {
    order_reference_id,
    getFormData,
    updateStoryIdeas,
    resetSingleStoryIdea,
  } = useGlobalStore();
  const dataForStories = getFormData(childDetailsSchema);

  const [isStreamingInProgress, setIsStreamingInProgress] = useState(false);
  const url = new URL('/preview/order-assistant', import.meta.env.VITE_API_URL);
  url.searchParams.append('task', 'change_story_idea');
  url.searchParams.append('order_reference_id', order_reference_id);

  const startSingleStoryIdeaStream = ({
    story_num,
    story_title,
    story_text,
    personalisation_tags,
  }: {
    story_num: number;
    story_title: string;
    story_text: string;
    personalisation_tags: string[];
  }) => {
    resetSingleStoryIdea(story_num);
    setIsStreamingInProgress(true);

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        change_story_idea_inputs: {
          country_iso2: 'uk',
          hero_dob: dataForStories?.hero_dob,
          story_num,
          story_title,
          story_text,
          personalisation_tags,
        },
      }),
    })
      .then((response) => {
        const { read } = createStreamReader({
          onData: updateStoryIdeas,
          onComplete: () => {
            // console.info("Stream complete");
            setIsStreamingInProgress(false);
          },
        });
        return read(response.body!.getReader());
      })
      .catch((error) => {
        console.error(error);
        setIsStreamingInProgress(false);
      });
  };

  return {
    startSingleStoryIdeaStream,
    isStreamingInProgress,
  };
};
