import { z } from "zod";

const fullStorySchema = z.object({
  title: z.string(),
  text: z.string(),
  order: z.number(),
  target_language: z.string(),
  title_translated: z.string().nullable(),
  text_translated: z.string().nullable(),
  image_right: z.string().nullable(),
  image_left: z.string().nullable(),
  image_final: z.string().nullable(),
  drawing_style: z.string().nullable(),
  image_right_optimized: z.string().nullable(),
  image_left_optimized: z.string().nullable(),
  image_final_optimized: z.string().nullable(),
  story_id: z.number(),
});

export const fullSoriesSchema = z.array(fullStorySchema);

export type FullStory = z.infer<typeof fullStorySchema>;
export type FullStories = z.infer<typeof fullSoriesSchema>;
