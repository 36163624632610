import { useEffect, useState } from "react";
import { WarnBeforeUnload } from "../WarnBeforeUnload";
import { useNavigate } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { getAllURLSearchParams } from "@/lib/campaign-params";
import { STEPS, getStepNumber } from "@/lib/config/steps";
import {
  ChildDetailsOptional,
  childDetailsOptionalSchema,
} from "@/lib/schemas/childDetailsSchema";
import { createNewOrderFormState, useGlobalStore } from "@/lib/store/global";
import { getStepRoute } from "@/lib/utils/useStepNavigation";

const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * ONE_MINUTE;

export function ContinueWhereYouLeftOff({
  currentStep,
}: {
  currentStep?: keyof typeof STEPS;
}) {
  const {
    saveFormData,
    getFormData,
    resetStore,
    lastUpdate,
    order_reference_id,
  } = useGlobalStore();
  const navigate = useNavigate();

  const [showContinueMessage, setShowContinueMessage] = useState(
    !isEmpty(getFormData(childDetailsOptionalSchema)) &&
      Date.now() > lastUpdate + ONE_HOUR
  );
  useEffect(() => {
    const interval = setInterval(() => {
      setShowContinueMessage(
        !isEmpty(getFormData(childDetailsOptionalSchema)) &&
          Date.now() > lastUpdate + ONE_HOUR
      );
    }, ONE_MINUTE);

    return () => clearInterval(interval);
  }, [getFormData, lastUpdate]);

  if (!showContinueMessage) {
    return <WarnBeforeUnload />;
  }

  function startNewOrder() {
    resetStore(undefined, () => {
      const stepRoute = getStepRoute(getStepNumber(STEPS.STEP_0));
      // navigate(stepRoute);
      // Instead of the above line, we will reload the page to ensure
      // that all the context is cleared and code is up to date.
      const newFormState = createNewOrderFormState();
      saveFormData(newFormState);

      window.location.href = stepRoute;
    });
  }

  function redirectToRehydrate() {
    if (!order_reference_id) {
      navigate("/");
      return;
    }
    if (currentStep) {
      const stepRoute = getStepRoute(getStepNumber(currentStep));

      // Restore the URL parameters from sessionStorage
      const urlParams = getAllURLSearchParams();
      // Create the final URL with the search parameters
      const finalUrl = `${stepRoute}?${urlParams.toString()}&order_reference_id=${order_reference_id}`;

      window.location.href = finalUrl;
      return;
    }
    navigate("?order_reference_id=" + order_reference_id);
  }

  return (
    <AlertDialog defaultOpen>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Continue unfinished order?</AlertDialogTitle>
          <AlertDialogDescription>
            You have an unfinished order. Do you want to continue where you left
            off?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={startNewOrder}>
            Start New
          </AlertDialogCancel>
          <AlertDialogAction onClick={redirectToRehydrate}>
            Continue
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function isEmpty(data?: ChildDetailsOptional) {
  return !data || Object.values(data).filter(Boolean).length < 1;
}
