import { CATEGORIES_WITH_SUGGESTIONS, Categories } from "./types";
import { SuggestionType } from "@/lib/services/suggestions/shared/types";

export const isValidTag = (tag: string): boolean => {
  return /^\[[\w_]+\]\s*.+$/.test(tag.trim());
};

export const capitalizeFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const isCategoryWithSuggestions = (
  category: Categories
): category is Extract<Categories, keyof SuggestionType> => {
  return category in CATEGORIES_WITH_SUGGESTIONS;
};

export const isCategoryWithInfoNote = (category: Categories): boolean => {
  return category === "people" || category === "animals";
};

/**
 * Transform story ideas from the legacy object format to an array of story objects
 */
export function formatStoryIdeasToArray(storyIdeas: Record<string, string>) {
  const storyArray = [];
  const storyNumbers = new Set<number>();

  // Get all unique story numbers
  Object.keys(storyIdeas).forEach((key) => {
    const match = key.match(/^story_(?:title|summary)_(\d+)$/);
    if (match) {
      storyNumbers.add(parseInt(match[1], 10));
    }
  });

  // For each story number, create an object with title, summary, and personalisation tags
  Array.from(storyNumbers)
    .sort((a, b) => a - b)
    .forEach((num) => {
      const titleKey = `story_title_${num}`;
      const summaryKey = `story_summary_${num}`;
      const tagsKey = `story_${num}_personalisation_tags`;

      if (storyIdeas[titleKey] && storyIdeas[summaryKey]) {
        storyArray.push({
          title: storyIdeas[titleKey],
          summary: storyIdeas[summaryKey],
          personalisation_tags: storyIdeas[tagsKey] || "",
        });
      }
    });

  return storyArray;
}
