import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TagInput } from "./components/tag-input";
import { InputTextGroup } from "./components/text-input-group";
import { clearSuggestionsBuffer, getSuggestions } from "./suggestions";
import {
  Categories,
  CategoryConfig,
  CategoryType,
  categoryLabels,
} from "./types";
import {
  capitalizeFirstLetter,
  isCategoryWithInfoNote,
  isCategoryWithSuggestions,
} from "./utils";
import { useQueryClient } from "@tanstack/react-query";
import {
  CastleIcon,
  GoalIcon,
  HeartIcon,
  MessageCircleHeartIcon,
  PaletteIcon,
  UserIcon,
  WandSparklesIcon,
} from "lucide-react";
import { AnimatePresence, motion } from "motion/react";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { childDetailsSchema } from "@/lib/schemas/childDetailsSchema";
import { occasionSchema } from "@/lib/schemas/occasionSchema";
import { personalisationSchema } from "@/lib/schemas/personalisationSchema";
import type { SuggestionType } from "@/lib/services/suggestions/shared/types";
import { useGlobalStore } from "@/lib/store/global";
import { cn } from "@/lib/utils";

// Add a utility function to map category to book_category key
const getBookCategoryKey = (category: Categories): string => {
  return `book_${category}`;
};

// Get combined tags from both regular and book_ prefixed keys
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCombinedTags = (data: any, category: Categories): string[] => {
  const regularTags = data[category] || [];
  const bookPrefixKey = getBookCategoryKey(category);
  const bookPrefixedTags = data[bookPrefixKey] || [];

  return [...regularTags, ...bookPrefixedTags];
};

const personalisationPageSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(personalisationSchema);

export const renderCategoryIcon = (
  category: CategoryType,
  strokeWidth: number = 2
) => {
  switch (category) {
    case "animals":
      return <HeartIcon size={16} strokeWidth={strokeWidth} />;
    case "goals":
      return <GoalIcon size={16} strokeWidth={strokeWidth} />;
    case "interests":
      return <MessageCircleHeartIcon size={16} strokeWidth={strokeWidth} />;
    case "hero_location":
    case "location":
    case "places":
      return <CastleIcon size={16} strokeWidth={strokeWidth} />;
    case "personalisation_note":
    case "note":
    case "occasion":
      return <WandSparklesIcon size={16} strokeWidth={strokeWidth} />;
    case "person":
    case "people":
      return <UserIcon size={16} strokeWidth={strokeWidth} />;
    case "themes":
      return <PaletteIcon size={16} strokeWidth={strokeWidth} />;
    default:
      return <WandSparklesIcon size={16} strokeWidth={strokeWidth} />;
  }
};

type SuggestionState = {
  [K in keyof SuggestionType]?: Array<{
    value: string;
    label: string;
  }>;
};

const TagCountIndicator = ({
  count,
  isExpanded,
  animateChanges,
}: {
  count: number;
  isExpanded: boolean;
  animateChanges: boolean;
}) => (
  <motion.span
    initial={animateChanges ? { scale: 0.5, opacity: 0 } : false}
    animate={{ scale: 1, opacity: 1 }}
    exit={{ scale: 0.5, opacity: 0 }}
    className={cn(
      "absolute right-[-0.4em] top-[-0.4em] flex h-5 w-5 items-center justify-center rounded-full border border-primary text-xs transition-colors",
      isExpanded
        ? "bg-white text-primary"
        : "bg-primary text-primary-foreground"
    )}
  >
    {count}
  </motion.span>
);

const CategoryButton = ({
  category,
  data,
  isExpanded,
  animateChanges,
  onClick,
}: {
  category: (typeof CategoryConfig.allCategories)[number];
  data: z.infer<typeof personalisationPageSchema>;
  isExpanded: boolean;
  animateChanges: boolean;
  onClick: () => void;
}) => {
  // Get combined tags from both regular and book_ prefixed keys
  const addedTagCount = getCombinedTags(data, category).length;

  return (
    <Button
      type="button"
      className={cn(
        "group relative w-full",
        isExpanded ? "bg-primary" : "bg-white/40 hover:bg-white"
      )}
      variant={isExpanded ? "default" : "outline"}
      onClick={onClick}
    >
      <span className="-ml-1 mr-1">{renderCategoryIcon(category)}</span>
      {categoryLabels[category]}
      <AnimatePresence>
        {addedTagCount > 0 && (
          <TagCountIndicator
            count={addedTagCount}
            isExpanded={isExpanded}
            animateChanges={animateChanges}
          />
        )}
      </AnimatePresence>
    </Button>
  );
};

const CategoryExpanded = ({
  category,
  data,
  addPersonalisationTag,
  removePersonalisationTag,
  suggestions,
  fetchMoreSuggestions,
  isLoadingSuggestions,
  resetSuggestions,
  suggestionsChanged = false,
  onSuggestionsViewed = () => {},
}: {
  category: Categories;
  data: z.infer<typeof personalisationPageSchema>;
  addPersonalisationTag: (category: Categories, value: string) => void;
  removePersonalisationTag: (category: Categories, value: string) => void;
  suggestions: Array<{ value: string; label: string }>;
  fetchMoreSuggestions: () => Promise<Array<{ value: string; label: string }>>;
  isLoadingSuggestions: boolean;
  resetSuggestions: (excludedCategory: Categories) => void;
  suggestionsChanged?: boolean;
  onSuggestionsViewed?: () => void;
}) => {
  // Get combined tags from both regular and book_ prefixed keys
  const combinedTags = useMemo(
    () => getCombinedTags(data, category),
    [data, category]
  );

  const selectedTags = combinedTags.map((tag) => ({
    value: tag.toLowerCase(),
    label: tag,
  }));

  const handleTagsChange = useCallback(
    (tags: Array<{ value: string; label: string }>) => {
      const existingTags = combinedTags;
      const existingValues = existingTags.map((tag) => tag.toLowerCase());

      const newTags = tags
        .map((tag) => tag.label)
        .filter((label) => !existingValues.includes(label.toLowerCase()));

      newTags.forEach((tag) => {
        addPersonalisationTag(category, tag);
      });

      const currentValues = tags.map((tag) => tag.label.toLowerCase());
      const removedTags = existingTags.filter(
        (tag) => !currentValues.includes(tag.toLowerCase())
      );

      removedTags.forEach((tag) => {
        removePersonalisationTag(category, tag);
      });

      // Clear suggestions buffer for all other categories when tags change
      // This ensures that when other categories are opened, they'll fetch fresh suggestions
      clearSuggestionsBuffer([category]);
      // Reset parent suggestions state for all other categories
      resetSuggestions(category);
    },
    [
      category,
      combinedTags,
      addPersonalisationTag,
      removePersonalisationTag,
      resetSuggestions,
    ]
  );

  // Acknowledge that suggestions have been viewed when component mounts or when suggestions change
  useEffect(() => {
    if (suggestionsChanged) {
      onSuggestionsViewed();
    }
  }, [suggestionsChanged, onSuggestionsViewed]);

  return (
    <motion.div
      className="relative col-span-3 w-full"
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: 1, height: "auto" }}
      exit={{ opacity: 0, height: 0 }}
      transition={{
        type: "spring",
        damping: 40,
        stiffness: 450,
      }}
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 10 }}
        className="overflow-hidden"
      >
        <div className="rounded-lg border bg-white px-3 py-5 pb-4 shadow-sm sm:px-4">
          <div className="-mb-1 -mt-2 flex flex-col">
            <h3 className="text-sm font-semibold">
              {categoryLabels[category]}
            </h3>
          </div>

          <TagInput
            key={`${category}.${isLoadingSuggestions ? "loading" : "loaded"}`}
            selectedTags={selectedTags}
            onTagsChange={handleTagsChange}
            availableTags={suggestions}
            onSuggestionsRequest={fetchMoreSuggestions}
            placeholder={getCategoryPlaceholderText(
              category,
              categoryLabels[category],
              data.hero_name
            )}
            hideSuggestions={!isCategoryWithSuggestions(category)}
            shouldAnimateSuggestions={suggestionsChanged}
            variant="transparent"
            isLoading={isLoadingSuggestions}
          >
            {isCategoryWithInfoNote(category) && (
              <div className="mt-4">
                <p className="max-w-[34ch] text-pretty text-sm font-light leading-snug text-secondary-foreground/80">
                  {getInfoNoteText(
                    category,
                    categoryLabels[category],
                    data.hero_name
                  )}
                </p>
              </div>
            )}
            {/* {isLoadingSuggestions && isCategoryWithSuggestions(category) && (
              <div className="mt-3 text-sm text-muted-foreground">
                <div className="flex items-center gap-1.5">
                  <div className="h-3 w-3 animate-spin rounded-full border-2 border-primary border-t-transparent"></div>
                  <span>Finding inspiring suggestions...</span>
                </div>
              </div>
            )} */}
          </TagInput>
        </div>
      </motion.div>
    </motion.div>
  );
};

const getCategoryPlaceholderText = (
  category: Categories,
  categoryLabel: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  childName: string
): string => {
  switch (category) {
    case "people":
      return `Who else we should mention in stories?`;
    case "animals":
      return `What ${categoryLabel.toLocaleLowerCase()} should we include?`;
    default:
      return `What ${categoryLabel.toLocaleLowerCase()} should we include?`;
  }
};

const getInfoNoteText = (
  category: Categories,
  categoryLabel: string,
  childName: string
): string => {
  switch (category) {
    case "people":
      return `Only the main hero - ${childName} will be illustrated. Mentioning others helps enrich the stories.`;
    case "animals":
      return `If described in detail, we'll do our best to illustrate them.`;
    default:
      return "Share helpful details to create a more personal and engaging stories.";
  }
};

interface StoryElementsProps {
  data: z.infer<typeof personalisationPageSchema>;
  saveFormData: (
    data: Partial<z.infer<typeof personalisationPageSchema>>
  ) => void;
  addPersonalisationTag: (category: Categories, value: string) => void;
  removePersonalisationTag: (category: Categories, value: string) => void;
  children?: React.ReactNode;
}

const PersonalisationNoteInput = ({
  data,
  saveFormData,
}: {
  data: z.infer<typeof personalisationPageSchema>;
  saveFormData: (
    data: Partial<z.infer<typeof personalisationPageSchema>>
  ) => void;
}) => {
  const { order_reference_id } = useGlobalStore();
  const [inputValue, setInputValue] = useState(
    data.personalisation_note || data.note || ""
  );
  const queryClient = useQueryClient();

  // Update local state when data changes from parent
  useEffect(() => {
    setInputValue(data.personalisation_note || data.note || "");
  }, [data.personalisation_note, data.note]);

  // Handle input changes locally first, then propagate to parent
  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(value);
      saveFormData({ personalisation_note: value });
    },
    [saveFormData]
  );

  const suggestPersonalisationNote = useCallback(async () => {
    try {
      const suggestions = await getSuggestions(
        "personalisation_note",
        order_reference_id,
        data,
        queryClient,
        1
      );

      return (
        suggestions[0] || `${data.hero_name}'s dream is to explore the world`
      );
    } catch (error) {
      console.error(
        "Error fetching personalisation note suggestion:",
        error,
        JSON.stringify(data, null, 2)
      );
      return `${data.hero_name}'s dream is to explore the world`;
    }
  }, [order_reference_id, data, queryClient]);

  const hasInput = inputValue.trim().length > 0;

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex flex-col gap-3 pt-6" ref={containerRef}>
      <div>
        <h3 className="align-baseline text-2xl">
          Personal Touch{" "}
          <span className="text-[0.8em] font-light text-secondary-foreground">
            (optional)
          </span>
        </h3>
        <p className="max-w-[80%] text-pretty font-light leading-tight text-secondary-foreground">
          Direct the stories with any wild or heartfelt detail.
        </p>
      </div>
      <InputTextGroup
        value={inputValue}
        onChange={handleInputChange}
        placeholder={`Write a special touch to the stories...`}
        rows={3}
        multiline={true}
        autoExpand={true}
        showCounter={true}
        className={cn("pb-0 text-xl leading-normal", hasInput && "bg-white")}
        suggestText={suggestPersonalisationNote}
        scrollIntoViewRef={containerRef}
      />
    </div>
  );
};

function StoryElements({
  data,
  saveFormData,
  addPersonalisationTag,
  removePersonalisationTag,
  children,
}: Readonly<StoryElementsProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const [expandedCategory, setExpandedCategory] = useState<Categories | null>(
    null
  );
  const { order_reference_id } = useGlobalStore();
  const queryClient = useQueryClient();

  const [suggestions, setSuggestions] = useState<SuggestionState>({});
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  // Add state to track when suggestions change for each category
  const [suggestionsChanged, setSuggestionsChanged] = useState<
    Record<Categories, boolean>
  >({} as Record<Categories, boolean>);

  const fetchMoreSuggestions = useCallback(
    async (category: Categories) => {
      setIsLoadingSuggestions(true);

      if (isCategoryWithSuggestions(category) && isMounted.current) {
        try {
          const newSuggestions = await getSuggestions(
            category,
            order_reference_id,
            data,
            queryClient,
            5
          );

          const formattedSuggestions = newSuggestions.map((suggestion) => ({
            value: suggestion.toLowerCase(),
            label: capitalizeFirstLetter(suggestion),
          }));

          // Check if suggestions have changed compared to previous ones
          const previousSuggestions = suggestions[category] || [];
          const hasChanged =
            formattedSuggestions.length !== previousSuggestions.length ||
            formattedSuggestions.some(
              (sugg, idx) =>
                !previousSuggestions[idx] ||
                sugg.value !== previousSuggestions[idx].value
            );

          setSuggestions((prev) => ({
            ...prev,
            [category]: formattedSuggestions,
          }));

          // Mark category as having changed suggestions
          if (hasChanged) {
            setSuggestionsChanged((prev) => ({
              ...prev,
              [category]: true,
            }));
          }

          return formattedSuggestions;
        } catch (error) {
          console.error(
            `Error fetching ${category} suggestions:`,
            error,
            JSON.stringify(data, null, 2)
          );
          return [];
        } finally {
          if (isMounted.current) {
            setIsLoadingSuggestions(false);
          }
        }
      } else {
        // Reset loading state when returning cached suggestions without making a request
        setIsLoadingSuggestions(false);
        return suggestions[category] || [];
      }
    },
    [order_reference_id, data, queryClient, suggestions]
  );

  const isMounted = useRef(true);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const wasSuggestionsFetched = useRef([]);
  const handleCategoryClick = useCallback(
    (category: Categories) => {
      const isExpanding = expandedCategory !== category;
      setExpandedCategory(expandedCategory === category ? null : category);

      // Load suggestions when a category is expanded for the first time
      if (isExpanding && isCategoryWithSuggestions(category)) {
        if (!wasSuggestionsFetched.current?.includes(category)) {
          fetchMoreSuggestions(category);
          wasSuggestionsFetched.current = [
            ...wasSuggestionsFetched.current,
            category,
          ];
        }
      }
    },
    [expandedCategory, fetchMoreSuggestions]
  );

  // Add function to reset suggestions for all categories except the excluded one
  const resetSuggestions = useCallback(
    (excludedCategory: Categories | null) => {
      setSuggestions((prev) => {
        // If excludedCategory is null, clear all suggestions
        if (excludedCategory === null) {
          return {};
        }

        // Otherwise, clear all except the excluded category
        const result = { ...prev };
        for (const cat in result) {
          if (cat !== excludedCategory) {
            result[cat] = [];
          }
        }
        return result;
      });

      // If excludedCategory is null, clear all fetched categories
      // Otherwise, keep only the excluded category
      wasSuggestionsFetched.current =
        excludedCategory === null
          ? []
          : wasSuggestionsFetched.current.filter(
              (cat) => cat === excludedCategory
            );
    },
    []
  );

  // Add function to acknowledge suggestion changes
  const acknowledgeSuggestionChange = useCallback((category: Categories) => {
    setSuggestionsChanged((prev) => ({
      ...prev,
      [category]: false,
    }));
  }, []);

  // Get current suggestions for the expanded category
  const currentSuggestions = useMemo(
    () =>
      expandedCategory && isCategoryWithSuggestions(expandedCategory)
        ? suggestions[expandedCategory] || []
        : [],
    [expandedCategory, suggestions]
  );

  const internalSaveFormData = useCallback(
    (data: Partial<z.infer<typeof personalisationPageSchema>>) => {
      setExpandedCategory(null); // Close any expanded category when saving form data
      resetSuggestions(null); // Reset suggestions when saving form data
      saveFormData(data);
    },
    [saveFormData, resetSuggestions]
  );

  return (
    <div ref={ref} className="flex flex-col gap-1">
      <div>
        <h3 className="align-baseline text-2xl">
          Story Details{" "}
          <span className="text-[0.8em] font-light text-secondary-foreground">
            (optional)
          </span>
        </h3>
        <p className="max-w-[80%] text-pretty font-light leading-tight text-secondary-foreground">
          Add creativity to make your stories truly special.
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div>
            <p className="mb-2 mt-[10px] tracking-wide text-foreground">
              Tell us more about {data.hero_name}
            </p>
            <div className="grid grid-cols-3 gap-2">
              {CategoryConfig.childFocused.map((category) => (
                <CategoryButton
                  key={category}
                  category={category}
                  data={data}
                  isExpanded={expandedCategory === category}
                  animateChanges={suggestionsChanged[category]}
                  onClick={() => handleCategoryClick(category)}
                />
              ))}
              <AnimatePresence>
                {expandedCategory &&
                CategoryConfig.childFocused.includes(
                  expandedCategory as (typeof CategoryConfig.childFocused)[number]
                ) ? (
                  <CategoryExpanded
                    category={expandedCategory}
                    data={data}
                    addPersonalisationTag={addPersonalisationTag}
                    removePersonalisationTag={removePersonalisationTag}
                    suggestions={currentSuggestions}
                    fetchMoreSuggestions={() =>
                      fetchMoreSuggestions(expandedCategory)
                    }
                    isLoadingSuggestions={isLoadingSuggestions}
                    resetSuggestions={resetSuggestions}
                    suggestionsChanged={
                      suggestionsChanged[expandedCategory] || false
                    }
                    onSuggestionsViewed={() =>
                      acknowledgeSuggestionChange(expandedCategory)
                    }
                  />
                ) : (
                  <span key="placeholder" />
                )}
              </AnimatePresence>
            </div>
          </div>

          <div>
            <p className="mb-2 mt-[10px] tracking-wide text-foreground">
              Choose the focus of your stories
            </p>
            <div className="grid grid-cols-3 gap-2">
              {CategoryConfig.storyElements.map((category) => (
                <CategoryButton
                  key={category}
                  category={category}
                  data={data}
                  isExpanded={expandedCategory === category}
                  animateChanges={suggestionsChanged[category]}
                  onClick={() => handleCategoryClick(category)}
                />
              ))}
              <AnimatePresence>
                {expandedCategory &&
                CategoryConfig.storyElements.includes(
                  expandedCategory as (typeof CategoryConfig.storyElements)[number]
                ) ? (
                  <CategoryExpanded
                    category={expandedCategory}
                    data={data}
                    addPersonalisationTag={addPersonalisationTag}
                    removePersonalisationTag={removePersonalisationTag}
                    suggestions={currentSuggestions}
                    fetchMoreSuggestions={() =>
                      fetchMoreSuggestions(expandedCategory)
                    }
                    isLoadingSuggestions={isLoadingSuggestions}
                    resetSuggestions={resetSuggestions}
                    suggestionsChanged={
                      suggestionsChanged[expandedCategory] || false
                    }
                    onSuggestionsViewed={() =>
                      acknowledgeSuggestionChange(expandedCategory)
                    }
                  />
                ) : (
                  <span key="placeholder" />
                )}
              </AnimatePresence>
            </div>
          </div>

          <PersonalisationNoteInput
            data={data}
            saveFormData={internalSaveFormData}
          />
        </div>
      </div>

      {children}
    </div>
  );
}

export { StoryElements };
