import { genderSchema } from "./genderSchema";
import { z } from "zod";

export const childDetailsSchema = z.object({
  hero_name: z.string().min(1),
  hero_location: z.string().min(1),
  hero_dob: z.string().regex(/^\d{4}-\d{1,2}-\d{1,2}$/, {
    message: "Date must be in YYYY-MM-DD format",
  }),
  hero_gender: genderSchema,
});
export type ChildDetails = z.infer<typeof childDetailsSchema>;

export const childDetailsOptionalSchema = z.object({
  hero_name: z.string().optional(),
  hero_location: z.string().optional(),
  hero_dob: z.string().optional(),
  hero_gender: genderSchema.optional(),
});
export type ChildDetailsOptional = z.infer<typeof childDetailsOptionalSchema>;
