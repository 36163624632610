import {
  GetOccasionSuggestionParams,
  fetchOccasionSuggestions,
} from "./fetchOccasionSuggestions";
import { ChildDetails } from "@/lib/schemas/childDetailsSchema";

const STORAGE_KEY_PREFIX = "br_order_occasion_suggestions";

const fallbackSuggestions = [
  "I Love Books",
  "Birthday Surprise",
  "To Read More",
  "Gift From Grandparents",
  "First Day At School",
  "Bedtime Ritual",
  "Easter fun",
  "Holiday Gift",
  "Graduation Gift",
];

const suggestionsPromises: Record<string, Promise<string[] | undefined>> = {};

// Track suggestion request iterations to ensure fresh suggestions each time
const suggestionIterations: Record<string, number> = {};

export async function getOccasionSuggestions(
  order_reference_id: string,
  params: ChildDetails,
  previous_suggestions?: string[]
) {
  const storageKey = `${STORAGE_KEY_PREFIX}:name=${params.hero_name},dob=${params.hero_dob},gender=${params.hero_gender},location=${params.hero_location}`;

  // If requesting additional suggestions (with previous_suggestions), skip cache
  if (!previous_suggestions) {
    const cachedSuggestions = localStorage.getItem(storageKey);
    if (cachedSuggestions) {
      return JSON.parse(cachedSuggestions);
    }
  }

  const requestParams: GetOccasionSuggestionParams = {
    ...params,
    previous_suggestions,
  };

  // Initialize iteration counter for this storageKey if it doesn't exist
  if (previous_suggestions && !(storageKey in suggestionIterations)) {
    suggestionIterations[storageKey] = 0;
  }

  // Increment the counter when previous_suggestions change
  if (previous_suggestions) {
    suggestionIterations[storageKey]++;
  }

  // Create unique key for the request params
  // For additional suggestions, include the iteration counter to ensure fresh results
  const promiseKey = previous_suggestions
    ? `${storageKey}:additional:${suggestionIterations[storageKey]}`
    : storageKey;

  if (!suggestionsPromises[promiseKey]) {
    suggestionsPromises[promiseKey] = fetchOccasionSuggestions(
      order_reference_id,
      requestParams
    )
      .catch(() => [...fallbackSuggestions].sort(() => Math.random() - 0.5))
      .then((suggestions) => {
        localStorage.setItem(storageKey, JSON.stringify(suggestions));
        return suggestions;
      });
  }

  return suggestionsPromises[promiseKey];
}
