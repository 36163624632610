import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

interface ImageLightboxProps {
  children: React.ReactNode;
}

export const ImageLightbox: React.FC<ImageLightboxProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [clonedContent, setClonedContent] = useState<HTMLElement | null>(null);
  const originalContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && originalContentRef.current) {
      // Find the first image element in the children
      const originalImage = originalContentRef.current.querySelector("img");

      if (originalImage) {
        // Clone the image element for use in the lightbox
        const clonedImage = originalImage.cloneNode(true) as HTMLElement;
        setClonedContent(clonedImage);
      }
    } else {
      setClonedContent(null);
    }
  }, [isOpen]);

  return (
    <div className="relative flex h-full cursor-pointer">
      <div
        ref={originalContentRef}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        {children}
      </div>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              className="fixed inset-0 z-50 flex items-center justify-center bg-black/0"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, backgroundColor: "rgba(0, 0, 0, 0.8)" }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
            >
              <motion.div
                className="relative m-auto flex items-center justify-center"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ type: "spring", damping: 40, stiffness: 400 }}
                style={{
                  maxWidth: "min(90vw, 1400px)",
                  maxHeight: "90vh",
                  width: "auto",
                  height: "auto",
                }}
              >
                <div className="flex h-full w-full items-center justify-center">
                  {clonedContent && (
                    <div className="lightbox-content relative flex items-center justify-center">
                      <style>{`
                        .lightbox-content img {
                          max-width: min(90vw, 1400px);
                          max-height: 90vh;
                          width: auto;
                          height: auto;
                          object-fit: contain;
                          object-position: center;
                          display: block;
                          margin: auto;
                        }
                      `}</style>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: clonedContent.outerHTML,
                        }}
                      />
                    </div>
                  )}
                </div>
                <motion.div
                  className="absolute right-2 top-2 flex h-10 w-10 items-center justify-center rounded-full bg-black/50 p-2 text-white will-change-transform"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                  }}
                >
                  ✕
                </motion.div>
              </motion.div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};
