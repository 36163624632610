import { storyIdeasSchema } from "./storyIdeasSchema";
import { z } from "zod";

export const personalisationSchema = z.object({
  note: z.string().optional(),
  personalisation_note: z.string().optional(),
  themes: z.array(z.string()).optional(),
  people: z.array(z.string()).optional(),
  animals: z.array(z.string()).optional(),
  interests: z.array(z.string()).optional(),
  goals: z.array(z.string()).optional(),
  places: z.array(z.string()).optional(),
  story_ideas: storyIdeasSchema.optional(),
  story_outlines: storyIdeasSchema.optional(),
});
export type Personalisation = z.infer<typeof personalisationSchema>;
