import { ScrollToTop } from "./components/ScrollToTop";
import { Toaster } from "./components/ui/toaster";
import * as Sentry from "@sentry/react";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { Outlet } from "react-router-dom";

const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: ONE_WEEK,
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  });

  return (
    <div className="overflow-x-hidden">
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister, maxAge: ONE_WEEK * 4 }}
      >
        <Outlet />
        <Toaster />
      </PersistQueryClientProvider>
      <ScrollToTop />
    </div>
  );
}

const BlossomReadsApp = Sentry.withProfiler(App);

export default BlossomReadsApp;
