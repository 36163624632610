import { useEffect, useRef } from "react";
import { STEPS } from "../config/steps";
import { childDetailsSchema } from "../schemas/childDetailsSchema";
import { dedicationMessageSchema } from "../schemas/dedicationMessageSchema";
import { Gender } from "../schemas/genderSchema";
import { occasionSchema } from "../schemas/occasionSchema";
import { personalisationSchema } from "../schemas/personalisationSchema";
import { StoryIdeas } from "../schemas/storyIdeasSchema";
import { themeSchema } from "../schemas/themeSchema";
import { useGlobalStore } from "../store/global";
import { FetchError } from "../utils/FetchError";
import { useDisplayErrorToast } from "../utils/useDisplayErrorToast";
import { queryKeys } from "./queryKeys";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

const MAX_FETCH_ATTEMPTS = 60;
const FETCH_POLL_INTERVAL = 1000;

export type GetStoriesParams = {
  hero_name: string;
  hero_gender: Gender;
  hero_dob: string;
  hero_location: string;
  story_ideas: StoryIdeas;
  personalisation_note: string;
  dedication_message: string;
  language: "en";
  theme: string;
  occasion: string;
};

export const getStoriesSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(themeSchema)
  .merge(personalisationSchema)
  .merge(dedicationMessageSchema);

const storySchema = z.object({
  title: z.string(),
  text: z.string(),
  target_language: z.string(),
  title_translated: z.string().nullable(),
  text_translated: z.string().nullable(),
  image_right: z.string().nullable(),
  image_left: z.string().nullable(),
  image_final: z.string().nullable(),
  order: z.number(),
  drawing_style: z.string().nullable(),
  image_right_optimized: z.string().nullable(),
  image_left_optimized: z.string().nullable(),
  image_final_optimized: z.string().nullable(),
  story_id: z.number(),
});

const storiesSchema = z.array(storySchema);

function filterValidStories(data: { story_id: number | null }[]) {
  if (!Array.isArray(data)) return [];
  return data.filter((item) => item && item.story_id !== null);
}

export const useStories = ({ start }: { start: boolean }) => {
  const { order_reference_id, storyIdeas } = useGlobalStore();
  const queryClient = useQueryClient();
  // Add a ref to track if we should refetch
  const shouldPollRef = useRef(true);

  // Track the number of fetch attempts
  const fetchAttemptsRef = useRef(0);
  const maxFetchAttemptsRef = useRef(MAX_FETCH_ATTEMPTS);

  // Check if we already have stories in the cache
  const existingData = queryClient.getQueryData([
    queryKeys.GET_STORIES,
    order_reference_id,
    storyIdeas,
  ]) as z.infer<typeof storiesSchema> | undefined;

  // Stop polling if we already have at least one story with story_id
  const validStories = existingData ? filterValidStories(existingData) : [];
  const hasValidStories = validStories.length > 0;

  if (hasValidStories && shouldPollRef.current) {
    shouldPollRef.current = false;
  }

  const result = useQuery({
    queryKey: [queryKeys.GET_STORIES, order_reference_id, storyIdeas],
    queryFn: () => {
      // Increment fetch attempt counter
      fetchAttemptsRef.current++;

      if (fetchAttemptsRef.current > maxFetchAttemptsRef.current) {
        shouldPollRef.current = false;
      }

      return getStories(order_reference_id, queryClient);
    },
    enabled: start && shouldPollRef.current,
    retry: false, // We'll handle retries manually
    refetchInterval: shouldPollRef.current ? FETCH_POLL_INTERVAL : false,
    staleTime: hasValidStories ? Infinity : FETCH_POLL_INTERVAL,
    gcTime: 10 * 60 * 1000, // 10 minutes cache
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  // Reset fetch counter on unmount or when order_id changes
  useEffect(() => {
    return () => {
      fetchAttemptsRef.current = 0;
    };
  }, [order_reference_id]);

  // NOTE: Do not need to poll, just meant to start the fetch.

  // Manual retry if error occurred
  useEffect(() => {
    if (result.error && shouldPollRef.current) {
      const timer = setTimeout(() => {
        if (shouldPollRef.current) {
          result.refetch();
        }
      }, FETCH_POLL_INTERVAL);

      return () => clearTimeout(timer);
    }
  }, [result.error, result.refetch]);

  // If we got valid data, stop polling
  useEffect(() => {
    if (result.data) {
      const validResults = filterValidStories(result.data);
      if (validResults.length > 0) {
        shouldPollRef.current = false;
      }
    }
  }, [result.data]);

  return result;
};

export const useStoriesGeneration = ({ start }: { start: boolean }) => {
  const { order_reference_id, getFormData, storyIdeas } = useGlobalStore();
  const queryClient = useQueryClient();
  const dataForStories = getFormData(getStoriesSchema);
  const hasRunOnceRef = useRef(false);

  const params = getParams(dataForStories!);

  // Check if we already have stories in the cache
  const existingStories = queryClient.getQueryData([
    queryKeys.GET_STORIES,
    order_reference_id,
    storyIdeas,
  ]) as z.infer<typeof storiesSchema> | undefined;

  // Only start generation if we don't have valid stories
  const shouldStartGeneration =
    start &&
    !!params &&
    !hasRunOnceRef.current &&
    !(
      existingStories &&
      Array.isArray(existingStories) &&
      existingStories.length > 0 &&
      existingStories[0].story_id
    );

  const { isError, isSuccess, isLoading, data } = useQuery({
    queryKey: [queryKeys.GENERATE_STORIES, order_reference_id, storyIdeas],
    queryFn: () => {
      hasRunOnceRef.current = true;
      return startStoryGeneration(order_reference_id, params!);
    },
    enabled: shouldStartGeneration,
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 0,
  });

  useDisplayErrorToast({ isError: start && isError });

  // If story generation is successful, invalidate stories query
  useEffect(() => {
    if (isSuccess && data === true) {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.GET_STORIES, order_reference_id, storyIdeas],
      });
    }
  }, [isSuccess, data, queryClient, order_reference_id, storyIdeas]);

  return {
    isStartStoriesGenerationLoading: isLoading,
    isStartStoriesGenerationSuccess: isSuccess,
    isStartStoriesGenerationError: isError,
  };
};

async function startStoryGeneration(
  order_reference_id: string,
  params: GetStoriesParams
) {
  const url = new URL("/preview/order-assistant", import.meta.env.VITE_API_URL);
  url.searchParams.append("task", "write_stories");
  url.searchParams.append("order_reference_id", order_reference_id);

  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  });

  if (!response.ok) {
    throw new Error("Failed to start story generation");
  }

  const responseData = await response.json();
  const status =
    z.object({ status: z.string() }).parse(responseData).status === "success";

  return status;
}

async function fetchStories(order_reference_id: string) {
  const url = new URL(
    "/preview/order-assistant/get-results",
    import.meta.env.VITE_API_URL
  );
  url.searchParams.append("task", "write_stories");
  url.searchParams.append("order_reference_id", order_reference_id);

  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });

  if (!response.ok) {
    throw new FetchError("Failed to fetch preview stories", STEPS.STEP_1);
  }

  const data = await response.json();

  if (!data || !Array.isArray(data)) {
    throw new FetchError("Stories data is invalid", STEPS.STEP_1);
  }

  const validStories = filterValidStories(data);
  if (validStories.length > 0) {
    return storiesSchema.parse(validStories);
  }

  // If no valid stories yet, throw error to continue polling
  throw new Error("No completed stories yet");
}

export async function getStories(
  order_reference_id: string,
  queryClient?: QueryClient,
  formData?: any
) {
  // FIXME: Use values from the localStorage because formData contains stale values
  let paramsFormData = {};
  try {
    const storedFormData = localStorage.getItem("br_order_form");
    const parsedFormData = storedFormData ? JSON.parse(storedFormData) : {};
    const storyIdeas = parsedFormData.storyIdeas || formData.story_ideas;
    paramsFormData = {
      ...(parsedFormData.formData || formData),
      story_ideas: storyIdeas,
    };
  } catch (error) {
    // Handle error if needed
  }

  const params = getParams(paramsFormData);

  // Check cache first if queryClient is provided
  if (queryClient) {
    const cachedData = queryClient.getQueryData([
      queryKeys.GET_STORIES,
      order_reference_id,
      params,
    ]);

    if (cachedData && Array.isArray(cachedData)) {
      const validStories = filterValidStories(cachedData);
      if (validStories.length > 0) {
        const validatedData = storiesSchema.parse(validStories);
        return validatedData;
      }
    }

    const storiesPromise = queryClient.fetchQuery({
      queryKey: [queryKeys.GET_STORIES, order_reference_id, params],
      queryFn: () => fetchStories(order_reference_id),
    });

    // Update the fullStories in localStorage directly
    // try {
    //   const formData = localStorage.getItem("br_order_form");
    //   const parsedFormData = formData ? JSON.parse(formData) : {};
    //   localStorage.setItem(
    //     "br_order_form",
    //     JSON.stringify({
    //       ...parsedFormData,
    //       fullStories: stories,
    //     })
    //   );
    // } catch (error) {
    //   // Handle error if needed
    // }
    return storiesPromise;
  }

  // If no queryClient is provided, fetch directly
  try {
    const stories = await fetchStories(order_reference_id);
    if (stories) {
      return stories;
    }
  } catch (error) {
    // Handle error if needed
  }

  // If no valid stories yet, throw error to continue polling
  throw new FetchError("No completed stories yet", STEPS.STEP_1);
}

export const getParams = (data: z.infer<typeof getStoriesSchema>) => {
  const params: GetStoriesParams | undefined = data
    ? {
        hero_name: data.hero_name,
        hero_gender: data.hero_gender,
        hero_dob: data.hero_dob,
        hero_location: data.hero_location,
        story_ideas: data.story_ideas,
        personalisation_note: data.personalisation_note ?? data.note ?? "",
        dedication_message: data.book_dedication_message ?? "",
        language: "en",
        theme: data.book_theme ?? "",
        occasion: data.occasion,
      }
    : undefined;

  return params;
};
