import { Button } from "./ui/button";
import { LoaderCircle } from "lucide-react";

export function SubmitButton({
  disabled,
  isLoading,
}: {
  disabled?: boolean;
  isLoading?: boolean;
}) {
  return (
    <Button
      type="submit"
      className="relative mt-5 bg-tertiary px-10 font-semibold uppercase tracking-[2px] text-popover hover:bg-tertiary/90 active:bg-tertiary max-sm:w-full sm:w-40"
      disabled={disabled || isLoading}
    >
      {isLoading ? <LoaderCircle className="animate-spin" /> : "Continue"}
    </Button>
  );
}
