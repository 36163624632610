import * as RadixDialog from '@radix-ui/react-dialog';
import { cn } from '@/lib/utils';
import { PropsWithChildren, useEffect, useState } from 'react';
import { isMobile } from '@/lib/utils/device';

interface DialogProps extends PropsWithChildren {
  buttonTitle?: string;
  inlineButton?: boolean;
  contentClassName?: string;
  customTrigger?: React.ReactNode;
}

export function Dialog({
  buttonTitle,
  inlineButton = false,
  children,
  contentClassName,
  customTrigger = null,
}: DialogProps) {
  const [dialogHeight, setDialogHeight] = useState(
    window.visualViewport?.height,
  );
  useEffect(() => {
    window.visualViewport?.addEventListener('resize', () => {
      setDialogHeight(window.visualViewport?.height);
    });
    return () => {
      window.visualViewport?.removeEventListener('resize', () => {
        setDialogHeight(window.visualViewport?.height);
      });
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <RadixDialog.Root onOpenChange={setIsOpen}>
      <RadixDialog.Trigger asChild>
        {customTrigger ? (
          customTrigger
        ) : inlineButton ? (
          <a
            className={cn(
              'hover:bg-accent hover:text-accent-foreground',
              'whitespace-normal cursor-pointer text-primary inline',
            )}
          >
            {buttonTitle}
          </a>
        ) : (
          <button
            className={cn(
              'inline-flex items-center justify-center rounded-md',
              'px-4 py-2 hover:bg-accent hover:text-accent-foreground',
              'text-primary',
            )}
          >
            {buttonTitle}
          </button>
        )}
      </RadixDialog.Trigger>
      <RadixDialog.Portal>
        <RadixDialog.Overlay className="fixed inset-0 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
        <RadixDialog.Content
          onOpenAutoFocus={(e) => {
            e.preventDefault();
          }}
          aria-describedby={undefined}
          className={cn(
            "fixed inset-0 grid grid-rows-[auto_auto_1fr_auto] bg-card focus:outline-none sm:rounded-lg",
            // Mobile: respect safe areas, add bottom padding for keyboard
            "mt-safe mb-safe mx-auto my-4 max-h-[80vh] overflow-auto",
            // Desktop: centered positioning
            "top-[60px] sm:inset-auto sm:left-[50%] sm:top-[50%] sm:m-4 sm:min-h-[680px] sm:w-[90vw] sm:max-w-[600px] sm:translate-x-[-50%] sm:translate-y-[-50%] sm:overflow-hidden",
            "my-5 sm:mx-5 sm:my-3",
            // Replace animation classes with simple opacity transition
            // "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
            "transition-opacity duration-500",
            contentClassName
          )}
          style={{
            height: isMobile() ? dialogHeight : "50%",
            animation: !isOpen
              ? "dialogContentShow 450ms cubic-bezier(0.16, 1, 0.3, 1) 0.1s forwards"
              : "dialogContentHide 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards",
            opacity: !isOpen ? 0 : 1, // to esnure fade in and out animations work
          }}
        >
          <div className="p-6 h-full overflow-y-auto">{children}</div>
          <RadixDialog.Close
            onClick={() => setIsOpen(false)}
            className={cn(
              'max-sm:hidden absolute right-6 top-4 rounded-sm opacity-70 ring-offset-background',
              'transition-opacity hover:opacity-100 outline-none disabled:pointer-events-none',
            )}
          >
            <span className="sr-only">Close</span>✕
          </RadixDialog.Close>
          <RadixDialog.Close
            onClick={() => setIsOpen(false)}
            className={cn(
              'sm:hidden bg-inherit flex justify-center py-4 shadow border-t w-full rounded-none',
            )}
          >
            <p className="text-primary">Close</p>
          </RadixDialog.Close>
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
}

export function DialogTitle({ children }: PropsWithChildren) {
  return (
    <RadixDialog.Title className="font-semibold text-xl leading-none tracking-tight pr-6">
      {children}
    </RadixDialog.Title>
  );
}
