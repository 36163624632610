import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

interface ZoomableImageLightboxProps {
  children: React.ReactNode;
}

export const ZoomableImageLightbox: React.FC<ZoomableImageLightboxProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [clonedContent, setClonedContent] = useState<HTMLImageElement | null>(
    null
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageNaturalWidth, setImageNaturalWidth] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageNaturalHeight, setImageNaturalHeight] = useState(0);
  const originalContentRef = useRef<HTMLDivElement>(null);

  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    if (isOpen && originalContentRef.current) {
      const originalImage = originalContentRef.current.querySelector("img");

      if (originalImage) {
        const clonedImage = originalImage.cloneNode(true) as HTMLImageElement;

        // Store original dimensions for aspect ratio calculation
        const imgWidth = originalImage.naturalWidth || originalImage.width;
        const imgHeight = originalImage.naturalHeight || originalImage.height;
        setImageNaturalWidth(imgWidth);
        setImageNaturalHeight(imgHeight);

        setIsPortrait(imgHeight > imgWidth);

        clonedImage.onload = () => {
          const width = clonedImage.naturalWidth;
          const height = clonedImage.naturalHeight;
          setImageNaturalWidth(width);
          setImageNaturalHeight(height);
          setIsPortrait(height > width);
        };

        setClonedContent(clonedImage);
      }
    } else {
      setClonedContent(null);
    }
  }, [isOpen]);

  // Prevent body scrolling when lightbox is open
  useEffect(() => {
    if (isOpen) {
      // Prevent body scrolling when dialog is open
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      // Prevent body scrolling when dialog is open
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    }

    return () => {
      // Prevent body scrolling when dialog is open
      document.body.style.overflow = "auto";
      document.documentElement.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <div className="relative h-full cursor-pointer">
      <div
        ref={originalContentRef}
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        {children}
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed inset-0 z-50 flex items-center justify-center bg-background/80 backdrop-blur-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            onClick={() => setIsOpen(false)}
          >
            {clonedContent && (
              <motion.div
                className="relative aspect-square w-full max-w-[100vw] overflow-hidden"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ type: "spring", damping: 40, stiffness: 400 }}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    overflowX: "auto",
                    overflowY: "hidden",
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    WebkitOverflowScrolling: "touch",
                    overscrollBehavior: "contain",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::WebkitScrollbar": {
                      display: "none",
                    },
                  }}
                  className="hide-scrollbar"
                >
                  <img
                    src={clonedContent.src}
                    alt={clonedContent.alt || "Enlarged image"}
                    style={{
                      height: isPortrait ? "auto" : "100%",
                      width: isPortrait ? "100%" : "auto",
                      maxWidth: isPortrait ? "100%" : "none",
                      maxHeight: isPortrait ? "100vh" : "none",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </motion.div>
            )}

            <motion.button
              className="absolute right-4 top-4 flex h-10 w-10 items-center justify-center rounded-full bg-black/50 p-2 text-white will-change-transform"
              onClick={(e) => {
                e.stopPropagation();
                setIsOpen(false);
              }}
              type="button"
              aria-label="Close"
            >
              ✕
            </motion.button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
