import { useGlobalStore } from '@/lib/store/global';
import { useEffect } from 'react';
import { getStepNumber, STEPS } from '../config/steps';
import { getStepRoute } from '../utils/useStepNavigation';
import { useNavigate } from 'react-router-dom';

export const useReset = () => {
  const { resetStore } = useGlobalStore();
  const navigate = useNavigate();

  useEffect(() => {
    resetStore(undefined, () => {
      navigate(getStepRoute(getStepNumber(STEPS.STEP_0)));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
