"use client";

import { useCallback, useEffect, useRef, useState } from "react";
import { Loader2, WandSparklesIcon, X } from "lucide-react";
import { cn } from "@/lib/utils";

interface InputTextGroupProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  maxLength?: number;
  allowExceed?: boolean;
  showCounter?: boolean;
  className?: string;
  style?: React.CSSProperties;
  suggestText?: () => Promise<string>;
  autoExpand?: boolean; // Set textarea to expand with content
  scrollIntoViewRef?: React.RefObject<HTMLDivElement>;
}

export function InputTextGroup({
  value,
  onChange,
  placeholder = "Be imaginative...",
  multiline = false,
  rows = 3,
  maxLength,
  allowExceed = false,
  showCounter = false,
  className,
  style,
  suggestText,
  autoExpand = false,
  scrollIntoViewRef,
}: Readonly<InputTextGroupProps>) {
  // Component state
  const [isFocused, setIsFocused] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  // Refs for input elements
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Auto-expand functionality for textarea
  useEffect(() => {
    if (multiline && autoExpand && textareaRef.current) {
      const textarea = textareaRef.current;

      // Reset height to auto to get the correct scrollHeight
      textarea.style.height = "auto";

      // Set the height to match content (scrollHeight)
      const scrollHeight = textarea.scrollHeight;
      textarea.style.height = `${scrollHeight}px`;
    }
  }, [value, multiline, autoExpand]);

  useEffect(() => {
    if (multiline && autoExpand) {
      const handleResize = () => {
        if (textareaRef.current) {
          const textarea = textareaRef.current;
          textarea.style.height = "auto";
          const scrollHeight = textarea.scrollHeight;
          textarea.style.height = `${scrollHeight}px`;
        }
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [multiline, autoExpand]);

  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    return () => {
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, []);

  // Event handlers
  const handleClear = useCallback(() => {
    onChange("");
    if (multiline) {
      textareaRef.current?.focus();
    } else {
      inputRef.current?.focus();
    }

    // Scroll to the top of the container if it exists
    scrollTimeout.current = setTimeout(() => {
      if (scrollIntoViewRef?.current) {
        scrollIntoViewRef.current.scrollIntoView({
          behavior: "instant",
          block: "start",
        });
      }
    }, 50);
  }, [onChange, multiline, scrollIntoViewRef]);

  const handleGenerate = useCallback(async () => {
    if (suggestText) {
      setIsGenerating(true);
      try {
        const newText = await suggestText();
        onChange(newText);
        if (multiline) {
          textareaRef.current?.focus();
        } else {
          inputRef.current?.focus();
        }
      } catch (error) {
        console.error("Failed to generate text:", error);
      } finally {
        setIsGenerating(false);
        // Scroll to the top of the container if it exists
        scrollTimeout.current = setTimeout(() => {
          if (scrollIntoViewRef?.current) {
            scrollIntoViewRef.current.scrollIntoView({
              behavior: "instant",
              block: "start",
            });
          }
        }, 50);
      }
    }
  }, [suggestText, onChange, multiline, scrollIntoViewRef]);

  const handleFocus = useCallback(() => setIsFocused(true), []);
  const handleBlur = useCallback(() => setIsFocused(false), []);

  // Handle input change with character limit enforcement
  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = e.target.value;
      // Only apply the maxLength restriction if allowExceed is false and maxLength is defined
      if (!allowExceed && maxLength && newValue.length > maxLength) {
        // Prevent exceeding the character limit by truncating the input
        onChange(newValue.slice(0, maxLength));
      } else {
        onChange(newValue);
      }
    },
    [onChange, maxLength, allowExceed]
  );

  // Derived state
  const isExceeded = maxLength ? value.length > maxLength : false;
  const showClearButton = value.length > 3;

  // Warning style variables for exceeded character limit
  const warningBorderColor = "";
  const warningTextColor = "text-yellow-600 dark:text-yellow-400";

  // Common props for both input and textarea
  const commonInputProps = {
    value,
    onChange: handleInputChange,
    placeholder,
    className: cn(
      "w-full px-2 border-0 focus:outline-none bg-transparent pt-1"
    ),

    onFocus: handleFocus,
    onBlur: handleBlur,
    // We'll handle maxLength manually in our handleInputChange function instead
    maxLength: undefined,
    disabled: isGenerating,
  };

  return (
    <div
      className={cn(
        "relative -mx-2 w-full overflow-hidden rounded-md bg-white/40 transition-colors focus-within:bg-white",
        className
      )}
      style={{ width: `calc(100% + 0.5rem)`, ...(style || {}) }}
    >
      {/* Main input container */}
      <div
        className={cn(
          "overflow-hidden rounded-md border border-b-2",
          isExceeded && allowExceed ? warningBorderColor : "border-input/40",
          isFocused ? "ring-2 ring-primary/50" : ""
        )}
      >
        {/* Input field section */}
        <div className="relative">
          {multiline ? (
            <textarea
              {...commonInputProps}
              ref={textareaRef}
              rows={rows}
              className={cn(
                commonInputProps.className,
                autoExpand
                  ? "overflow-hidden"
                  : `overflow-y-auto [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-gray-300 [&::-webkit-scrollbar-track]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar]:w-2`,
                "resize-none px-2 sm:px-4 sm:pt-3"
              )}
              style={autoExpand ? { overflow: "hidden" } : undefined}
            />
          ) : (
            <input {...commonInputProps} ref={inputRef} type="text" />
          )}
        </div>

        {/* Footer group */}
        <div
          className={cn(
            "flex items-center justify-between px-2 py-2 text-xs text-muted-foreground sm:px-4 sm:py-3",
            multiline ? "-mt-2" : ""
          )}
        >
          {/* Text generation button */}
          {suggestText && (
            <button
              type="button"
              onClick={handleGenerate}
              className={cn(
                "flex items-center gap-1.5 rounded-md border border-primary/40 bg-primary/5 px-2.5 py-1.5 text-sm text-primary transition-all hover:bg-white/60",
                isGenerating
                  ? "cursor-not-allowed opacity-70"
                  : "hover:text-primary"
              )}
              aria-label="Inspire me"
              disabled={isGenerating}
            >
              {isGenerating ? (
                <Loader2 size={16} className="animate-spin" />
              ) : (
                <WandSparklesIcon size={16} />
              )}
              <span>{isGenerating ? "Thinking..." : "Inspire me"}</span>
            </button>
          )}

          {/* Character counter */}
          <div className="-mb-1 ml-auto flex items-center gap-2 pr-1 sm:pr-0">
            {(showCounter || maxLength) && value.length > 3 && (
              <div
                className={cn(
                  isExceeded && allowExceed
                    ? `font-medium ${warningTextColor}`
                    : ""
                )}
              >
                {showCounter && <span>{value.length}</span>}
                {maxLength && (
                  <span>
                    {showCounter
                      ? ` / ${maxLength}`
                      : `${value.length} / ${maxLength}`}
                  </span>
                )}
              </div>
            )}

            {/* Clear button */}
            {showClearButton && (
              <button
                type="button"
                onClick={handleClear}
                className="-m-3 p-3 text-muted-foreground transition-colors hover:text-foreground"
                aria-label="Clear input"
                disabled={isGenerating}
              >
                <X size={16} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
