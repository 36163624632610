import { useRef, useState } from "react";
import { getAllParams } from "../campaign-params";
import { adaptFrontendToBackendOrder } from "../store/adapter";
import { useDisplayErrorToast } from "../utils/useDisplayErrorToast";
import { STEPS, StepEventName } from "@/lib/config/steps";
import { useGlobalStore } from "@/lib/store/global";

export const useSubmitFormData = (stepEventName: StepEventName) => {
  const { deviceId, order_reference_id, formData, images, storyIdeas } =
    useGlobalStore();
  const [isPending, setIsPending] = useState(false);
  const [isError, setIsError] = useState(false);
  const pendingRequestRef = useRef<AbortController | null>(null);

  useDisplayErrorToast({ isError });

  const submitData = async (user_email?: string) => {
    // Use isPending for UI/flow control
    if (isPending) {
      console.log("Request already in progress, skipping duplicate call");
      return false;
    }

    // Cancel any previous request that might still be ongoing
    if (pendingRequestRef.current) {
      pendingRequestRef.current.abort();
    }

    const abortController = new AbortController();
    pendingRequestRef.current = abortController;

    setIsPending(true);
    setIsError(false);

    if (!deviceId) {
      throw new Error("Device ID is not set");
    }

    const url = new URL(`/order-form-event`, import.meta.env.VITE_API_URL);
    url.searchParams.append("event_name", stepEventName);
    url.searchParams.append("device_id", deviceId);
    url.searchParams.append("order_reference_id", order_reference_id);

    const orderMeta = {
      ...(formData.order_meta || {}),
    };

    const urlParams = getAllParams();
    if (urlParams) {
      orderMeta.url_params = {
        // Always include only the latest url params
        ...urlParams,
      };
    }

    if (storyIdeas) {
      // Get existing story outlines or initialize empty object
      const existingStoryOutlines = orderMeta.story_outlines || {};

      // Filter out empty string values from storyIdeas
      const filteredStoryIdeas = Object.fromEntries(
        Object.entries(storyIdeas).filter(([, value]) => value !== "")
      );

      // Merge with existing, prioritizing non-empty values from storyIdeas
      orderMeta.story_outlines = {
        ...existingStoryOutlines,
        ...filteredStoryIdeas,
      };
    }

    // FIXME: This is a temporary fix to handle both old and new cases
    const imagePaths =
      images
        .map((img) => {
          // Handle both old and new image formats
          if (img.s3_path && img.s3_path_original) {
            return {
              ...img,
              original: img.s3_path_original,
              optimized: img.s3_path,
            };
          } else if ("optimized" in img && "original" in img) {
            return img;
          }
        })
        .filter((img) => img) || [];
    if (imagePaths.length > 0) {
      orderMeta.image_paths = imagePaths;
    }

    let isSuccess = false;
    let backendData = null;
    try {
      const data = {
        ...formData,
        book_language: "en",
        order_meta: orderMeta,
        // Ensure user_email can be passed in as an argument
        ...(user_email
          ? {
              user_email: user_email,
            }
          : {}),
      };
      // if (process.env.NODE_ENV === "development") {
      //   console.log("Form data to submit:", data, images);
      // }

      backendData = adaptFrontendToBackendOrder(data, images);
      // if (process.env.NODE_ENV === "development") {
      //   console.log("Form data to submit:", backendData);
      // }

      const res = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(backendData),
        signal: abortController.signal,
      });

      if (res.ok) {
        isSuccess = true;
      } else {
        // Try to extract error details from response
        let errorDetails = "";
        try {
          const errorBody = await res.text();
          errorDetails = errorBody;
        } catch (parseError) {
          errorDetails = "Failed to parse error response";
        }
        throw new Error(
          `Failed to submit form data with status ${res.status}: ${errorDetails}`
        );
      }
    } catch (error) {
      // Don't report errors from aborted requests
      if (!(error instanceof DOMException && error.name === "AbortError")) {
        console.error(
          `Failed to submit form data in ${stepEventName}:`,
          error,
          JSON.stringify(backendData, null, 2)
        );
        setIsError(true);
      }
    } finally {
      pendingRequestRef.current = null;
      setIsPending(false);
    }

    return isSuccess;
  };

  return { submitData, isPending };
};

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

export const useInitialPageLoadSubmission = () => {
  const { submitData } = useSubmitFormData(STEPS.STEP_0);

  const submitWithRetry = async (deviceId: string, retries = 0) => {
    try {
      await submitData();
    } catch (error) {
      console.error(
        `Failed to submit initial page load event (attempt ${retries + 1}):`,
        error
      );

      if (retries < MAX_RETRIES) {
        setTimeout(() => submitWithRetry(deviceId, retries + 1), RETRY_DELAY);
      }
    }
  };

  return submitWithRetry;
};
