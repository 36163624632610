import * as React from "react";
import { cn } from "@/lib/utils";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isError?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, isError, type, ...props }, ref) => {
    return (
      <input
        ref={ref}
        type={type}
        className={cn(
          "-mx-2 flex w-full rounded-md border border-b-2 border-input/40 bg-white/40 px-2 py-2 text-xl transition-colors file:border-0 file:text-sm file:font-medium placeholder:text-muted-foreground focus:bg-white focus:ring-2 focus:ring-primary/50 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
          isError && "ring-2 ring-destructive/60",
          className
        )}
        style={{ width: "calc(100% + 0.5rem)", ...(props.style || {}) }}
        {...props}
      />
    );
  }
);
Input.displayName = "Input";

export { Input };
