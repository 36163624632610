import { StepEventName, getStepNumber } from "../config/steps";
import { useNavigate } from "react-router-dom";

// Define step routes configuration as an array
export const STEP_ROUTES = [
  "/", // STEP_0
  "/child-details", // STEP_1
  "/occasion", // STEP_2
  "/personalisation", // STEP_3
  "/photos", // STEP_4
  "/customer-details", // STEP_5
  "/similarity", // STEP_6
  "/cart", // STEP_7
  "/paid", // STEP_8 // TODO: Not implemented
];

export function useStepNavigation(stepEventName: StepEventName) {
  const navigate = useNavigate();
  const currentStep = getStepNumber(stepEventName);

  return {
    goToPreviousStep: () =>
      navigate(getStepRoute(Math.max(0, currentStep - 1))),
    goToNextStep: () => {
      const stepRoute = getStepRoute(
        Math.min(STEP_ROUTES.length - 1, currentStep + 1)
      );
      if (process.env.NODE_ENV === "development") {
        console.log("Navigating to next step:", stepRoute);
      }
      navigate(stepRoute);
    },
  };
}

export function getStepRoute(step: number) {
  // Ensure step is within valid bounds
  if (step >= 0 && step < STEP_ROUTES.length) {
    return STEP_ROUTES[step];
  }
  // Fallback to root route
  return "/";
}
