import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { DropdownMenuProps } from "@radix-ui/react-dropdown-menu";
import { ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";
import {
  getDayOptions,
  getMonthOptions,
  getYearOptions,
} from "@/lib/utils/date";

type DateInputProps = {
  value?: string;
  onChange: (value: string) => void;
  isError?: boolean;
};

export const DateInput = ({
  value = "",
  onChange,
  isError,
}: DateInputProps) => {
  const [isRehydrating, setIsRehydrating] = useState(true);
  const hasInitialized = useRef(false);

  // Parse date from YYYY-MM-DD format only
  const parseDate = (val: string | undefined) => {
    if (!val) return [undefined, undefined, undefined];
    return val.split("-").map((v) => (v ? parseInt(v, 10) : undefined));
  };

  // Format date ensuring YYYY-MM-DD format with padded numbers
  const formatDate = (y?: number, m?: number, d?: number) => {
    if (!y || !m || !d) return "";
    return `${y}-${String(m).padStart(2, "0")}-${String(d).padStart(2, "0")}`;
  };

  const [y, m, d] = parseDate(value);
  const [day, setDay] = useState<number | null>(d || null);
  const [month, setMonth] = useState<number | null>(m || null);
  const [year, setYear] = useState<number | null>(y || null);
  const [dayOpen, setDayOpen] = useState(false);
  const [monthOpen, setMonthOpen] = useState(false);
  const [yearOpen, setYearOpen] = useState(false);

  const getValue = useCallback(
    (y = year, m = month, d = day) => {
      return y && m && d ? formatDate(y, m, d) : "";
    },
    [year, month, day]
  );

  // Add effect to sync with external value changes
  useEffect(() => {
    if (value) {
      const [newYear, newMonth, newDay] = value
        .split("-")
        .map((v) => (v ? parseInt(v, 10) : undefined));

      // Validate parsed values before updating state
      if (newYear && !isNaN(newYear)) setYear(newYear);
      if (newMonth && !isNaN(newMonth)) setMonth(newMonth);
      if (newDay && !isNaN(newDay)) {
        const dayOptions = getDayOptions(newMonth, newYear);
        const isValidDay = dayOptions.some((d) => d.value === newDay);
        if (isValidDay) setDay(newDay);
      }
      const newValue = getValue(newYear, newMonth, newDay);
      if (!isRehydrating) {
        // Only call onChange if not rehydrating
        onChange(newValue);
      }
    } else {
      setYear(null);
      setMonth(null);
      setDay(null);
    }
    setIsRehydrating(false); // Rehydration is complete
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const dayOptions = getDayOptions(month ?? undefined, year ?? undefined);
    const isInvalidDay = dayOptions.every((d) => d.value !== day);
    if (isInvalidDay) {
      setDay(null);
      onChange(getValue(undefined, undefined, null));
    }
  }, [day, month, year, getValue, onChange]);

  useEffect(() => {
    if (!hasInitialized.current && value) {
      const [newYear, newMonth, newDay] = parseDate(value);

      if (newYear && !isNaN(newYear)) setYear(newYear);
      if (newMonth && !isNaN(newMonth)) setMonth(newMonth);
      if (newDay && !isNaN(newDay)) {
        const dayOptions = getDayOptions(newMonth, newYear);
        const isValidDay = dayOptions.some((d) => d.value === newDay);
        if (isValidDay) {
          setDay(newDay);
          // Ensure the value is in YYYY-MM-DD format
          const formattedDate = `${newYear}-${String(newMonth).padStart(2, "0")}-${String(newDay).padStart(2, "0")}`;
          if (!isRehydrating) {
            onChange(formattedDate);
          }
        }
      }
      hasInitialized.current = true;
    }
    setIsRehydrating(false);
  }, [value, onChange, isRehydrating]);

  return (
    <div className="flex w-full flex-row items-center gap-2 text-lg">
      <NumberSelect
        label="Day"
        options={getDayOptions(month ?? undefined, year ?? undefined)}
        selectedOption={day}
        buttonText={(day) => (day ? day.toString() : "Day")}
        onSelect={(day) => {
          setDay(day);
          onChange(getValue(year, month, day));
        }}
        isOpen={dayOpen}
        onOpenChange={setDayOpen}
        isError={!day && !!isError}
      />
      <NumberSelect
        label="Month"
        buttonText={(month) =>
          month
            ? new Date(0, month, 0).toLocaleDateString("en-US", {
                month: "short",
              })
            : "Month"
        }
        options={getMonthOptions()}
        selectedOption={month}
        onSelect={(month) => {
          setMonth(month);
          onChange(getValue(year, month, day));
        }}
        isOpen={monthOpen}
        onOpenChange={setMonthOpen}
        isError={!month && !!isError}
      />
      <NumberSelect
        label="Year"
        buttonText={(year) => (year ? year.toString() : "Year")}
        options={getYearOptions()}
        selectedOption={year}
        onSelect={(year) => {
          setYear(year);
          onChange(getValue(year, month, day));
        }}
        isOpen={yearOpen}
        onOpenChange={setYearOpen}
        isError={!year && !!isError}
      />
    </div>
  );
};

const NumberSelect = ({
  label,
  options,
  selectedOption,
  buttonText,
  onSelect,
  isOpen,
  onOpenChange,
  isError,
}: {
  label: string;
  options: { label: string; value: number }[];
  selectedOption: number | null;
  buttonText: (selectedOption: number | null) => string;
  onSelect: (option: number) => void;
  isOpen: boolean;
  isError: boolean;
} & Pick<DropdownMenuProps, "onOpenChange">) => (
  <DropdownMenu onOpenChange={onOpenChange}>
    <DropdownMenuTrigger asChild>
      <Button
        variant="outline"
        className={cn(
          "flex flex-1 flex-row items-center justify-between border-b-2 border-input/40 bg-white/40 py-6 text-lg hover:bg-white/40 max-sm:px-2 max-sm:pl-3",
          isOpen && "bg-white ring-2 ring-primary/50",
          isError ? "ring-2 ring-destructive/60" : ""
        )}
      >
        {buttonText(selectedOption)}
        <ChevronDown
          className={cn(
            "h-6 w-6 shrink-0 opacity-50 transition-transform",
            isOpen && "rotate-180"
          )}
        />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent align="start" className="max-h-64 overflow-auto">
      <DropdownMenuLabel>{label}</DropdownMenuLabel>
      <DropdownMenuSeparator />
      {options.map((option) => (
        <DropdownMenuItem
          key={option.value}
          onClick={() => onSelect(option.value)}
          className={cn(
            "cursor-pointer hover:bg-background",
            selectedOption === option.value
              ? "bg-accent text-accent-foreground"
              : ""
          )}
        >
          {option.label}
        </DropdownMenuItem>
      ))}
    </DropdownMenuContent>
  </DropdownMenu>
);
