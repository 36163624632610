import { STEPS } from "../config/steps";
import { similaritySchema } from "../schemas/similaritySchema";
import { StoryIdeas } from "../schemas/storyIdeasSchema";
import { useGlobalStore } from "../store/global";
import { FetchError } from "../utils/FetchError";
import { useDisplayErrorToast } from "../utils/useDisplayErrorToast";
import { queryKeys } from "./queryKeys";
import { useHeroImages } from "./useHeroImages";
import { useStories } from "./useStories";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

// Max polling time 5 minutes (+ latency and response time x300 times)
const MAX_FETCH_ATTEMPTS = 300;
const FETCH_POLL_INTERVAL = 1000;

type GetStoryImageParams = {
  story_title: string;
  story_text: string;
  image_url: string;
  story_id?: number;
  storyIdeas?: StoryIdeas;
};

const storyImageSchema = z.object({
  final_image_url: z.string().min(1),
});

export const useStoryImage = () => {
  const { order_reference_id, params } = useOrderReferenceIdAndParams();

  const { formData, images, fullStories, editedStories } = useGlobalStore();

  // Edited story
  const storyId = fullStories?.[0].story_id ?? 0;
  const currentEdits = editedStories[storyId];
  // Query params
  const editedStoryParams = {
    story_title: currentEdits?.title || fullStories?.[0].title,
    story_text: currentEdits?.text || fullStories?.[0].text,
    // image_url: heroImageUrl,
  };

  return useQuery({
    queryKey: [
      queryKeys.GET_STORY_IMAGE,
      order_reference_id,
      params?.image_url,
      formData.hero_similarity_image_number,
      editedStoryParams,
      images,
    ],
    queryFn: () => getStoryImage(order_reference_id),
    // enabled: !!params?.image_url,
    retry: shouldRetry,
    retryDelay: FETCH_POLL_INTERVAL,
  });
};

export const useStoryImageGeneration = ({ start }: { start: boolean }) => {
  const { order_reference_id, params } = useOrderReferenceIdAndParams();

  const { isError, isSuccess, isLoading } = useQuery({
    queryKey: [
      queryKeys.GENERATE_STORY_IMAGE,
      order_reference_id,
      params?.image_url,
    ],
    queryFn: () => startStoryImageGeneration(order_reference_id, params!),
    enabled: !!params && start,
  });

  useDisplayErrorToast({ isError: start && isError });

  return {
    isStartStoryImageGenerationLoading: isLoading,
    isStartStoryImageGenerationSuccess: isSuccess,
    isStartHeroImagesGenerationError: isError,
  };
};

const getEditedStoriesLocal = () => {
  const orderForm = localStorage.getItem("br_order_form");
  if (!orderForm) return null;

  const parsedOrderForm = JSON.parse(orderForm);
  return parsedOrderForm.editedStories || null;
};

export async function startStoryImageGeneration(
  order_reference_id: string,
  params: GetStoryImageParams
) {
  const url = new URL("/preview/order-assistant", import.meta.env.VITE_API_URL);
  url.searchParams.append("task", "story_image");
  url.searchParams.append("order_reference_id", order_reference_id);

  // Check if user has edited story title and text
  if (params?.story_id) {
    const editedStoriesLocal = getEditedStoriesLocal();
    const editedStory = editedStoriesLocal?.[params.story_id];
    if (editedStory) {
      params.story_text = editedStory.text;
      params.story_title = editedStory.title;
    }
  }

  if (process.env.NODE_ENV === "development") {
    console.log("Starting story image generation", params);
  }

  // Remove story_id and storyIdeas from params
  // if exists to ensure API compatibility.
  const { story_id, storyIdeas, ...restParams } = params;

  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      story_image_inputs: restParams,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to start story image generation");
  }

  return true;
}

export async function getStoryImage(order_reference_id: string) {
  const url = new URL(
    "/preview/order-assistant/get-results",
    import.meta.env.VITE_API_URL
  );
  url.searchParams.append("task", "story_image");
  url.searchParams.append("order_reference_id", order_reference_id);

  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
  });

  if (!response.ok) {
    throw new FetchError("Failed to fetch story image", STEPS.STEP_8);
  }

  const data = await response.json();
  const lastItem = data[data.length - 1];

  if (
    lastItem.status === "completed" &&
    lastItem.results &&
    Object.keys(lastItem.results).length === 0
  ) {
    throw new FetchError(
      "Story image generation has failed, need to restart it",
      STEPS.STEP_8,
      true
    );
  }

  if (lastItem.status === "pending") {
    throw new FetchError("Story image is still being generated", STEPS.STEP_8);
  }

  if (lastItem.results.length === 0) {
    throw new FetchError("Story image does not exist", STEPS.STEP_8);
  }

  return storyImageSchema.parse(lastItem.results).final_image_url;
}

export const useOrderReferenceIdAndParams = () => {
  const { order_reference_id, getFormData, storyIdeas } = useGlobalStore();

  const { data: stories } = useStories({ start: true });
  const { data: heroImages } = useHeroImages();
  const data = getFormData(similaritySchema);

  const getImageUrl = () => {
    const imageIndex = data!.hero_similarity_image_number - 1;

    const isEnhanced = Object.keys(heroImages?.enhanced ?? {}).length > 0;

    if (isEnhanced) {
      return heroImages!.enhanced!.image_urls![imageIndex];
    }

    return heroImages!.hero_preview_image_urls_low_res[imageIndex];
  };

  const params: GetStoryImageParams | undefined =
    data && stories && heroImages
      ? {
          story_id: stories[0]?.story_id,
          story_title: stories[0]?.title,
          story_text: stories[0]?.text,
          image_url: getImageUrl(),
          storyIdeas: storyIdeas,
        }
      : undefined;

  return { order_reference_id, params };
};

const shouldRetry = (failureCount: number, error: FetchError) => {
  if (failureCount >= MAX_FETCH_ATTEMPTS) {
    console.error("Exceeded max fetch attempts for 'getStoryImage'");
    return false;
  }

  if (error.abortRetry) {
    console.error(error);
    return false;
  }

  return true;
};
