import { Button } from "./ui/button";
import { X } from "lucide-react";

export function CloseFormButton() {
  return (
    <Button
      variant="ghost"
      size="icon"
      onClick={(e) => {
        e?.preventDefault();
        window.location.href = "https://blossomreads.com/";
      }}
    >
      <X />
    </Button>
  );
}
