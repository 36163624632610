import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./instrument";
import { handleRedirect } from "./lib/loaders/handleRedirect.ts";
import { CheckStatusPage } from "./pages/check-status.tsx";
import { ChildDetailsPage } from "./pages/child-details.tsx";
import { CustomerDetailsPage } from "./pages/customer-details.tsx";
import { ErrorPage } from "./pages/errorPage.tsx";
import { OccasionPage } from "./pages/occasion.tsx";
import { OverviewPage } from "./pages/overview.tsx";
import { PersonalisationPage } from "./pages/personalisation.tsx";
import { PhotosPage } from "./pages/photos.tsx";
import { RehydratePage } from "./pages/rehydrate.tsx";
import { ResetPage } from "./pages/reset.tsx";
import { SimilarityPage } from "./pages/similarity.tsx";
import * as Sentry from "@sentry/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "@/styles/global.css";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: handleRedirect,
    children: [
      {
        path: "rehydrate",
        element: <RehydratePage />,
      },
      { path: "check-status", element: <CheckStatusPage /> },
      { path: "reset", element: <ResetPage /> },
      {
        index: true,
        element: <ChildDetailsPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: ":lang",
        element: <ChildDetailsPage />,
      },
      {
        path: "child-details",
        element: <ChildDetailsPage />,
      },
      {
        path: "occasion",
        element: <OccasionPage />,
      },
      {
        path: "personalisation",
        element: <PersonalisationPage />,
      },
      {
        path: "photos",
        element: <PhotosPage />,
      },
      {
        path: "customer-details",
        element: <CustomerDetailsPage />,
      },
      {
        path: "similarity",
        element: <SimilarityPage />,
      },
      {
        path: "cart",
        element: <OverviewPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
