import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import bannerImage from "@/assets/banners/1.avif";
import { Banner } from "@/components/Banner";
import { DateInput } from "@/components/DateInput";
import { FormBody } from "@/components/FormBody";
import { FormField } from "@/components/FormField";
import { FormHeader } from "@/components/FormHeader";
import { GenderSelect } from "@/components/GenderSelect";
import { SubmitButton } from "@/components/SubmitButton";
import { ContinueWhereYouLeftOff } from "@/components/orderForm/continueWhereYouLeft";
import { Layout } from "@/components/ui/Layout";
import { Input } from "@/components/ui/input";
import { STEPS } from "@/lib/config/steps";
import {
  useInitialPageLoadSubmission,
  useSubmitFormData,
} from "@/lib/hooks/useSubmitFormData";
import {
  ChildDetails,
  ChildDetailsOptional,
  childDetailsOptionalSchema,
  childDetailsSchema,
} from "@/lib/schemas/childDetailsSchema";
import { useGlobalStore } from "@/lib/store/global";
import { useStepNavigation } from "@/lib/utils/useStepNavigation";

const CURRENT_STEP = STEPS.STEP_1 as keyof typeof STEPS;

export function ChildDetailsPage() {
  const { getFormData, deviceId } = useGlobalStore();
  const data = getFormData(childDetailsOptionalSchema);
  const submitInitialPageLoad = useInitialPageLoadSubmission();

  useEffect(() => {
    if (deviceId) {
      submitInitialPageLoad(deviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  return <ChildDetailsForm data={data ?? {}} />;
}

function ChildDetailsForm({ data }: Readonly<{ data: ChildDetailsOptional }>) {
  const { saveFormData } = useGlobalStore();
  const {
    register,
    handleSubmit,
    formState,
    watch,
    control,
    formState: { errors },
  } = useForm<ChildDetails>({
    resolver: zodResolver(childDetailsSchema),
    defaultValues: data,
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToNextStep } = useStepNavigation(CURRENT_STEP);
  const heroName = watch("hero_name");
  const { submitData, isPending } = useSubmitFormData(CURRENT_STEP);
  const onSubmit = () => submitData().finally(() => goToNextStep());

  return (
    <Layout>
      <FormHeader
        title={`${heroName || "Child"}'s Adventure`}
        currentStep={CURRENT_STEP}
        onBack={() => {}}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="h-fit">
              <div className="relative mx-auto">
                <Banner src={bannerImage} />
              </div>
              <FormField
                inputId="br_order_hero_name"
                label="First Name"
                description="Who is this book for?"
              >
                <Input
                  {...register("hero_name")}
                  id="br_order_hero_name"
                  placeholder="Ex. Oliver"
                  required
                  isError={!!errors.hero_name}
                />
              </FormField>
              <FormField
                inputId="br_order_hero_location"
                label="Location"
                description={
                  data.hero_name
                    ? `Where do ${data.hero_name} live?`
                    : "Where do they live?"
                }
              >
                <Input
                  {...register("hero_location")}
                  id="br_order_hero_location"
                  placeholder="Ex. London, UK"
                  required
                  isError={!!errors.hero_location}
                />
              </FormField>
              <FormField
                label="Date of Birth"
                description="Help us tailor stories to their age."
              >
                <Controller
                  name="hero_dob"
                  control={control}
                  render={({ field }) => (
                    <DateInput
                      value={field.value}
                      onChange={field.onChange}
                      isError={!!errors.hero_dob}
                    />
                  )}
                />
              </FormField>
              <FormField
                label="Child's Gender"
                description="Ensures story accuracy."
              >
                <Controller
                  name="hero_gender"
                  control={control}
                  render={({ field }) => (
                    <GenderSelect
                      onChange={field.onChange}
                      value={field.value}
                      isError={!!errors.hero_gender}
                    />
                  )}
                />
              </FormField>
            </div>
          </div>
          <SubmitButton isLoading={isPending} />
        </FormBody>
      </form>

      {!formState.isSubmitting && !formState.isSubmitted && (
        <ContinueWhereYouLeftOff currentStep={CURRENT_STEP} />
      )}
    </Layout>
  );
}
