import { useCallback, useEffect, useRef, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { throttle } from "lodash";
import { FilePenLine, History } from "lucide-react";
import { motion } from "motion/react";
import { cn } from "@/lib/utils";

const TITLE_CHARACTER_LIMIT = 60;
const APPROX_CHAR_LIMIT = 1400;
const TEXT_WORD_LIMIT = 210;

interface EditStoryDialogProps {
  title: string;
  text: string;
  initialTitle?: string;
  initialText?: string;
  storyIndex?: number;
  truncateLength?: number;
  onSave: (title: string, text: string, index?: number) => Promise<void> | void;
  isSaving?: boolean;
  className?: string;
  onRevert?: () => void;
  hasEdits?: boolean;
}

export default function EditStoryDialog({
  title: initialTitle,
  text: initialText,
  initialTitle: externalInitialTitle,
  initialText: externalInitialText,
  truncateLength = 120,
  onSave,
  storyIndex,
  className,
  isSaving = false,
  onRevert,
  hasEdits = false,
}: Readonly<EditStoryDialogProps>) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const [text, setText] = useState(initialText);
  const [wordCount, setWordCount] = useState(0);
  const [isAllowedWordCount, setIsAllowedWordCount] = useState(true);
  const [charCount, setCharCount] = useState(0);
  const [isAllowedCharCount, setIsAllowedCharCount] = useState(true);
  const [hasContentChanged, setHasContentChanged] = useState(false);
  const [pendingRevert, setPendingRevert] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const titleEditRef = useRef<HTMLTextAreaElement>(null);
  const contentEditRef = useRef<HTMLTextAreaElement>(null);
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const initialSafeHeight = useRef<number>(0);

  const visibleContent = initialText.substring(0, truncateLength);
  const hasMoreContent = initialText.length > truncateLength;

  useEffect(() => {
    const wordCount = countWords(text);
    setWordCount(wordCount);
    setIsAllowedWordCount(wordCount <= TEXT_WORD_LIMIT);

    setCharCount(text.length);
    setIsAllowedCharCount(text.length <= APPROX_CHAR_LIMIT);
  }, [text]);

  // Function to auto-resize textarea for title only
  const resizeTitleTextarea = useCallback(() => {
    const textarea = titleEditRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, []);

  // Reset values when dialog opens with new data
  useEffect(() => {
    if (isDialogOpen) {
      setTitle(initialTitle);
      setText(initialText);
      setHasContentChanged(false);
      setPendingRevert(false);
    }
  }, [isDialogOpen, initialTitle, initialText]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (isDialogOpen) {
      timeoutRef.current = setTimeout(() => {
        resizeTitleTextarea();
      }, 50);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [isDialogOpen, resizeTitleTextarea]);

  const skipToContent = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      if (contentEditRef.current) {
        contentEditRef.current.selectionStart = 0;
        contentEditRef.current.selectionEnd = 0;
        contentEditRef.current.scrollTop = 0;
        contentEditRef.current.focus();
      }
    }, 50);
  }, []);

  const focusTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const contentSelectionRef = useRef<number>(-1);
  const handleFocusContent = useCallback(() => {
    focusTimeoutRef.current = setTimeout(() => {
      const textarea = contentEditRef.current;
      if (textarea) {
        const { selectionStart, selectionEnd } = textarea;
        if (selectionStart !== selectionEnd) {
          contentSelectionRef.current = -1;
        } else {
          contentSelectionRef.current = selectionStart;
        }
      }
    }, 10);
  }, []);

  const handleSelectChange = useCallback(() => {
    const textarea = contentEditRef.current;
    if (textarea) {
      const { selectionStart, selectionEnd } = textarea;
      if (selectionStart !== selectionEnd) {
        contentSelectionRef.current = -1;
      } else {
        contentSelectionRef.current = selectionStart;
      }
    }
  }, []);

  // Restore scroll position when dialog is closed
  const lastScrollPositionRef = useRef(0);
  useEffect(() => {
    if (isDialogOpen) {
      lastScrollPositionRef.current = window.scrollY;
    } else {
      window.scrollTo(0, lastScrollPositionRef.current);
    }
  }, [isDialogOpen]);

  // Improved viewport and dialog sizing management
  const resizeTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const viewportHeightRef = useRef<number>(0);

  useEffect(() => {
    if (!isDialogOpen) return;

    // Store initial height for reference
    initialSafeHeight.current = window.innerHeight;
    viewportHeightRef.current =
      window.visualViewport?.height || window.innerHeight;

    // Update CSS variable for viewport height
    const updateViewportHeight = () => {
      const viewportHeight =
        window.visualViewport?.height || window.innerHeight;
      const dialogContent = dialogContentRef.current;
      if (dialogContent) {
        (dialogContent as HTMLElement).style.setProperty(
          "--viewport-height",
          `${viewportHeight}px`
        );
      }
      viewportHeightRef.current = viewportHeight;
    };

    // Handler for viewport changes (keyboard appearing, device rotation, etc.)
    const handleResizeCore = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = setTimeout(() => {
        const viewportHeight =
          window.visualViewport?.height || window.innerHeight;
        const viewportTop = window.visualViewport?.offsetTop || 0;
        const dialogContent =
          dialogContentRef.current || document.querySelector("[role='dialog']");

        if (!dialogContent || !(dialogContent instanceof HTMLElement)) return;

        // Update viewport height CSS variable
        updateViewportHeight();

        // Different handling for mobile vs. desktop
        if (window.innerWidth < 640) {
          const keyboardLikelyVisible =
            viewportHeight < initialSafeHeight.current * 0.9;

          // sm breakpoint in tailwind
          dialogContent.style.position = "fixed";
          dialogContent.style.height = `${keyboardLikelyVisible ? viewportHeight : viewportHeight * 0.85}px`;

          // Set top position based on viewport changes
          const bufferTop = (viewportHeight * 0.15) / 2;
          dialogContent.style.top = `${keyboardLikelyVisible ? viewportTop : viewportTop + bufferTop}px`;
          dialogContent.style.bottom = "auto";
          dialogContent.style.transform = "none";
          dialogContent.style.margin = "0";
          dialogContent.style.maxHeight = "none";
          dialogContent.style.borderRadius = "0";

          // Ensure full width on mobile
          dialogContent.style.width = "100%";
          dialogContent.style.maxWidth = "100%";
        } else {
          // On larger screens, ensure proper centering
          dialogContent.style.position = "fixed";
          dialogContent.style.top = "50%";
          dialogContent.style.left = "50%";
          dialogContent.style.transform = "translate(-50%, -50%)";
          dialogContent.style.margin = "auto";
          dialogContent.style.height = "auto";
          dialogContent.style.maxHeight = "90vh";
          dialogContent.style.width = "90vw";
          dialogContent.style.maxWidth = "600px";
        }

        // Check if content textarea is currently focused and restore cursor position
        const contentTextarea = contentEditRef.current;
        const isContentFocused = document.activeElement === contentTextarea;

        if (
          contentTextarea &&
          isContentFocused &&
          contentSelectionRef.current > -1
        ) {
          contentTextarea.setSelectionRange(
            contentSelectionRef.current,
            contentSelectionRef.current
          );

          // Scroll to appropriate position based on cursor location
          const textLength = contentTextarea.value.length;
          const position = contentSelectionRef.current;
          const scrollHeight = contentTextarea.scrollHeight;

          // Adjust scrolling based on cursor position with 25% gradations
          if (position < textLength * 0.25) {
            contentTextarea.scrollTop = 0;
          } else if (position < textLength * 0.5) {
            contentTextarea.scrollTop = scrollHeight * 0.25;
          } else if (position < textLength * 0.75) {
            contentTextarea.scrollTop = scrollHeight * 0.5;
          } else {
            contentTextarea.scrollTop = scrollHeight * 0.75;
          }

          // const coords = createCursorCoordinates(
          //   contentTextarea,
          //   contentSelectionRef.current
          // );
          // const cursorPosition =
          //   coords.top - contentTextarea.getBoundingClientRect().top - 100; // 100px buffer
          // contentTextarea.scrollTop = cursorPosition;
        }
      }, 20);
    };

    const handleResize = throttle(handleResizeCore, 20);
    const handleResizeLate = throttle(handleResizeCore, 100, {
      trailing: true,
    });

    // Better handling of visual viewport changes
    window.visualViewport?.addEventListener("resize", handleResizeCore);
    window.visualViewport?.addEventListener("resize", handleResizeLate);
    window.visualViewport?.addEventListener("scroll", handleResize);
    window.addEventListener("resize", handleResizeCore);
    window.addEventListener("resize", handleResizeLate);

    // Improved body scroll locking
    const html = document.documentElement;
    const body = document.body;
    // const scrollY = window.scrollY;

    // Fix position to prevent scrolling
    [html, body].forEach((el) => {
      // el.style.position = "fixed";
      el.style.overflow = "hidden";
      // el.style.width = "100%";
      // el.style.top = `-${scrollY}px`;
    });

    // Allow scrolling only inside dialog content
    const preventScroll = (e: TouchEvent) => {
      const target = e.target;
      const isInsideScrollable =
        target instanceof Node && contentEditRef.current?.contains(target);

      if (!isInsideScrollable) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", preventScroll, { passive: false });

    // Initial call to set up the dialog correctly
    handleResizeCore();

    return () => {
      // Clean up all event listeners and timeouts
      handleResize.cancel();
      handleResizeLate.cancel();

      window.visualViewport?.removeEventListener("resize", handleResizeCore);
      window.visualViewport?.removeEventListener("resize", handleResizeLate);
      window.visualViewport?.removeEventListener("scroll", handleResize);
      window.removeEventListener("resize", handleResizeCore);
      window.removeEventListener("resize", handleResizeLate);

      // Restore original styles
      [html, body].forEach((el) => {
        el.style.position = "";
        el.style.overflow = "";
        el.style.width = "";
        // el.style.top = "";
      });

      // window.scrollTo(0, scrollY);
      document.removeEventListener("touchmove", preventScroll);

      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
        resizeTimeoutRef.current = null;
      }
    };
  }, [isDialogOpen]);

  // Get cursor position coordinates by creating a temporary element
  const createCursorCoordinates = useCallback(
    (contentTextarea: HTMLTextAreaElement, cursorPosition: number) => {
      // Create a hidden div with the same styling
      const mirror = document.createElement("div");
      const style = window.getComputedStyle(contentTextarea);

      // Copy styles to ensure text flows the same way
      mirror.style.width = style.width;
      mirror.style.fontSize = style.fontSize;
      mirror.style.fontFamily = style.fontFamily;
      mirror.style.lineHeight = style.lineHeight;
      mirror.style.padding = style.padding;
      mirror.style.position = "absolute";
      mirror.style.visibility = "hidden";

      // Get text up to cursor position
      const textUpToCursor = contentTextarea.value.substring(0, cursorPosition);
      mirror.textContent = textUpToCursor;

      // Add a span at cursor position
      const cursorSpan = document.createElement("span");
      cursorSpan.id = "cursor-position";
      mirror.appendChild(cursorSpan);

      document.body.appendChild(mirror);
      const coords = cursorSpan.getBoundingClientRect();
      document.body.removeChild(mirror);

      return coords;
    },
    []
  );

  const handleSave = async () => {
    if (titleEditRef.current && contentEditRef.current) {
      const newTitle = titleEditRef.current.value || title;
      const newText = contentEditRef.current.value || text;

      await onSave(newTitle, newText, storyIndex);

      setIsDialogOpen(false);
      setPendingRevert(false);
    }
  };

  const handleClose = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsDialogOpen(false);
    setPendingRevert(false);
  }, []);

  const handleRequestRevert = () => {
    if (onRevert && externalInitialTitle && externalInitialText) {
      setTitle(externalInitialTitle);
      setText(externalInitialText);

      setHasContentChanged(hasEdits);
      setPendingRevert(true);

      contentEditRef.current?.focus();
      contentEditRef.current?.setSelectionRange(0, 0);

      // Resize title area after reverting
      setTimeout(resizeTitleTextarea, 50);
    }
  };

  // Clear timeouts on unmount
  useEffect(() => {
    return () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
        resizeTimeoutRef.current = null;
      }
    };
  }, []);

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length <= TITLE_CHARACTER_LIMIT) {
      setTitle(e.target.value);

      // If user changes content after revert, clear pending revert flag
      if (pendingRevert && e.target.value !== externalInitialTitle) {
        setPendingRevert(false);
      }

      // Check if title has changed from initial value
      setHasContentChanged(
        e.target.value !== initialTitle || text !== initialText
      );
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    if (newText.length <= APPROX_CHAR_LIMIT || newText.length < text.length) {
      setText(newText);

      // If user changes content after revert, clear pending revert flag
      if (pendingRevert && newText !== externalInitialText) {
        setPendingRevert(false);
      }

      // Check if text has changed from initial value
      setHasContentChanged(newText !== initialText || title !== initialTitle);
    }
  };

  const [isCloseCooldown, setIsCloseCooldown] = useState(false);
  const closeCooldownTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    return () => {
      if (closeCooldownTimeoutRef.current) {
        clearTimeout(closeCooldownTimeoutRef.current);
        closeCooldownTimeoutRef.current = null;
      }
    };
  }, []);

  const handleOpenChange = useCallback((open: boolean) => {
    setIsDialogOpen(open);
    if (!open) {
      setIsCloseCooldown(true);
      // Reset cooldown after a short delay
      closeCooldownTimeoutRef.current = setTimeout(
        () => setIsCloseCooldown(false),
        300
      );
    }
  }, []);

  const handleDialogClick = useCallback(
    (e: React.MouseEvent) => {
      e?.preventDefault();

      if (!isDialogOpen && !isCloseCooldown) {
        setIsDialogOpen(true);
      }
    },
    [isDialogOpen, isCloseCooldown]
  );

  return (
    <div
      ref={containerRef}
      className={cn(
        "relative flex flex-col rounded-lg bg-card px-4 py-3 sm:px-5",
        className
      )}
      onClick={handleDialogClick}
    >
      {storyIndex !== undefined && (
        <span className="text-s mb-1 text-muted-foreground">
          Story {storyIndex}
        </span>
      )}
      <h3 className="mb-2 text-lg font-semibold leading-[1.2]">
        {initialTitle}
      </h3>

      <p className="overflow-hidden leading-[1.5]">
        {visibleContent}
        {hasMoreContent && "..."}
      </p>

      {/* Action buttons */}
      <div className="mt-2 border-t pt-2">
        <div className="flex w-full items-center justify-end gap-2">
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setIsDialogOpen(true);
            }}
            className="flex items-center gap-2 text-sm font-medium text-primary"
          >
            <span>Read and Edit</span>
            <FilePenLine className="h-4 w-4" />
          </button>
        </div>
      </div>

      <Dialog.Root open={isDialogOpen} onOpenChange={handleOpenChange}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-10" />

          <Dialog.Content
            ref={dialogContentRef}
            onOpenAutoFocus={(e) => {
              e.preventDefault();
            }}
            className={cn(
              "fixed grid grid-rows-[auto_auto_1fr_auto] bg-card focus:outline-none sm:rounded-lg sm:shadow-md",
              // Mobile: ensure full height and respect safe areas
              "p-safe h-[var(--viewport-height,85vh)] max-h-none overflow-auto",
              // Desktop: centered positioning
              "sm:inset-auto sm:left-[50%] sm:top-[50%] sm:m-4 sm:h-auto sm:max-h-[90vh] sm:min-h-[680px] sm:w-[90vw] sm:max-w-[600px] sm:translate-x-[-50%] sm:translate-y-[-50%] sm:overflow-hidden",
              "px-4 py-3 sm:px-5"
            )}
            style={
              {
                animation: isDialogOpen
                  ? "dialogContentShow 450ms cubic-bezier(0.16, 1, 0.3, 1) 0.1s forwards"
                  : "dialogContentHide 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards",
                opacity: isDialogOpen ? 0 : 1, // to ensure fade in and out animations work
                // paddingTop: "max(env(safe-area-inset-top, 16px), 16px)",
                // paddingRight: "max(env(safe-area-inset-right, 16px), 16px)",
                // paddingBottom: "max(env(safe-area-inset-bottom, 16px), 16px)",
                // paddingLeft: "max(env(safe-area-inset-left, 16px), 16px)",
                // Set a CSS variable for viewport height that will be updated by JS
                "--viewport-height": `${viewportHeightRef.current || window.innerHeight}px`,
              } as React.CSSProperties
            }
          >
            <style>{`
              @keyframes dialogContentShow {
                from {
                  opacity: 0;
                  transform: translate(-50%, -50%) scale(0.95);
                }
                to {
                  opacity: 1;
                  transform: translate(-50%, -50%) scale(1);
                }
              }

              @keyframes dialogContentHide {
                from {
                  opacity: 1;
                  transform: translate(-50%, -50%) scale(1);
                }
                to {
                  opacity: 0;
                  transform: translate(-50%, -50%) scale(0.95);
                }
              }

              @media (max-width: 640px) {
                @keyframes dialogContentShow {
                  from {
                    opacity: 0;
                    transform: scale(0.95);
                  }
                  to {
                    opacity: 1;
                    transform: scale(1);
                  }
                }

                @keyframes dialogContentHide {
                  from {
                    opacity: 1;
                    transform: scale(1);
                  }
                  to {
                    opacity: 0;
                    transform: scale(0.95);
                  }
                }
              }
            `}</style>
            <Dialog.Title>
              <span className="text-s mb-1 text-muted-foreground">
                Story {storyIndex ?? ""}
              </span>
            </Dialog.Title>

            <Dialog.Description className="sr-only">
              Edit your story details
            </Dialog.Description>

            {/* Title */}
            <textarea
              ref={titleEditRef}
              autoFocus={false}
              value={title}
              onChange={handleTitleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  skipToContent();
                }
              }}
              className="w-full resize-none overflow-hidden border-b bg-transparent pb-2 text-xl font-semibold leading-[1.2] outline-none"
              rows={1}
              placeholder="Your story title"
              style={{
                WebkitUserSelect: "text",
                userSelect: "text",
                WebkitTouchCallout: "default",
                touchAction: "auto",
              }}
              maxLength={TITLE_CHARACTER_LIMIT}
              onInput={resizeTitleTextarea}
            />

            {/* Content */}
            <div className="relative min-h-0">
              <textarea
                ref={contentEditRef}
                autoFocus={false}
                value={text}
                onChange={handleTextChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.shiftKey) {
                    e.preventDefault();
                    titleEditRef.current?.focus();
                  }
                }}
                onFocus={handleFocusContent}
                onSelect={handleSelectChange}
                className="h-full w-full resize-none overflow-y-auto whitespace-pre-wrap bg-transparent py-3 text-base leading-[1.5] outline-none"
                placeholder="Your story content"
                style={{
                  userSelect: "text",
                  touchAction: "auto",
                  WebkitUserSelect: "text",
                  WebkitTouchCallout: "default",
                  WebkitOverflowScrolling: "touch",
                }}
              />
            </div>

            <div className="flex justify-between gap-2 border-t pt-3">
              <div
                className={cn(
                  "self-center text-sm text-muted-foreground",
                  !isAllowedCharCount && "text-primary"
                )}
              >
                {charCount}/{APPROX_CHAR_LIMIT}
              </div>

              <div className="flex items-center gap-2 sm:gap-4">
                {/* Show revert button only if we have edits and not already reverted */}
                {(hasContentChanged || hasEdits) &&
                  onRevert &&
                  !pendingRevert &&
                  !isSaving && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleRequestRevert();
                        }}
                        disabled={isSaving}
                        className="-ml-4 flex items-center gap-1 rounded-md px-4 py-2 text-sm text-secondary-foreground/80 hover:bg-muted hover:text-foreground"
                      >
                        <History className="h-4 w-4" />
                        <span className="ml-1">Reset to Original</span>
                      </button>
                    </motion.div>
                  )}

                {hasContentChanged ? (
                  // Show Save button only when content has changed
                  <button
                    type="button"
                    onClick={handleSave}
                    className="rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground hover:bg-primary/90 disabled:opacity-70"
                    disabled={isSaving}
                  >
                    {isSaving ? "Saving..." : "Save & Close"}
                  </button>
                ) : (
                  // Show Close button when no changes made
                  <button
                    type="button"
                    onClick={handleClose}
                    className="rounded-md border border-secondary bg-secondary px-4 py-2 text-sm font-medium text-secondary-foreground hover:border-secondary-foreground/40 hover:bg-secondary/80 disabled:opacity-70"
                  >
                    Close
                  </button>
                )}
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  );
}

const countWords = (str: string) => {
  return str.trim().split(/\s+/).filter(Boolean).length;
};
