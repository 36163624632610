import { z } from 'zod';

export const storyIdeasSchema = z.object({
  story_title_1: z.string(),
  story_summary_1: z.string(),
  story_1_personalisation_tags: z.string(),
  story_title_2: z.string(),
  story_summary_2: z.string(),
  story_2_personalisation_tags: z.string(),
  story_title_3: z.string(),
  story_summary_3: z.string(),
  story_3_personalisation_tags: z.string(),
  story_title_4: z.string(),
  story_summary_4: z.string(),
  story_4_personalisation_tags: z.string(),
  story_title_5: z.string(),
  story_summary_5: z.string(),
  story_5_personalisation_tags: z.string(),
  story_title_6: z.string(),
  story_summary_6: z.string(),
  story_6_personalisation_tags: z.string(),
});
export type StoryIdeas = z.infer<typeof storyIdeasSchema>;
