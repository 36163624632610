type FormFieldProps = {
  inputId?: string;
  label: string;
  description?: string;
  optional?: boolean;
  children: React.ReactNode;
};

export const FormField = ({
  inputId,
  label,
  description,
  optional,
  children,
}: FormFieldProps) => {
  return (
    <div className="flex w-full flex-col items-start justify-start gap-1 py-4">
      <div className="mb-2 flex flex-col">
        <label htmlFor={inputId} className="align-baseline text-2xl">
          {label}
          {optional && (
            <span className="cursor-default text-[0.8em] font-light text-secondary-foreground">
              &nbsp;(optional)
            </span>
          )}
        </label>
        {description && (
          <p className="max-w-[40ch] text-pretty font-light leading-snug text-secondary-foreground">
            {description}
          </p>
        )}
      </div>
      {children}
    </div>
  );
};
