import { useCallback, useEffect, useState } from "react";
import { ChildDetails } from "../../../schemas/childDetailsSchema";
import { getOccasionSuggestions } from "./getOccasionSuggestions";
import { useGlobalStore } from "@/lib/store/global";

export function useOccasionSuggestions(params: ChildDetails) {
  const { order_reference_id } = useGlobalStore();
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [allPreviousSuggestions, setAllPreviousSuggestions] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const fetchSuggestions = useCallback(async () => {
    setLoading(true);
    try {
      const data = await getOccasionSuggestions(order_reference_id, params);
      setSuggestions(data || []);
      setAllPreviousSuggestions(data || []);
    } finally {
      setLoading(false);
    }
  }, [order_reference_id, params]);

  const fetchMoreSuggestions = useCallback(
    async (options?: { replace?: boolean }) => {
      if (loadingMore) return;
      setLoadingMore(true);
      try {
        const newSuggestions = await getOccasionSuggestions(
          order_reference_id,
          params,
          allPreviousSuggestions
        );
        if (options?.replace) {
          setSuggestions(newSuggestions || []);
        } else {
          setSuggestions((prev) => {
            // Filter out any duplicates that might have been returned
            const uniqueNewSuggestions =
              newSuggestions?.filter(
                (suggestion: string) => !prev.includes(suggestion)
              ) || [];
            return [...prev, ...uniqueNewSuggestions];
          });
        }

        // Always update the complete history of suggestions
        setAllPreviousSuggestions((prev) => {
          return [
            ...prev,
            ...(newSuggestions?.filter(
              (suggestion: string) => !prev.includes(suggestion)
            ) || []),
          ];
        });
      } finally {
        setLoadingMore(false);
      }
    },
    [order_reference_id, params, allPreviousSuggestions, loadingMore]
  );

  useEffect(() => {
    fetchSuggestions();
  }, [fetchSuggestions]);

  return {
    suggestions,
    loading,
    loadingMore,
    refetch: fetchSuggestions,
    fetchMore: fetchMoreSuggestions,
  };
}
