import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Tag, WrapCompactTagInput } from "./components/wrap-compact-tag-input";
import { renderCategoryIcon } from "./story-elements";
import { Check, FilePenLine, Pencil, RefreshCwIcon } from "lucide-react";
import { AnimatePresence, motion } from "motion/react";
import z from "zod";
import { LoadingDots } from "@/components/ui/LoadingDots";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAnimatedText } from "@/lib/hooks/useAnimatedText";
import { useSingleStoryIdeaStreaming } from "@/lib/hooks/useStoryIdeas";
import { useGlobalStore } from "@/lib/store/global";
import { cn } from "@/lib/utils";
import type {
  Categories,
  CategoryType,
  PersonalisationKeys,
  SummaryKeys,
  TitleKeys,
  personalisationPageSchema,
} from "@/modules/personalisation/types";
import { isValidTag } from "@/modules/personalisation/utils";

export const STORY_IDEAS_CONFIG = [1, 2, 3, 4, 5, 6].map((index) => ({
  titleKey: `story_title_${index}` as TitleKeys,
  summaryKey: `story_summary_${index}` as SummaryKeys,
  personalisationTagsKey:
    `story_${index}_personalisation_tags` as PersonalisationKeys,
  index,
}));

export const hasSomeStorieOutlinesGenerated = (
  storyIdeas: Record<string, string>
) => STORY_IDEAS_CONFIG.some(({ titleKey }) => storyIdeas[titleKey]);

const containerMotion = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -10 },
  transition: { type: "spring", damping: 40, stiffness: 450 },
};

function StoryHeader({
  index,
  isEditing,
  isSummaryComplete,
  onRewrite,
  onEdit,
  onSave,
  isAllStoriesAvailable,
  isTagsChanged,
}: Readonly<{
  index: number;
  isEditing: boolean;
  isSummaryComplete: boolean;
  onRewrite: () => void;
  onEdit: () => void;
  onSave: () => void;
  isAllStoriesAvailable?: boolean;
  isTagsChanged?: boolean;
}>) {
  return (
    <div className="z-10 flex items-center justify-between">
      <div className="text-s text-muted-foreground">Story {index}</div>
      <div
        className={cn(
          "transition-opacity duration-200",
          isSummaryComplete ? "opacity-100" : "opacity-0"
        )}
      >
        {isEditing ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Button
              type="button"
              variant="ghost"
              className="-mr-3 flex h-8 items-center gap-1.5 text-primary hover:text-primary"
              onClick={onSave}
            >
              <Check className="h-4 w-4" />
              <span>Save</span>
            </Button>
          </motion.div>
        ) : (
          <div className="relative">
            {isTagsChanged && (
              <span className="pointer-events-none absolute -right-1.5 top-0 inline-block h-2 w-2 rounded-full bg-primary" />
            )}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <Button
                    type="button"
                    variant="ghost"
                    className={cn(
                      "-mx-4 h-8 p-0 px-4 text-muted-foreground hover:text-foreground",
                      isTagsChanged ? "text-primary hover:text-primary/80" : ""
                    )}
                    aria-label="Story options"
                  >
                    <FilePenLine className="h-[18px] w-[18px]" />
                  </Button>
                </motion.div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="mr-2 text-foreground">
                <DropdownMenuItem onClick={onEdit}>
                  <Pencil className="mr-2 h-4 w-4" />
                  <span>Edit Text</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={onRewrite}
                  disabled={!isAllStoriesAvailable}
                  style={
                    isAllStoriesAvailable
                      ? {}
                      : { pointerEvents: "none", opacity: 0.5 }
                  }
                  className={cn(
                    isTagsChanged ? "text-primary hover:text-primary/80" : ""
                  )}
                >
                  <RefreshCwIcon className="mr-2 h-4 w-4" />
                  <span>Re-Write</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>
    </div>
  );
}

function StoryContainer({
  children,
  index,
  generationIndex,
  shouldAnimate,
}: Readonly<{
  children: React.ReactNode;
  index: number;
  generationIndex: number;
  shouldAnimate: boolean;
}>) {
  return (
    <motion.div
      key={`story_${index}_gen_${generationIndex}`}
      className="overflow-hidden rounded-lg border bg-white px-3 pb-3 pt-2 shadow-sm sm:px-4 sm:pb-4 sm:pt-3"
      initial={shouldAnimate ? "initial" : false}
      animate={shouldAnimate ? "animate" : false}
      variants={containerMotion}
    >
      {children}
    </motion.div>
  );
}

const TITLE_CHARACTER_LIMIT = 60;

// Combined editable component for both title and summary
const EditableStoryContent = ({
  title,
  editedTitle,
  summary,
  editedSummary,
  isEditing,
  isSummaryComplete,
  onEdit,
  onChangeTitle,
  onChangeSummary,
  onSave,
}: {
  title: string;
  editedTitle: string;
  summary: string;
  editedSummary: string;
  isEditing: boolean;
  isSummaryComplete: boolean;
  onEdit: () => void;
  onChangeTitle: (value: string) => void;
  onChangeSummary: (value: string) => void;
  onSave: () => void;
}) => {
  const titleRef = useRef<HTMLTextAreaElement>(null);
  const summaryRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const displayContainerRef = useRef<HTMLDivElement>(null);
  const displayedTitleRef = useRef<HTMLHeadingElement>(null);
  const displayedSummaryRef = useRef<HTMLParagraphElement>(null);

  // Add click outside handler
  useEffect(() => {
    if (isEditing) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target as Node)
        ) {
          onSave();
        }
      };

      document.addEventListener("mouseup", handleClickOutside);
      return () => {
        document.removeEventListener("mouseup", handleClickOutside);
      };
    }
  }, [isEditing, onSave]);

  // Auto-adjust text areas on content change
  const adjustTextAreaHeight = (element: HTMLTextAreaElement | null) => {
    if (element) {
      element.style.height = "auto";
      element.style.height = `${element.scrollHeight}px`;
    }
  };

  // Adjust both textareas
  const adjustAllTextAreas = useCallback(() => {
    adjustTextAreaHeight(titleRef.current);
    adjustTextAreaHeight(summaryRef.current);
  }, []);

  // Handle window resize
  useEffect(() => {
    if (isEditing) {
      window.addEventListener("resize", adjustAllTextAreas);
      return () => window.removeEventListener("resize", adjustAllTextAreas);
    }
  }, [isEditing, adjustAllTextAreas]);

  // When entering edit mode, set textarea heights based on display content
  useEffect(() => {
    if (isEditing) {
      // Auto-resize function that works directly with the content
      const autoResizeTextarea = (textarea: HTMLTextAreaElement | null) => {
        if (!textarea) return;

        // Reset height to calculate correct scrollHeight
        textarea.style.height = "auto";

        // Set to scrollHeight to exactly match content
        textarea.style.height = `${textarea.scrollHeight}px`;
      };

      // Apply to both textareas
      if (titleRef.current) {
        autoResizeTextarea(titleRef.current);
        titleRef.current.focus();
        const length = titleRef.current.value.length;
        titleRef.current.setSelectionRange(length, length);
      }

      if (summaryRef.current) {
        autoResizeTextarea(summaryRef.current);
      }

      // No need for the complex measurement or display manipulation
    }
  }, [isEditing]);

  // Adjust heights when content changes
  useEffect(() => {
    if (isEditing) {
      adjustAllTextAreas();
    }
  }, [editedTitle, editedSummary, isEditing, adjustAllTextAreas]);

  return (
    <>
      {/* Display View - always rendered but hidden when editing */}
      <div
        ref={displayContainerRef}
        className={cn(
          "user-select-none -ml-1 flex cursor-pointer flex-col gap-1 rounded-md p-1",
          isSummaryComplete &&
            "hover:bg-gray-50/80 hover:ring-2 hover:ring-gray-200 hover:ring-offset-1",
          isEditing ? "hidden" : "flex"
        )}
        onClick={onEdit}
      >
        <h3
          ref={displayedTitleRef}
          className="whitespace-pre-wrap text-xl font-semibold leading-[1.2]"
        >
          {title}
        </h3>
        <p
          ref={displayedSummaryRef}
          className="whitespace-pre-wrap text-base leading-[1.5]"
        >
          {summary}
        </p>
      </div>

      {/* Edit View - always rendered but hidden when not editing */}
      <div
        ref={containerRef}
        className={cn(
          "flex-col gap-1 rounded-md bg-white/70 p-1 ring-2 ring-primary/20 ring-offset-1",
          isEditing ? "-ml-1 flex" : "hidden"
        )}
      >
        <textarea
          id={`story-title-${title}`}
          ref={titleRef}
          value={editedTitle}
          onChange={(e) => {
            // Limit the input to the maximum character count
            if (e.target.value.length <= TITLE_CHARACTER_LIMIT) {
              onChangeTitle(e.target.value);
            }
          }}
          className="w-full resize-none rounded border-none text-xl font-semibold leading-[1.2] focus:outline-none"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              summaryRef.current?.focus();
            }
          }}
          maxLength={TITLE_CHARACTER_LIMIT}
          rows={1}
        />

        <textarea
          id={`story-summary-${title}`}
          ref={summaryRef}
          value={editedSummary}
          onChange={(e) => {
            onChangeSummary(e.target.value);
          }}
          className={cn(
            "w-full resize-none rounded border-none text-base leading-[1.5] focus:outline-none"
          )}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              if (e.shiftKey) {
                e.preventDefault();
                titleRef.current?.focus();
              }
            }
          }}
        />
      </div>
    </>
  );
};

interface StoryIdeaProps {
  index: number;
  title: string;
  summary?: string;
  data: z.infer<typeof personalisationPageSchema>;
  generationIndex: number;
  personalisationTags?: string;
  availablePersonalisationTags?: Array<{
    value: string;
    label: string;
    category?: string;
  }>;
  onAnimationComplete?: (index: number) => void;
  shouldAnimate?: boolean;
  isAllStoriesAvailable?: boolean;
}

// Utility function to format personalization tags
const formatPersonalizationTags = (tagsString?: string) => {
  if (!tagsString) return [];

  return tagsString
    .split("|")
    .filter((tag) => isValidTag(tag))
    .map((tag) => {
      const category = tag.match(/\[(.*)\]/)?.[1] as Categories;
      const tagLabel = tag.replace(/\[.*?\]\s*/, "").trim();
      return {
        value: tag.toLowerCase(),
        label: tagLabel,
        category,
      };
    });
};

function StoryIdea({
  index,
  title,
  summary,
  generationIndex,
  personalisationTags,
  availablePersonalisationTags,
  onAnimationComplete,
  shouldAnimate = false,
  isAllStoriesAvailable,
}: Readonly<StoryIdeaProps>) {
  const [isLocalStreamingInProgress, setIsLocalStreamingInProgress] =
    useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);
  const [editedSummary, setEditedSummary] = useState(summary || "");
  const [isTagsExpanded, setIsTagsExpanded] = useState(false);
  const [hasEditedTitle, setHasEditedTitle] = useState(false);
  const [hasEditedSummary, setHasEditedSummary] = useState(false);
  const [selectedTags, setSelectedTags] = useState<
    Array<{
      value: string;
      label: string;
      category?: string;
    }>
  >([]);

  const summaryRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const newTagInputRef = useRef<HTMLInputElement>(null);
  const isMounted = useRef(true);

  const { startSingleStoryIdeaStream, isStreamingInProgress } =
    useSingleStoryIdeaStreaming();
  const {
    addStoryPersonalisationTag,
    removeStoryPersonalisationTag,
    saveFormData,
    storyIdeas,
    setStoryIdeas,
  } = useGlobalStore();

  const [animatedTitle] = useAnimatedText(title, {
    duration: 0.5,
    ease: "easeOut",
    skipAnimation: !shouldAnimate,
  });

  const [animatedSummary] = useAnimatedText(summary ?? "", {
    duration: 1.25,
    delay: 0.5,
    ease: "easeOut",
    skipAnimation: !shouldAnimate,
  });

  const isTitleAvailable = title.length > 2;
  const isTitleStarted = shouldAnimate
    ? animatedTitle.length > 2
    : isTitleAvailable;
  const isSummaryStarted = shouldAnimate
    ? animatedSummary.length > 2
    : (summary?.length ?? 0) > 2;
  const isSummaryComplete =
    isSummaryStarted &&
    (shouldAnimate
      ? animatedSummary.length / (summary?.length ?? 1) > 0.995
      : true); // Consider complete immediately if not animating

  // Use raw text when animation is disabled
  const displayTitle = shouldAnimate
    ? hasEditedTitle
      ? editedTitle
      : animatedTitle
    : hasEditedTitle
      ? editedTitle
      : title;

  const displaySummary = shouldAnimate
    ? hasEditedSummary
      ? editedSummary
      : animatedSummary
    : hasEditedSummary
      ? editedSummary
      : (summary ?? "");

  const filteredPersonalityTags = personalisationTags
    ? personalisationTags.split("|").filter((tag) => isValidTag(tag))
    : [];

  // Format tags for TagInput
  const formattedTags = filteredPersonalityTags.map((tag) => {
    const category = tag.match(/\[(.*)\]/)?.[1] as Categories;
    const tagLabel = tag.replace(/\[.*?\]\s*/, "").trim();
    return {
      value: tag.toLowerCase(),
      label: tagLabel,
      category,
    };
  });

  // Initialize selectedTags with formatted tags on mount and when tags change
  useEffect(() => {
    setSelectedTags(formattedTags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEditedTitle(title);
  }, [title]);

  useEffect(() => {
    setEditedSummary(summary ?? "");
  }, [summary]);

  // const scrollIntoView = useCallback(
  //   ({ delay = 1000 } = {}) => {
  //     if (scrollTimeout.current) {
  //       clearTimeout(scrollTimeout.current);
  //     }

  //     // Only scroll on mobile
  //     if (window.innerWidth > 768) return;

  //     scrollTimeout.current = setTimeout(() => {
  //       containerRef.current?.scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //       });
  //     }, delay);
  //   },
  //   [containerRef]
  // );

  // useEffect(() => {
  //   if (isTagsExpanded) {
  //     scrollIntoView({ delay: 200 });
  //   }
  //   return () => {
  //     if (scrollTimeout.current) {
  //       clearTimeout(scrollTimeout.current);
  //     }
  //   };
  // }, [isTagsExpanded, scrollIntoView]);

  useEffect(() => {
    if (!isStreamingInProgress && isLocalStreamingInProgress) {
      setIsLocalStreamingInProgress(false);
      setIsTagsExpanded(false);
    }
  }, [isStreamingInProgress, isLocalStreamingInProgress]);

  useEffect(() => {
    if (isTagsExpanded) {
      const timer = setTimeout(() => {
        const input = newTagInputRef.current;
        input?.focus();
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [isTagsExpanded]);

  useEffect(() => {
    if (!hasEditedTitle) {
      setEditedTitle(title);
    }
  }, [title, hasEditedTitle]);

  useEffect(() => {
    if (!hasEditedSummary) {
      setEditedSummary(summary ?? "");
    }
  }, [summary, hasEditedSummary]);

  useEffect(() => {
    if (summaryRef.current) {
      summaryRef.current.style.height = "0";
      summaryRef.current.style.height = `${summaryRef.current.scrollHeight}px`;
    }
  }, [isEditing, editedTitle, editedSummary]); // Replace editingTitle and editingSummary with isEditing

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleRewrite = () => {
    setIsTagsChanged(false);

    // Get the tag input value and save as a new tag before rewriting
    const tagInputEl: HTMLInputElement | null =
      containerRef.current?.querySelector(`input[data-testid="tag-input"]`) ??
      null;
    const tagInputValue = tagInputEl ? tagInputEl.value?.trim() : "";
    let newTag = null;
    if (tagInputValue) {
      newTag = {
        value: tagInputValue.toLocaleLowerCase(),
        label: tagInputValue,
        isCustom: true,
      };
      handleTagsChange([...selectedTags, newTag]);
      if (tagInputEl) {
        tagInputEl.value = "";
      }
    }

    // Add new tag to the list before next
    // render to call the streaming function.
    const nextFilteredPersonalisationTags = newTag
      ? [...filteredPersonalityTags, `[custom] ${newTag.label}`]
      : filteredPersonalityTags;

    setIsLocalStreamingInProgress(true);
    setHasEditedTitle(false);
    setHasEditedSummary(false);
    saveFormData({
      [`story_title_${index}`]: undefined,
      [`story_summary_${index}`]: undefined,
    });
    setIsTagsExpanded(false);
    startSingleStoryIdeaStream({
      story_num: index,
      story_title: editedTitle,
      story_text: editedSummary,
      personalisation_tags: nextFilteredPersonalisationTags,
    });
  };

  // Edit both title and summary at once
  const handleEdit = () => {
    if (!isSummaryComplete) return;
    setIsEditing(true);
    setHasEditedTitle(true);
    setHasEditedSummary(true);
  };

  // Save both title and summary at once
  const handleSaveEdits = () => {
    setIsEditing(false);

    // Save both changes if needed
    const titleChanged = editedTitle !== title;
    const summaryChanged = editedSummary !== summary;

    if (titleChanged || summaryChanged) {
      const updatedStoryIdeas = { ...storyIdeas };

      if (titleChanged) {
        updatedStoryIdeas[`story_title_${index}`] = editedTitle;
      }

      if (summaryChanged) {
        updatedStoryIdeas[`story_summary_${index}`] = editedSummary;
      }

      setStoryIdeas(updatedStoryIdeas);
    }
  };

  const [isTagsChanged, setIsTagsChanged] = useState(false);
  const handleTagsChange = (tags: Tag[]) => {
    if (!isMounted.current) return;

    setIsTagsChanged(true);
    setSelectedTags(tags);

    // Create a normalized version of existing tags for comparison
    const normalizedExistingTags = filteredPersonalityTags.map((tag) => {
      const category = tag.match(/\[(.*)\]/)?.[1] || "styles";
      const label = tag.replace(/\[.*?\]\s*/, "").trim();
      return `[${category}] ${label}`;
    });

    // Create normalized versions of new tags for comparison
    const normalizedNewTags = tags.map((tag) => {
      const category = tag.category || (tag.isCustom ? "custom" : "styles");
      return `[${category}] ${tag.label}`;
    });

    // Find removed tags - compare normalized versions
    filteredPersonalityTags.forEach((tag) => {
      const category = tag.match(/\[(.*)\]/)?.[1] || "styles";
      const label = tag.replace(/\[.*?\]\s*/, "").trim();
      const normalizedTag = `[${category}] ${label}`;

      if (!normalizedNewTags.includes(normalizedTag)) {
        removeStoryPersonalisationTag(index, tag);
      }
    });

    // Find added tags - use original tags to avoid duplication
    tags.forEach((tag) => {
      const category = tag.category || (tag.isCustom ? "custom" : "styles");
      const normalizedNewTag = `[${category}] ${tag.label}`;

      // Only add if the normalized tag isn't already in the list
      if (!normalizedExistingTags.includes(normalizedNewTag)) {
        addStoryPersonalisationTag(index, normalizedNewTag);
      }
    });
  };

  useEffect(() => {
    if (isSummaryComplete && onAnimationComplete) {
      onAnimationComplete(index);
    }
  }, [isSummaryComplete, onAnimationComplete, index]);

  const renderTagIcon = useCallback((tag: Tag) => {
    // This regex matches a range of emoji characters
    if (tag.value.match(/[\u{1F000}-\u{1FAFF}\u{2600}-\u{27BF}]/u)) {
      return null;
    }
    return renderCategoryIcon(tag.category as CategoryType, 1);
  }, []);

  if (!isTitleAvailable && !isLocalStreamingInProgress) {
    return null;
  }

  return (
    <div ref={containerRef} className="relative z-10">
      <StoryContainer
        index={index}
        generationIndex={generationIndex}
        shouldAnimate={
          shouldAnimate ? isLocalStreamingInProgress || isTitleStarted : false
        }
      >
        <div className="relative min-h-[200px]">
          <StoryHeader
            index={index}
            isEditing={isEditing}
            onRewrite={handleRewrite}
            onEdit={handleEdit}
            onSave={handleSaveEdits}
            isSummaryComplete={isSummaryComplete}
            isAllStoriesAvailable={isAllStoriesAvailable}
            isTagsChanged={isTagsChanged}
          />
          <div className="mt-1 flex flex-col gap-4">
            <div className="flex flex-col gap-1">
              {isTitleStarted ? (
                <EditableStoryContent
                  title={displayTitle}
                  editedTitle={editedTitle}
                  summary={displaySummary}
                  editedSummary={editedSummary}
                  isEditing={isEditing}
                  isSummaryComplete={isSummaryComplete}
                  onEdit={handleEdit}
                  onChangeTitle={setEditedTitle}
                  onChangeSummary={setEditedSummary}
                  onSave={handleSaveEdits}
                />
              ) : (
                <motion.div
                  className="mt-2 flex flex-col gap-2"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  <div className="h-10 w-2/3 animate-pulse rounded-md bg-gray-100 px-2 py-1" />
                </motion.div>
              )}

              {/* Show tags regardless of editing mode */}
              {isSummaryComplete && (
                <motion.div
                  className="flex flex-col gap-4 pt-4"
                  initial={shouldAnimate ? { opacity: 0 } : false}
                  animate={shouldAnimate ? { opacity: 1 } : false}
                  transition={{ delay: 0.25 }}
                >
                  <WrapCompactTagInput
                    selectedTags={selectedTags}
                    onTagsChange={handleTagsChange}
                    availableTags={
                      availablePersonalisationTags || formattedTags
                    }
                    placeholder="Add a playful detail here..."
                    renderTagIcon={renderTagIcon}
                  />
                </motion.div>
              )}

              {!isTitleStarted && (
                <motion.div
                  className="mt-2 flex flex-col gap-2"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  <div className="h-5 w-11/12 animate-pulse rounded-md bg-gray-100" />
                  <div className="h-5 w-3/4 animate-pulse rounded-md bg-gray-100" />
                </motion.div>
              )}
            </div>
          </div>
        </div>
      </StoryContainer>
    </div>
  );
}

interface StoryOutlinesProps {
  data: z.infer<typeof personalisationPageSchema>;
  storyIdeas: Record<string, string>;
  generationIndex: number;
  isStreamingInProgress?: boolean;
  handleStartStoryIdeasStream?: () => void;
  children?: React.ReactNode;
}

function StoryOutlines({
  data,
  storyIdeas,
  generationIndex,
  isStreamingInProgress,
  children,
}: Readonly<StoryOutlinesProps>) {
  const ref = useRef<HTMLDivElement>(null);
  const [completedAnimations, setCompletedAnimations] = useState<number[]>([1]);
  const [shouldAnimateStories, setShouldAnimateStories] = useState(false);

  const hasOutlinesGenerated = STORY_IDEAS_CONFIG.some(
    ({ titleKey }) => storyIdeas[titleKey]
  );

  // Simplified logic: If we already have outlines, don't animate them
  // Only animate when streaming new content
  useEffect(() => {
    if (hasOutlinesGenerated && !isStreamingInProgress) {
      // If we have existing outlines and we're not streaming, don't animate
      setShouldAnimateStories(false);
    } else {
      // Otherwise, animate (when streaming or on initial load without outlines)
      setShouldAnimateStories(true);
    }
  }, [hasOutlinesGenerated, isStreamingInProgress]);

  const handleAnimationComplete = useCallback((index: number) => {
    setCompletedAnimations((prev) =>
      prev.includes(index + 1) ? prev : [...prev, index + 1]
    );
  }, []);

  useEffect(() => {
    if (isStreamingInProgress) {
      setCompletedAnimations([1]);
    }
  }, [isStreamingInProgress]);

  // Collect and merge all personalization tags from all stories
  const allPersonalizationTags = useMemo(() => {
    const allTags: Record<
      string,
      {
        value: string;
        label: string;
        category?: string;
      }
    > = {};

    STORY_IDEAS_CONFIG.forEach(({ personalisationTagsKey }) => {
      const tags = storyIdeas[personalisationTagsKey];
      if (tags) {
        formatPersonalizationTags(tags).forEach((tag) => {
          allTags[tag.value] = tag;
        });
      }
    });

    return Object.values(allTags);
  }, [storyIdeas]);

  return (
    <div ref={ref} className="flex flex-col gap-2 pt-10">
      <div className="flex flex-col gap-6">
        {(hasOutlinesGenerated || isStreamingInProgress) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.5 } }}
          >
            <h3 className="align-baseline text-2xl">Story Summaries</h3>

            <p className="max-w-[80%] text-pretty font-light leading-tight text-secondary-foreground">
              Review and edit these summaries before creating the full stories.
            </p>
          </motion.div>
        )}
        {/* <Button
          type="button"
          className="relative uppercase"
          variant="tertiary"
          disabled={isStreamingInProgress}
          onClick={handleStartStoryIdeasStream}
        >
          {isStreamingInProgress ? (
            <div>Writing in Progress</div>
          ) : (
            `${hasOutlinesGenerated ? "Re-" : ""}Write Story Summaries`
          )}
        </Button> */}
        {children}
      </div>

      <div className="mt-6">
        <div className="flex flex-col gap-6">
          <AnimatePresence>
            {STORY_IDEAS_CONFIG.map(
              ({ titleKey, summaryKey, personalisationTagsKey, index }, i) => {
                const title = storyIdeas[titleKey];
                const summary = storyIdeas[summaryKey];
                const personalisationTags = storyIdeas[personalisationTagsKey];

                const shouldRender =
                  i === 0 || completedAnimations.includes(index);
                const hasSummary = summary && summary.length > 2;

                const isAllStoriesAvailable =
                  STORY_IDEAS_CONFIG.every(
                    ({ titleKey }) => storyIdeas[titleKey]
                  ) && !isStreamingInProgress;

                return shouldRender ? (
                  <StoryIdea
                    key={`story_${index}_gen_${generationIndex}`}
                    data={data}
                    index={index}
                    title={title}
                    summary={summary}
                    generationIndex={generationIndex}
                    personalisationTags={personalisationTags}
                    availablePersonalisationTags={allPersonalizationTags}
                    onAnimationComplete={handleAnimationComplete}
                    shouldAnimate={
                      shouldAnimateStories &&
                      (hasSummary || isStreamingInProgress)
                    }
                    isAllStoriesAvailable={isAllStoriesAvailable}
                  />
                ) : null;
              }
            )}
          </AnimatePresence>

          {isStreamingInProgress && (
            <motion.div
              layoutId="loading-dots"
              className="flex justify-center py-4"
            >
              <LoadingDots />
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
}

export { StoryOutlines };
