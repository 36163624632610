export default function FullDiscountBanner() {
  return (
    <div className="rounded-lg border-border bg-green-100 p-4" role="alert">
      <h3 className="font-semibold">
        <span>🎉</span> You have unlocked a full discount!
      </h3>
      <p className="mt-1 text-pretty leading-tight">
        No worries, you will not be charged for this purchase and delivery is on
        us. Please proceed to the checkout page as we need your address to
        deliver the book. Thank you!
      </p>
    </div>
  );
}
