import { useEffect, useState } from "react";
import { Button } from "./ui/button";
import { Dialog, DialogTitle } from "./ui/dialog";
import { useQueryClient } from "@tanstack/react-query";
import { LoaderCircle, ZoomIn } from "lucide-react";
import { z } from "zod";
import { Skeleton } from "@/components/ui/skeleton";
import { queryKeys } from "@/lib/hooks/queryKeys";
import { useStories } from "@/lib/hooks/useStories";
import {
  startStoryImageGeneration,
  useOrderReferenceIdAndParams,
  useStoryImage,
} from "@/lib/hooks/useStoryImage";
import { cartPageSchema } from "@/lib/schemas/cartSchema";
import { useGlobalStore } from "@/lib/store/global";
import { useProgress } from "@/lib/utils/useProgress";
import CollapsibleStory from "@/modules/personalisation/components/collapsible-story";
import FullStoryPreview from "@/modules/personalisation/components/full-story-preview";
import { ImageLightbox } from "@/modules/personalisation/components/image-lightbox";
import { ZoomableImageLightbox } from "@/modules/personalisation/components/zoomable-image-lightbox";
import { FUTURE_FLAGS } from "@/modules/personalisation/future-flags";

const STORY_INDICES = [1, 2, 3, 4, 5];

// Add a hook to detect mobile devices
const useIsMobile = (breakpoint = 768) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < breakpoint);
    };

    // Check on mount
    checkIsMobile();

    // Check on resize
    window.addEventListener("resize", checkIsMobile);
    return () => window.removeEventListener("resize", checkIsMobile);
  }, [breakpoint]);

  return isMobile;
};

export const StorybookPreview: React.FC<{
  data: z.infer<typeof cartPageSchema>;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}> = ({ data }) => {
  const {
    data: storyImage,
    isLoading: isStoryImageLoading,
    isPending: isStoryImagePending,
  } = useStoryImage();
  // const { data: stories } = useStories({ start: true });
  const { formData, fullStories } = useGlobalStore();

  const [isRegenerateStoryImage, setIsRegenerateStoryImage] = useState(false);

  const isLoadingFinishedWithNoData = !isStoryImagePending && !storyImage;

  const {
    progress,
    isInProgress: isProgressAnimationInProgress,
    restart: restartProgressAnimation,
  } = useProgress({
    start: isStoryImageLoading,
    finish: !!storyImage,
    abort: isLoadingFinishedWithNoData,
    duration: 60000,
  });

  const queryClient = useQueryClient();

  const { order_reference_id, params } = useOrderReferenceIdAndParams();

  const handleRetryClick = async () => {
    setIsRegenerateStoryImage(true);
    await queryClient.fetchQuery({
      queryKey: [queryKeys.GENERATE_STORY_IMAGE],
      queryFn: () => startStoryImageGeneration(order_reference_id, params!),
      staleTime: 0,
    });
    queryClient.invalidateQueries({ queryKey: [queryKeys.GET_STORY_IMAGE] });
    setIsRegenerateStoryImage(false);
    restartProgressAnimation();
  };

  const isMobile = useIsMobile();

  const getStoryImageComponent = () => {
    if (isLoadingFinishedWithNoData) {
      return (
        <LoadingFailed
          handleClick={handleRetryClick}
          isButtonLoading={isRegenerateStoryImage}
        />
      );
    }

    if (progress === 99) {
      return (
        <LoadingTimedOut
          email={
            "order_customer_email" in formData
              ? (formData.order_customer_email as string)
              : formData.user_email
          }
        />
      );
    }

    if (!isProgressAnimationInProgress && storyImage) {
      return (
        <div className="relative">
          {isMobile ? (
            <ZoomableImageLightbox>
              <img
                src={storyImage}
                alt="storybook preview"
                className="h-full w-full object-cover"
              />
              <div className="absolute left-1/2 top-0 h-full w-px translate-x-1/2 bg-[rgba(255,255,255,0.3)] mix-blend-overlay shadow-[-4px_0_12px_6px_rgba(0,0,0,0.75),4px_0_12px_6px_rgba(255,255,255,0.75)]" />
            </ZoomableImageLightbox>
          ) : (
            <ImageLightbox>
              <img
                src={storyImage}
                alt="storybook preview"
                className="h-full w-full object-cover"
              />
              <div className="absolute left-1/2 top-0 h-full w-px translate-x-1/2 bg-[rgba(255,255,255,0.3)] mix-blend-overlay shadow-[-4px_0_12px_6px_rgba(0,0,0,0.75),4px_0_12px_6px_rgba(255,255,255,0.75)]" />
            </ImageLightbox>
          )}

          <div className="pointer-events-none absolute bottom-2 right-2 flex h-9 w-9 items-center justify-center rounded-full bg-black/50 text-white will-change-transform sm:h-10 sm:w-10">
            <ZoomIn className="h-5 w-5 sm:h-6 sm:w-6" />
          </div>
        </div>
      );
    }

    if (isProgressAnimationInProgress) {
      return <LoadingStoryImage progress={progress} />;
    }

    return <Skeleton className="h-full" />;
  };

  return (
    <>
      <div className="my-6 flex flex-col gap-3">
        <h2 className="align-baseline text-2xl">First Story Two-Page Spread</h2>

        <div className="flex flex-col gap-2">
          <div className="relative flex aspect-[2/1] w-full flex-col overflow-hidden rounded-lg border bg-card">
            {getStoryImageComponent()}
          </div>

          <p className="mt-1 w-full text-sm font-light text-secondary-foreground">
            Initial preview, the final illustrations will be artistically
            refined by our artists. 🧑‍🎨
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <h2 className="align-baseline text-2xl">Remaining Story Preview</h2>

        <p className="max-w-[40ch] text-pretty font-light leading-snug text-secondary-foreground">
          We'll also send the full book for review after you complete the order.{" "}
          <WhyALimitedPreviewDialog />
        </p>
      </div>

      <div className="mb-6 mt-3 flex flex-col gap-4">
        {fullStories &&
          STORY_INDICES.map((index) => {
            const story = fullStories[index];
            if (story) {
              return FUTURE_FLAGS.STORY_EDITING ? (
                <FullStoryPreview
                  key={story.title}
                  storyIndex={index + 1}
                  title={story.title}
                  text={story.text}
                  story_id={story.story_id}
                />
              ) : (
                <CollapsibleStory
                  key={story.title}
                  storyIndex={index + 1}
                  title={story.title}
                  text={story.text}
                  className="my-0"
                />
              );
            }
          })}
      </div>
    </>
  );
};

const LoadingTimedOut = ({ email }: { email: string }) => (
  <div className="relative grid h-full grid-cols-2 gap-2">
    <Skeleton className="col-span-2 rounded-none" />
    <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-2 p-4">
      <p className="pb-2 text-center">
        Thanks for your patience - due to high demand, it's taking a bit longer.
      </p>
      <p className="text-center">
        We'll email your illustrations to
        {email ? (
          <>
            <br />
            <span className="break-words font-semibold">
              {email.split("@")[0]}
              <wbr />@{email.split("@")[1]}
            </span>
            <br />
          </>
        ) : (
          " email address "
        )}
        as soon as they're ready.
      </p>
    </div>
  </div>
);

const LoadingStoryImage = ({ progress }: { progress: number }) => (
  <div className="relative h-full">
    <Skeleton className="h-full" />
    <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-2 p-4">
      <p className="text-center text-lg">
        {progress < 25
          ? "Analysing your chosen illustration"
          : progress < 50
            ? "Expanding the illustration"
            : progress < 75
              ? "Adding story title and text"
              : progress < 100
                ? "Finishing touches"
                : "Two-page spread is ready!"}
        {progress < 100 && (
          <span className="font-semibold">
            <span className="animate-[pulse_1s_infinite]">.</span>
            <span className="animate-[pulse_1s_infinite_0.25s]">.</span>
            <span className="animate-[pulse_1s_infinite_0.5s]">.</span>
          </span>
        )}
      </p>
      <div className="flex items-center gap-1">
        <div className="flex items-center justify-between gap-2">
          <div className="relative h-2 w-48 rounded-full bg-secondary">
            <div
              className="absolute h-full rounded-full bg-primary transition-all"
              style={{ width: `${progress}%` }}
            />
          </div>
          <div className="flex w-12 justify-end text-lg font-medium">
            <span className="text-right">{progress}</span>
            <span>%</span>
          </div>
        </div>
      </div>
      <p className="text-center text-muted-foreground">
        Usually we're done in less than a minute
      </p>
    </div>
  </div>
);

const LoadingFailed = ({
  handleClick,
  isButtonLoading,
}: {
  handleClick: () => void;
  isButtonLoading: boolean;
}) => (
  <div className="flex h-full flex-col items-center justify-center gap-1 rounded-md bg-border p-2">
    <p className="text-center text-xl">Ugh, apologies.</p>
    <p className="text-center">
      Seems like we ran out of paint.
      <br />
      Retry now or come back in 30 minutes.
    </p>
    <p className="text-center"></p>
    <Button
      variant="outline"
      type="button"
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
      disabled={isButtonLoading}
    >
      {isButtonLoading ? (
        <LoaderCircle className="animate-spin" />
      ) : (
        "Try Again"
      )}
    </Button>
  </div>
);

const WhyALimitedPreviewDialog = () => (
  <Dialog buttonTitle="Why a limited preview?" inlineButton>
    <div className="grid gap-4">
      <div className="space-y-4">
        <DialogTitle>Why a Limited Preview?</DialogTitle>
        <p>
          At Blossom Reads, each book is lovingly crafted from scratch, giving
          your child a truly unique experience. Here's why we show you a full
          preview of just one story, with a glimpse of the rest:
        </p>
        <ul className="list-inside list-disc">
          <li>
            <span className="font-semibold">
              High Quality with Less Effort:
            </span>{" "}
            Unlike complex platforms, we make ordering simple, while we handle
            the finishing touches so you can enjoy a quick, 5-minute ordering
            experience.
          </li>
          <li>
            <span className="font-semibold">Caring Attention to Detail:</span>{" "}
            Creating bespoke illustrations takes time and care. By previewing
            only part, we ensure a polished, beautifully crafted final product.
          </li>
          <li>
            <span className="font-semibold">Room for Feedback:</span> Don't
            worry, you can still request changes! This helps us ensure every
            detail is right for your child.
          </li>
        </ul>
        <p>
          This approach lets us bring you a high-quality, joyful reading
          experience without the hassle or waiting.
        </p>
      </div>
    </div>
  </Dialog>
);
