import React, { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Circle,
  CircleCheckBig,
  LoaderCircle,
  ThumbsDown,
  ThumbsUp,
} from "lucide-react";
import { AnimatePresence, motion } from "motion/react";
import { Control, Controller, UseFormSetValue, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { FormBody } from "@/components/FormBody";
import { FormHeader } from "@/components/FormHeader";
import { SubmitButton } from "@/components/SubmitButton";
import { ContinueWhereYouLeftOff } from "@/components/orderForm/continueWhereYouLeft";
import { Layout } from "@/components/ui/Layout";
import { LoadingDots } from "@/components/ui/LoadingDots";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { useToast } from "@/components/ui/use-toast";
import { STEPS, getStepNumber } from "@/lib/config/steps";
import { queryKeys } from "@/lib/hooks/queryKeys";
import {
  getHeroImagesQueryKey,
  getParams,
  startHeroImagesGeneration,
  useHeroImages,
} from "@/lib/hooks/useHeroImages";
import { getStories } from "@/lib/hooks/useStories";
import {
  startStoryImageGeneration as startStoryImageGenerationHook,
  useStoryImageGeneration,
} from "@/lib/hooks/useStoryImage";
import { useSubmitFormData } from "@/lib/hooks/useSubmitFormData";
import { childDetailsSchema } from "@/lib/schemas/childDetailsSchema";
import { customerDetailsSchema } from "@/lib/schemas/customerDetailsSchema";
import {
  Similarity,
  similarityOptionalSchema,
  similaritySchema,
} from "@/lib/schemas/similaritySchema";
import { useGlobalStore } from "@/lib/store/global";
import { FetchError } from "@/lib/utils/FetchError";
import { useProgress } from "@/lib/utils/useProgress";
import { getStepRoute, useStepNavigation } from "@/lib/utils/useStepNavigation";
import CollapsibleStory from "@/modules/personalisation/components/collapsible-story";
import FullStoryPreview from "@/modules/personalisation/components/full-story-preview";
import { FUTURE_FLAGS } from "@/modules/personalisation/future-flags";

const CURRENT_STEP = STEPS.STEP_6 as keyof typeof STEPS;

const similarityPageSchema = childDetailsSchema
  .merge(customerDetailsSchema)
  .merge(similarityOptionalSchema);

export function SimilarityPage() {
  const { getFormData, images } = useGlobalStore();
  const data = getFormData(childDetailsSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data || images.length === 0) {
      if (process.env.NODE_ENV === "development") {
        console.log("Similarity Selection Page data:", { data, images });
      }
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <SimilarityForm data={data} /> : null;
}

function SimilarityForm({
  data,
}: Readonly<{
  data: z.infer<typeof similarityPageSchema>;
}>) {
  const {
    saveFormData,
    order_reference_id,
    formData,
    images,
    editedStories,
    setFullStories,
  } = useGlobalStore();
  const { handleSubmit, watch, formState, control, setValue } =
    useForm<Similarity>({
      resolver: zodResolver(similaritySchema),
      defaultValues: data,
    });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const similarityNote = watch("illustration_feedback_choice");
  const similarityFeedback = watch("illustration_feedback");

  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending } = useSubmitFormData(CURRENT_STEP);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { toast } = useToast();

  const {
    data: heroImages,
    isLoading: isHeroImagesLoading,
    isPending: isHeroImagesPending,
    error: heroImagesError,
  } = useHeroImages();

  const isLoadingFinishedWithNoData = !isHeroImagesPending && !heroImages;

  const {
    progress,
    isInProgress: isProgressAnimationInProgress,
    restart: restartProgressAnimation,
  } = useProgress({
    start: isHeroImagesLoading,
    finish: !!heroImages,
    abort: isLoadingFinishedWithNoData,
  });

  // Do not refetch stories if hero image selection changes
  const getStoriesParams = useMemo(() => {
    const params = {
      ...formData,
    };
    delete params["hero_similarity_image_number"];
    return params;
  }, [formData]);

  const {
    data: stories,
    isLoading: isStoriesLoading,
    isError: isStoriesError,
    error: storiesError,
  } = useQuery({
    queryKey: ["stories", order_reference_id, getStoriesParams],
    queryFn: () => getStories(order_reference_id, queryClient),
    refetchInterval: (query) => {
      const data = query.state.data as unknown;
      // If we have valid data (story_id is not null), stop polling
      if (data && Array.isArray(data) && data[0]?.story_id !== null) {
        return false; // Stop polling
      }
      return 3000;
    },
    retryDelay: (attemptIndex) => Math.min(2000 * 2 ** attemptIndex, 30000),
    retry: 10, // Retry failed requests 10 times
  });

  useEffect(() => {
    if (stories) {
      setFullStories(stories);
    }
  }, [stories, setFullStories]);

  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [startStoryImageGeneration, setStartStoryImageGeneration] =
    useState(false);
  const [isRegenerateHeroImages, setIsRegenerateHeroImages] = useState(false);

  const {
    isStartStoryImageGenerationLoading,
    isStartStoryImageGenerationSuccess,
    isStartHeroImagesGenerationError,
  } = useStoryImageGeneration({ start: startStoryImageGeneration });

  const [userHasSelected, setUserHasSelected] = useState(false);
  useEffect(() => {
    if (!userHasSelected) {
      setValue(
        "hero_similarity_image_number",
        formData.order_meta?.hero_similarity_image_number ||
          formData.hero_similarity_image_number ||
          1
      );
    }
  }, [
    formData.order_meta,
    formData.hero_similarity_image_number,
    setValue,
    userHasSelected,
  ]);

  useEffect(() => {
    if (submitAttempted && isStartStoryImageGenerationSuccess) {
      goToNextStep();
    }
  }, [submitAttempted, isStartStoryImageGenerationSuccess, goToNextStep]);

  useEffect(() => {
    if (startStoryImageGeneration && isStartHeroImagesGenerationError) {
      setStartStoryImageGeneration(false);
      queryClient.cancelQueries({ queryKey: [queryKeys.GENERATE_STORY_IMAGE] });
    }
  }, [
    startStoryImageGeneration,
    isStartHeroImagesGenerationError,
    queryClient,
  ]);

  const retryImageGeneration = async () => {
    setIsRegenerateHeroImages(true);
    // Ensure stories[0] exists and has a story_id before attempting to use it
    if (stories?.[0]?.story_id) {
      const queryKey = getHeroImagesQueryKey(
        order_reference_id,
        stories[0].title,
        stories[0].text,
        images
      );
      const params = getParams({
        story_title: stories[0].title,
        story_text: stories[0].text,
        hero_gender: data.hero_gender,
        images,
      });
      await queryClient.fetchQuery({
        queryKey: [queryKeys.GENERATE_HERO_IMAGES, queryKey],
        queryFn: () => startHeroImagesGeneration(order_reference_id, params!),
        staleTime: 0,
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.GET_HERO_IMAGES] });
    } else {
      // If no valid story is available, show toast or handle appropriately
      toast({
        title: "Story Not Available",
        description: "The story is not yet available. Please try again later.",
        duration: 5000,
      });
    }
    setIsRegenerateHeroImages(false);
    restartProgressAnimation();
  };

  const navigateBackToImages = () => {
    navigate(getStepRoute(getStepNumber(STEPS.STEP_4)));
  };

  const isEnhanced = Object.keys(heroImages?.enhanced ?? {}).length > 0;
  const imagesMap =
    isEnhanced && heroImages?.enhanced
      ? heroImages.enhanced.image_urls
      : (heroImages?.hero_preview_image_urls_low_res ?? []);

  const onSubmit = async () => {
    if (similarityNote !== "negative:other") {
      setValue("illustration_feedback", "");
    }

    if (!heroImages) {
      toast({
        title: "Illustrations In Progress",
        description: `Please wait for illustrations to be created. You will have to pick the best one to continue.`,
        duration: 7000,
      });

      return;
    }

    setSubmitAttempted(true);
    const isFormSubmitted = await submitData();
    if (isFormSubmitted) {
      // setStartStoryImageGeneration(true);
      if (stories) {
        // Hero image
        const selectedImageIndex = formData.hero_similarity_image_number || 1;
        const heroImageUrl = imagesMap?.[selectedImageIndex - 1] ?? "";
        // Edited story
        const storyId = stories[0].story_id;
        const currentEdits = editedStories[storyId];
        // Query params
        const params = {
          story_title: currentEdits?.title || stories[0].title,
          story_text: currentEdits?.text || stories[0].text,
          image_url: heroImageUrl,
        };
        const queryKey = [
          queryKeys.GENERATE_STORY_IMAGE,
          order_reference_id,
          params,
        ];
        await queryClient.fetchQuery({
          queryKey,
          queryFn: () =>
            startStoryImageGenerationHook(order_reference_id, params!),
        });
      }
      goToNextStep();
    }
  };

  const getHeroImagesComponent = () => {
    if (isLoadingFinishedWithNoData) {
      return (
        <LoadingFailed
          handleRetry={retryImageGeneration}
          handleBackToImages={navigateBackToImages}
          isButtonLoading={isRegenerateHeroImages}
          error={heroImagesError}
          heroName={data.hero_name}
        />
      );
    }

    if (progress === 99) {
      return (
        <LoadingTimedOut
          email={
            "order_customer_email" in formData
              ? (formData.order_customer_email as string)
              : formData.user_email
          }
        />
      );
    }

    if (!isProgressAnimationInProgress && heroImages) {
      return (
        <>
          <p className="max-w-[40ch] text-pretty font-light leading-snug text-secondary-foreground">
            Select the best matching illustration for the first story. You'll
            see the full story preview in the next step.
          </p>
          <IllustrationSelection
            // hero_name={data.hero_name}
            control={control}
            imagesMap={imagesMap}
            setUserHasSelected={setUserHasSelected}
          />

          <div className="mb-2 flex w-full items-baseline gap-1 px-1">
            <span className="display-block text-lg">🎨</span>
            <p className="text-sm font-light leading-tight text-secondary-foreground">
              Our artists will polish and enhance the final illustrations for{" "}
              {data.hero_name}'s book.
            </p>
          </div>

          <IllustrationFeedback
            control={control}
            similarityNote={similarityNote}
            similarityFeedback={similarityFeedback}
            setValue={setValue}
          />
        </>
      );
    }

    if (isProgressAnimationInProgress) {
      return <LoadingIllustrations progress={progress} />;
    }

    return (
      <div className="rounded-lg border p-4">
        <p className="text-center font-medium">Loading hero images...</p>
      </div>
    );
  };

  return (
    <Layout>
      <FormHeader
        title={`First Story & Illustrations`}
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <h2 className="align-baseline text-2xl">
                Your First Story{" "}
                <span className="text-[0.8em] font-light text-secondary-foreground">
                  (1 of 6)
                </span>
              </h2>
              <p className="max-w-[40ch] text-pretty font-light leading-snug text-secondary-foreground">
                Preview the first of six stories. Next, you'll see the full
                opening and a peek at the other five tales!
              </p>

              <StoryContent
                stories={stories}
                isStoriesError={isStoriesError}
                isStoriesLoading={isStoriesLoading}
                storiesError={null}
              />
            </div>

            <div className="mb-4 flex flex-col gap-2">
              <h2 className="align-baseline text-2xl">Illustrations</h2>
              {getHeroImagesComponent()}
            </div>
          </div>

          <SubmitButton
            isLoading={isPending || isStartStoryImageGenerationLoading}
          />
        </FormBody>
      </form>

      {!formState.isSubmitting && !formState.isSubmitted && (
        <ContinueWhereYouLeftOff currentStep={CURRENT_STEP} />
      )}
    </Layout>
  );
}

const StoryContent = ({
  stories,
  isStoriesError,
  isStoriesLoading,
  storiesError,
}: {
  isStoriesLoading: boolean;
  stories:
    | {
        story_id: number | null;
        title: string;
        text: string;
      }[]
    | undefined;
  isStoriesError: boolean;
  storiesError: Error | null;
}) => {
  const [wasStoriesLoading, setWasStoriesLoading] = useState(false);
  useEffect(() => {
    if (isStoriesLoading && !wasStoriesLoading) {
      setWasStoriesLoading(true);
    }
  }, [isStoriesLoading, wasStoriesLoading]);

  if (isStoriesLoading) {
    return (
      <div className="flex justify-center py-6">
        <LoadingDots />
      </div>
    );
  }

  if (isStoriesError && !isStoriesLoading) {
    return (
      <div className="relative my-4 flex flex-col rounded-lg border bg-card p-4 pb-3 sm:p-5 sm:pb-4">
        <p className="text-red-500">
          Error loading story. Please try again later.
          {storiesError instanceof Error && (
            <span className="block text-sm">{storiesError.message}</span>
          )}
        </p>
      </div>
    );
  }

  return (
    <AnimatePresence>
      {stories?.[0]?.story_id && (
        <motion.div
          key={stories[0].story_id}
          initial={wasStoriesLoading ? { opacity: 0, y: 20 } : false}
          animate={
            wasStoriesLoading && {
              opacity: 1,
              y: 0,
              transition: { type: "spring", damping: 40, stiffness: 450 },
            }
          }
          exit={{ opacity: 0, transition: { delay: 0.2 } }}
        >
          {FUTURE_FLAGS.STORY_EDITING ? (
            <FullStoryPreview
              storyIndex={1}
              title={stories[0].title}
              text={stories[0].text}
              story_id={stories[0].story_id}
              className="mt-3"
            />
          ) : (
            <CollapsibleStory title={stories[0].title} text={stories[0].text} />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const LoadingFailed = ({
  handleRetry,
  handleBackToImages,
  isButtonLoading,
  error,
  heroName,
}: {
  handleRetry: () => void;
  handleBackToImages: () => void;
  isButtonLoading: boolean;
  error: FetchError;
  heroName: string;
}) => {
  let text1 = "Seems like we ran out of paint.";
  let text2 = "Retry now or come back in 30 minutes.";
  let handleClick = handleRetry;
  let buttonLabel = "Try Again";

  if (error?.type === "IMAGE_QUALITY") {
    text1 = `We couldn't find two clear photos of ${heroName}.`;
    text2 = "Please upload different pictures with their face clearly visible.";
    handleClick = handleBackToImages;
    buttonLabel = "Change Images";
  }

  if (error?.type === "IMAGE_FILTERS") {
    text1 = "One or more images were flagged as inappropriate.";
    text2 = "This might be a mistake, but please upload new ones.";
    handleClick = handleBackToImages;
    buttonLabel = "Change Images";
  }

  return (
    <>
      <p className="max-w-[80%] text-pretty font-light leading-tight text-secondary-foreground">
        We’re illustrating your first story now. You’ll see the full preview
        soon.
      </p>
      <div className="flex aspect-square flex-col items-center justify-center gap-2 rounded-md bg-border p-5">
        <p className="text-center text-xl">Ugh, apologies.</p>
        <p className="text-center">
          {text1}
          <br />
          {text2}
        </p>
        <p className="text-center"></p>
        <Button
          variant="outline"
          type="button"
          onClick={handleClick}
          disabled={isButtonLoading}
        >
          {isButtonLoading ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            buttonLabel
          )}
        </Button>
      </div>
    </>
  );
};

const LoadingTimedOut = ({ email }: { email: string }) => (
  <div className="relative mt-4 grid aspect-square grid-cols-2 gap-2">
    <Skeleton className="col-span-2" />
    <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-2 p-4">
      <p className="pb-2 text-center">
        Thanks for your patience - due to high demand, it's taking a bit longer.
      </p>
      <p className="text-center">
        We'll email your illustrations to
        {email ? (
          <>
            <br />
            <span className="break-words font-semibold">
              {email.split("@")[0]}
              <wbr />@{email.split("@")[1]}
            </span>
            <br />
          </>
        ) : (
          " email address "
        )}
        as soon as they're ready.
      </p>
    </div>
  </div>
);

const getProgressMessage = (progress: number): string => {
  if (progress < 25) {
    return "Starting up the creative process";
  } else if (progress < 50) {
    return "Sketching out your illustrations";
  } else if (progress < 75) {
    return "Adding colors and details";
  } else if (progress < 100) {
    return "Finishing touches";
  } else {
    return "Illustrations ready!";
  }
};

const LoadingIllustrations = ({ progress }: { progress: number }) => (
  <>
    <p className="max-w-[80%] text-pretty font-light leading-tight text-secondary-foreground">
      We’re illustrating your first story now. You’ll see the full preview soon.
    </p>
    <div className="relative mt-4 grid aspect-square grid-cols-2 gap-2 rounded-lg border bg-card">
      <Skeleton className="col-span-2 h-full" />
      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-2 p-4">
        <p className="text-center text-lg">
          {getProgressMessage(progress)}
          {progress < 100 && (
            <span className="font-semibold">
              <span className="animate-[pulse_1s_infinite]">.</span>
              <span className="animate-[pulse_1s_infinite_0.25s]">.</span>
              <span className="animate-[pulse_1s_infinite_0.5s]">.</span>
            </span>
          )}
        </p>
        <div className="flex items-center gap-1">
          <div className="flex items-center justify-between gap-2">
            <div className="relative h-2 w-48 rounded-full bg-secondary">
              <div
                className="absolute h-full rounded-full bg-primary transition-all"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="flex w-12 justify-end text-lg font-medium">
              <span className="text-right">{progress}</span>
              <span>%</span>
            </div>
          </div>
        </div>
        <p className="text-center text-muted-foreground">
          Usually this takes less than a minute
        </p>
      </div>
    </div>
  </>
);

type RadioButtonOption = {
  value: string;
  icon: React.ComponentType<{ className?: string }>;
};

type RadioButtonGroupProps = {
  name: "illustration_feedback_choice";
  control: Control<Similarity>;
  options: RadioButtonOption[];
};

function RadioButtonGroup({
  name,
  control,
  options,
}: Readonly<RadioButtonGroupProps>) {
  return (
    <div className="flex w-full gap-2">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {options.map((option) => {
              const isChecked = field.value?.startsWith(option.value);
              return (
                <label
                  key={option.value}
                  className={`inline-flex flex-1 cursor-pointer items-center justify-center gap-2 rounded-md border-2 px-4 py-3 ${
                    isChecked
                      ? "border-primary bg-primary/10 text-primary hover:bg-primary/20"
                      : "hover:bg-border"
                  }`}
                >
                  <input
                    type="radio"
                    className="hidden"
                    value={option.value}
                    checked={isChecked}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  <option.icon className="h-5 w-5 flex-shrink-0" />
                </label>
              );
            })}
          </>
        )}
      />
    </div>
  );
}

type IllustrationFeedbackProps = {
  control: Control<Similarity>;
  similarityNote?: string;
  similarityFeedback?: string;
  setValue: UseFormSetValue<Similarity>;
};

const IllustrationFeedback: React.FC<IllustrationFeedbackProps> = ({
  control,
  similarityNote,
  similarityFeedback,
  setValue,
}) => (
  <div className="flex flex-col gap-4 pb-2 pt-6">
    <p className="text-xl">Happy with this illustration?</p>
    <RadioButtonGroup
      name="illustration_feedback_choice"
      control={control}
      options={[
        {
          value: "positive",
          icon: ThumbsUp,
        },
        {
          value: "negative",
          icon: ThumbsDown,
        },
      ]}
    />
    <div
      className={`w-full transition-all ${similarityNote?.startsWith("negative") ? "" : "hidden"}`}
    >
      <p className="mb-3 text-lg">What's the main issue?</p>
      <div className="grid grid-cols-2 gap-2">
        {[
          { value: "negative:likeness", label: "Likeness" },
          {
            value: "negative:details_issue",
            label: "Details Issue",
          },
          {
            value: "negative:style_and_appeal",
            label: "Style & Appeal",
          },
          { value: "negative:other", label: "Other" },
        ].map((option) => (
          <label key={option.value} className="flex items-center gap-2">
            <input
              type="radio"
              className="h-4 w-4 accent-primary"
              value={option.value}
              checked={similarityNote === option.value}
              onChange={(e) =>
                setValue(
                  "illustration_feedback_choice",
                  e.target.value as Similarity["illustration_feedback_choice"]
                )
              }
            />
            <span>{option.label}</span>
          </label>
        ))}
      </div>
      {similarityNote === "negative:other" && (
        <textarea
          value={similarityFeedback ?? ""}
          onChange={(e) => setValue("illustration_feedback", e.target.value)}
          placeholder="Please tell us what should be improved..."
          className="mt-4 min-h-[100px] w-full resize-none rounded-md border p-3 outline-none"
        />
      )}
    </div>
  </div>
);

type IllustrationSelectionProps = {
  control: Control<Similarity>;
  imagesMap: string[] | undefined;
  // hero_name: string;
  setUserHasSelected: React.Dispatch<React.SetStateAction<boolean>>;
};

const IllustrationSelection: React.FC<IllustrationSelectionProps> = ({
  control,
  imagesMap,
  // hero_name,
  setUserHasSelected,
}) => {
  return (
    <div className="mt-4 grid aspect-square grid-cols-2 gap-2">
      <Controller
        name="hero_similarity_image_number"
        control={control}
        render={({ field }) => (
          <>
            {imagesMap!.map((url, i) => (
              <label
                key={`hero-image.${i}`}
                className={`relative aspect-square cursor-pointer overflow-hidden rounded-lg border-2 transition-opacity hover:opacity-100 ${
                  field.value === i + 1
                    ? "border-primary"
                    : "border-border opacity-70"
                }`}
              >
                <input
                  name="hero_similarity_image_number"
                  type="radio"
                  value={i + 1}
                  onChange={() => {
                    field.onChange(i + 1);
                    setUserHasSelected(true);
                  }}
                  className="hidden"
                />
                <img src={url} alt="" />
                <div className="absolute left-2 top-2 flex h-7 w-7 items-center justify-center rounded-full bg-secondary">
                  {field.value === i + 1 ? (
                    <CircleCheckBig className="text-primary" />
                  ) : (
                    <Circle className="text-primary" />
                  )}
                </div>
              </label>
            ))}
          </>
        )}
      />
    </div>
  );
};
