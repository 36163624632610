"use client";

import {
  forwardRef,
  memo,
  useCallback,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import { Plus, WandSparkles, X } from "lucide-react";
import { AnimatePresence, LayoutGroup, motion } from "motion/react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

export interface Tag {
  value: string;
  label: string;
  category?: string;
  isCustom?: boolean;
}

const tagMotion = {
  initial: { scale: 0.8, opacity: 0 },
  animate: ({ delay }: { delay: number } = { delay: 0 }) => ({
    scale: 1,
    opacity: 1,
    transition: { delay },
  }),
  exit: { scale: 0.8, opacity: 0 },
  transition: { type: "spring", stiffness: 1000, damping: 75 },
};

const TagItem = memo(
  forwardRef<
    HTMLDivElement,
    {
      tag: Tag;
      onRemove: (tag: Tag, event?: React.MouseEvent) => void;
      isLoading: boolean;
      renderTagIcon?: (tag: Tag) => React.ReactNode;
    }
  >(({ tag, onRemove, isLoading, renderTagIcon }, ref) => (
    <motion.div
      ref={ref}
      key={`tag-${tag.value}`}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={tagMotion}
      layout="position"
      className="inline-flex h-8 shrink-0 select-none items-center gap-1 rounded-md border border-border bg-white pl-3 pr-1 text-sm text-foreground transition-colors hover:bg-white"
    >
      {renderTagIcon && renderTagIcon(tag)}
      <span className="max-w-[160px] select-none truncate" title={tag.label}>
        {tag.label}
      </span>
      <button
        onClick={(e) => onRemove(tag, e)}
        className="-ml-2 -mr-1 flex h-full w-8 flex-shrink-0 items-center justify-center px-1.5 transition-colors hover:text-primary"
        disabled={isLoading}
      >
        <X className="h-3.5 w-3.5" />
      </button>
    </motion.div>
  ))
);
TagItem.displayName = "TagItem";

const SuggestionItem = memo(
  forwardRef<
    HTMLDivElement,
    {
      tag: Tag;
      index: number;
      onToggle: (tag: Tag, event?: React.MouseEvent) => void;
      isLoading: boolean;
    }
  >(({ tag, index, onToggle, isLoading }, ref) => (
    <motion.div
      ref={ref}
      key={`suggestion-${tag.value}`}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={tagMotion}
      custom={{ delay: index * 0.1 }}
      layout="position"
    >
      <Button
        key={tag.value}
        variant="outline"
        size="sm"
        onClick={(e) => onToggle(tag, e)}
        disabled={isLoading}
        className="h-8 select-none transition-colors"
      >
        {tag.label}
      </Button>
    </motion.div>
  ))
);
SuggestionItem.displayName = "SuggestionItem";

interface WrapCompactTagInputProps {
  /**
   * Selected tags (controlled)
   */
  selectedTags: Tag[]; // Changed to required prop
  /**
   * Callback when selected tags change
   */
  onTagsChange: (tags: Tag[]) => void; // Changed to required prop
  /**
   * Available tags for suggestions
   */
  availableTags: Tag[]; // Changed to required prop
  /**
   * Callback to get new suggestions
   */
  onSuggestionsRequest?: () => Promise<Tag[]>;
  /**
   * Custom input placeholder
   */
  placeholder?: string;
  /**
   * Label for suggestions section
   */
  suggestionsLabel?: React.ReactNode;
  /**
   * Hide suggestions section
   * @default false
   */
  hideSuggestions?: boolean;
  /**
   * Maximum length for tag input
   * @default 50
   */
  maxInputLength?: number;
  /**
   * Loading state for suggestions
   */
  isLoading?: boolean;
  /**
   * Custom function to render tag icons
   */
  renderTagIcon?: (tag: Tag) => React.ReactNode;
}

export function WrapCompactTagInput({
  selectedTags,
  onTagsChange,
  availableTags,
  onSuggestionsRequest,
  placeholder = "Add a detail here...",
  suggestionsLabel,
  hideSuggestions = false,
  maxInputLength = 50,
  isLoading = false,
  renderTagIcon,
}: Readonly<WrapCompactTagInputProps>) {
  const uid = useId();
  const [inputValue, setInputValue] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // Helper function to normalize tag values
  const normalizeTagValue = useCallback((value: string): string => {
    return value
      .replace(/^\s*\[[^\]]+\]\s*/, "")
      .trim()
      .toLowerCase();
  }, []);

  // Helper to check if a tag exists in a collection
  const tagExistsIn = useCallback(
    (value: string, tagCollection: Tag[]): boolean => {
      const normalizedValue = normalizeTagValue(value);
      return tagCollection.some(
        (tag) => normalizeTagValue(tag.value) === normalizedValue
      );
    },
    [normalizeTagValue]
  );

  const filteredAvailableTags = useMemo(() => {
    // Create a Map to deduplicate tags based on normalized values
    const uniqueTagsMap = new Map<string, Tag>();

    availableTags.forEach((tag) => {
      const normalizedValue = normalizeTagValue(tag.value);
      // Only add the tag if it hasn't been seen before
      if (!uniqueTagsMap.has(normalizedValue)) {
        uniqueTagsMap.set(normalizedValue, tag);
      }
    });

    // Convert map values to array and apply remaining filters
    return Array.from(uniqueTagsMap.values()).filter(
      (tag) =>
        !tagExistsIn(tag.value, selectedTags) &&
        "category" in tag &&
        tag.category !== "custom"
    );
  }, [availableTags, selectedTags, tagExistsIn, normalizeTagValue]);

  const addNewTag = useCallback(
    (value: string) => {
      const trimmedValue = value.trim();
      if (!trimmedValue || trimmedValue.length > maxInputLength) return false;

      const normalizedNewValue = normalizeTagValue(trimmedValue);
      // const allTags = [...availableTags, ...selectedTags];
      if (!tagExistsIn(normalizedNewValue, selectedTags)) {
        const customTag: Tag = {
          value: trimmedValue.toLowerCase(),
          label: trimmedValue,
          isCustom: true,
        };

        onTagsChange([...selectedTags, customTag]);
        setInputValue("");
        return true;
      }

      return false;
    },
    [
      // availableTags,
      maxInputLength,
      normalizeTagValue,
      selectedTags,
      tagExistsIn,
      onTagsChange,
    ]
  );

  const removeTag = useCallback(
    (tagToRemove: Tag, event?: React.MouseEvent) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      const newTags = selectedTags.filter(
        (tag) => tag.value !== tagToRemove.value
      );

      onTagsChange(newTags);
    },
    [selectedTags, onTagsChange]
  );

  const toggleTag = useCallback(
    (tag: Tag, event?: React.MouseEvent) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      const tagExists = tagExistsIn(tag.value, selectedTags);
      const newTags = tagExists
        ? selectedTags.filter(
            (t) => normalizeTagValue(t.value) !== normalizeTagValue(tag.value)
          )
        : [...selectedTags, tag];

      onTagsChange(newTags);
    },
    [selectedTags, tagExistsIn, normalizeTagValue, onTagsChange]
  );

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && inputValue && !isLoading) {
        e.preventDefault();
        addNewTag(inputValue);
      }
    },
    [addNewTag, inputValue, isLoading]
  );

  const toggleExpanded = useCallback((event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    setIsExpanded((prev) => !prev);

    // Do not focus because of the huge layout shift (mobile keyboard)
    // setTimeout(() => {
    //   if (!isExpanded) {
    //     inputRef.current?.focus();
    //   }
    // }, 0);
  }, []);

  const handleMoreSuggestions = useCallback(
    async (event: React.MouseEvent) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      try {
        if (onSuggestionsRequest) {
          await onSuggestionsRequest();
        }
      } catch (error) {
        console.error("Failed to fetch suggestions:", error);
      }
    },
    [onSuggestionsRequest]
  );

  return (
    <div className="space-y-4">
      <div>
        {/* Tags container */}

        <motion.div
          animate="animate"
          initial="initial"
          className="relative flex flex-wrap items-center gap-2 pb-2"
        >
          <LayoutGroup key={`tags-${uid}`}>
            <AnimatePresence initial={false} mode="popLayout">
              {selectedTags.map((tag) => (
                <TagItem
                  key={tag.value}
                  tag={tag}
                  onRemove={removeTag}
                  isLoading={isLoading}
                  renderTagIcon={renderTagIcon}
                />
              ))}
            </AnimatePresence>

            <motion.div
              layout="position"
              layoutId={uid}
              className="z-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.25 } }}
              exit={{ opacity: 0 }}
            >
              <Button
                key={isExpanded ? "hide" : "add"}
                variant="ghost"
                size="sm"
                onClick={toggleExpanded}
                className={cn(
                  "flex h-auto items-center gap-1 border border-primary/40 bg-[#fef8f7] px-2 py-1 pr-1.5 text-primary transition-colors hover:bg-white",
                  isExpanded &&
                    "border-secondary-foreground/40 bg-[#f8f8f8] text-secondary-foreground hover:text-secondary-foreground"
                )}
              >
                <span>{isExpanded ? "Hide" : "Add"}</span>
                {isExpanded ? (
                  <X className="h-4 w-4" />
                ) : (
                  <Plus className="h-4 w-4" />
                )}
              </Button>
            </motion.div>
          </LayoutGroup>
        </motion.div>

        {/* Expandable input and suggestions */}
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "auto", opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ type: "spring", stiffness: 1000, damping: 75 }}
              className="overflow-hidden"
            >
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    type: "spring",
                    stiffness: 1000,
                    damping: 75,
                    delay: 0.1,
                  },
                }}
                exit={{ opacity: 0, y: 0 }}
              >
                <div className="flex w-full gap-2">
                  <div className="relative my-1 h-8 w-full min-w-[8rem]">
                    <Input
                      ref={inputRef}
                      placeholder={placeholder}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={handleInputKeyDown}
                      maxLength={maxInputLength}
                      data-testid="tag-input"
                      className="mx-0 h-10 w-full rounded-none border-x-0 border-b-2 border-t-0 px-1 text-base focus:border-primary/40 focus:ring-0"
                    />
                    <AnimatePresence>
                      {inputValue.length > 0 && (
                        <motion.button
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.8 }}
                          onClick={(e) => {
                            e.preventDefault();
                            addNewTag(inputValue);
                          }}
                          className="absolute right-0 top-0 flex h-8 w-8 items-center justify-center rounded-full bg-primary/10 px-2 hover:bg-primary/15 hover:text-primary"
                        >
                          <Plus className="h-5 w-5 text-primary" />
                        </motion.button>
                      )}
                    </AnimatePresence>
                  </div>
                </div>

                {!hideSuggestions && (
                  <div className="mt-4 flex flex-col gap-3">
                    {suggestionsLabel && (
                      <p className="font-light leading-tight text-secondary-foreground">
                        {suggestionsLabel}
                      </p>
                    )}

                    <div
                      className={cn(
                        "flex flex-wrap items-center gap-2",
                        isLoading && "pointer-events-none opacity-50"
                      )}
                    >
                      <LayoutGroup key={`suggestions-${uid}`}>
                        <AnimatePresence initial={false} mode="popLayout">
                          {filteredAvailableTags.map((tag, i) => (
                            <SuggestionItem
                              key={tag.value}
                              tag={tag}
                              index={i}
                              onToggle={toggleTag}
                              isLoading={isLoading}
                            />
                          ))}
                        </AnimatePresence>

                        {onSuggestionsRequest && (
                          <motion.div
                            layout="position"
                            layoutId={uid}
                            className="z-10"
                          >
                            <Button
                              variant="default"
                              size="sm"
                              onClick={handleMoreSuggestions}
                              disabled={isLoading}
                              className={cn(
                                "flex h-8 select-none items-center gap-2 border border-primary/40 bg-[#fef8f7] text-primary transition-colors hover:bg-white",
                                isLoading && "pointer-events-none opacity-50"
                              )}
                            >
                              <WandSparkles className="-ml-[0.1em] h-4 w-4" />
                              <span>New ideas</span>
                            </Button>
                          </motion.div>
                        )}
                      </LayoutGroup>
                    </div>
                  </div>
                )}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
