import { Ref, forwardRef, useCallback, useState } from "react";
import { useToast } from "./ui/use-toast";
import { Upload } from "lucide-react";
import { cn } from "@/lib/utils";
import { isMobile } from "@/lib/utils/device";
import { isImageFile } from "@/lib/utils/image-file";
import { MAXIMUM_PHOTOS } from "@/pages/photos";

type ImageUploadProps = {
  onUpload: (files: FileList) => void;
  isError: boolean;
  imagesUploaded: number;
};

export const ImageUpload = forwardRef(
  (
    { onUpload, isError, imagesUploaded }: Readonly<ImageUploadProps>,
    ref: Ref<HTMLInputElement>
  ) => {
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const { toast } = useToast();

    const onDrop = useCallback(
      (e: React.DragEvent<HTMLElement>) => {
        e.preventDefault();
        setIsDraggingOver(false);

        if (e.dataTransfer?.files?.length) {
          // Check if any of the dropped files are not images
          const allFiles = Array.from(e.dataTransfer.files);
          const invalidFiles = allFiles.filter((file) => !isImageFile(file));

          if (invalidFiles.length > 0) {
            toast({
              title: "Invalid files detected",
              description: `${invalidFiles.length} file(s) were not valid images and will be ignored.`,
              variant: "destructive",
            });
            console.error(
              "Invalid files detected:",
              invalidFiles.map((file) => file.name)
            );
          }

          // Only process valid files
          const validFiles = allFiles.filter(isImageFile);
          if (validFiles.length > 0) {
            // Create a new FileList-like object with only valid files
            const dataTransfer = new DataTransfer();
            validFiles.forEach((file) => dataTransfer.items.add(file));
            onUpload(dataTransfer.files);
          }
        }
      },
      [onUpload, toast]
    );

    const onDragOver = useCallback((e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      setIsDraggingOver(true);
    }, []);

    const onDragLeave = useCallback(() => {
      setIsDraggingOver(false);
    }, []);

    const onFileInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.target.files?.length) {
          // Filter valid image files
          const allFiles = Array.from(e.target.files);
          const invalidFiles = allFiles.filter((file) => !isImageFile(file));

          if (invalidFiles.length > 0) {
            toast({
              title: "Invalid files detected",
              description: `${invalidFiles.length} file(s) were not valid images and will be ignored.`,
              variant: "destructive",
            });
            console.error(
              "Invalid files detected:",
              invalidFiles.map((file) => file.name)
            );
          }

          // Only process valid files
          const validFiles = allFiles.filter(isImageFile);
          if (validFiles.length > 0) {
            const dataTransfer = new DataTransfer();
            validFiles.forEach((file) => dataTransfer.items.add(file));
            onUpload(dataTransfer.files);
          }
        }
      },
      [onUpload, toast]
    );

    const remainingPhotos = MAXIMUM_PHOTOS - imagesUploaded;

    const label = () => {
      if (imagesUploaded === 0) {
        return "Click to add 2 to 5 photos";
      } else if (imagesUploaded === 1) {
        return "Click to add 1 to 4 more photos";
      }

      if (remainingPhotos === 1) {
        return "Optional: add 1 more photo";
      }

      return `Optional: add 1 to ${remainingPhotos} more photos`;
    };

    return (
      <div className="flex flex-col gap-3">
        <label
          className={cn(
            "flex h-32 w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-md border-2 border-dashed border-border bg-white px-4 hover:border-solid hover:border-primary/20 sm:h-44",
            isDraggingOver && "border-primary text-primary",
            isError && "border-destructive"
          )}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="flex flex-col items-center justify-center">
            <Upload size={32} />
            <p className="text-md mt-2 text-center">{label()}</p>
            {!isMobile() && (
              <p
                className={cn(
                  "mt-2 text-sm text-muted-foreground",
                  isDraggingOver && "text-primary"
                )}
              >
                Or drag and drop
              </p>
            )}
            <input
              ref={ref}
              type="file"
              accept="image/png,image/jpeg,image/jpg,image/avif,image/webp,image/heic"
              multiple={remainingPhotos !== 1}
              className="hidden"
              onChange={(e) => {
                onFileInputChange(e);
                e.target.value = "";
              }}
            />
          </div>
        </label>

        <div className="mb-2 flex w-full items-baseline gap-1 px-1">
          <span className="display-block text-lg">🔒</span>
          <p className="text-sm font-light leading-tight text-secondary-foreground">
            Photos will be immediately deleted from our servers after
            illustrations are ready.
          </p>
        </div>
      </div>
    );
  }
);

ImageUpload.displayName = "ImageUpload";
