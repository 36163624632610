import { ReactNode } from "react";
import { HandleDeviceId } from "../HandleDeviceId";
import { Link } from "react-router-dom";
import logo from "@/assets/logo.svg";

export function Layout({ children }: { children: ReactNode }) {
  return (
    <div className="mb-safe mx-auto flex h-full min-h-dvh max-w-screen-sm flex-col overflow-x-hidden">
      {children}
      <div className="mb-4 mt-auto flex h-full items-center justify-center pt-4">
        <Link to="https://blossomreads.com/">
          <img src={logo} className="h-8" />
        </Link>
      </div>
      <HandleDeviceId />
    </div>
  );
}
