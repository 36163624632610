import { z } from "zod";

export const similaritySchema = z.object({
  hero_similarity_image_number: z
    .union([
      z.number().int().min(1).max(4),
      z
        .string()
        .regex(/^[1-4]$/)
        .transform((val) => parseInt(val)),
      z.null(),
    ])
    .nullable(),
  illustration_feedback_choice: z
    .enum([
      "positive",
      "negative",
      "negative:other",
      "negative:likeness",
      "negative:details_issue",
      "negative:style_and_appeal",
    ])
    .optional(),
  illustration_feedback: z.string().optional(),
});
export type Similarity = z.infer<typeof similaritySchema>;

export const similarityOptionalSchema = similaritySchema.extend({
  hero_similarity_image_number:
    similaritySchema.shape.hero_similarity_image_number.optional(),
  similarity_note:
    similaritySchema.shape.illustration_feedback_choice.optional(),
  similarity_feedback: similaritySchema.shape.illustration_feedback.optional(),
});
