import { useEffect } from "react";
import { useMixpanel } from "@/lib/utils/index";

export function HandleDeviceId() {
  const handleDeviceId = useMixpanel();
  useEffect(() => {
    handleDeviceId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
