import { STEPS } from "../config/steps";
import { adaptBackendToFrontendOrder } from "../store/adapter";
import { FetchError } from "../utils/FetchError";
import { z } from "zod";
import { orderFormDataOptionalSchema } from "@/lib/store/global";

export const fetchLatestState = async (orderReferenceId: string) => {
  try {
    const url = new URL("/order-form-info", import.meta.env.VITE_API_URL);
    url.searchParams.append("order_reference_id", orderReferenceId);

    // const data = await fetchWithRetry(
    //   url,
    //   {},
    //   {
    //     maxRetries: 2,
    //     initialRetryDelay: 600,
    //     timeout: 4000,
    //   }
    // );

    const response = await fetch(url);
    if (!response.ok) {
      throw new FetchError(
        `Failed to fetch latest state for order ${orderReferenceId}: ${response.statusText}`,
        STEPS.STEP_1,
        false
      );
    }
    const data = await response.json();
    const frontendData = adaptBackendToFrontendOrder(data);
    return frontendData;
  } catch (error) {
    // Wrap error in FetchError for consistency with existing code
    throw new FetchError(
      `Failed to fetch latest state for order ${orderReferenceId}: ${error instanceof Error ? error.message : "Unknown error"} (Status: ${(error as any).status || "unknown"})`,
      STEPS.STEP_1,
      false
    );
  }
};

const orderStatusSchema = z.object({
  status: z.string().optional(),
  image_paths: z.array(
    z.object({ optimized: z.string(), original: z.string() })
  ),
});

const formDataSchema = orderFormDataOptionalSchema.merge(orderStatusSchema);

export type FormData = z.infer<typeof formDataSchema>;
