import { useCallback, useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { UseFormSetValue, useForm } from "react-hook-form";
import bannerImage from "@/assets/banners/7.avif";
import { Banner } from "@/components/Banner";
import { FormBody } from "@/components/FormBody";
import { FormField } from "@/components/FormField";
import { FormHeader } from "@/components/FormHeader";
import { SubmitButton } from "@/components/SubmitButton";
import { ContinueWhereYouLeftOff } from "@/components/orderForm/continueWhereYouLeft";
import { Layout } from "@/components/ui/Layout";
import { Input } from "@/components/ui/input";
import { STEPS } from "@/lib/config/steps";
import { useSubmitFormData } from "@/lib/hooks/useSubmitFormData";
import {
  ChildDetails,
  childDetailsSchema,
} from "@/lib/schemas/childDetailsSchema";
import {
  CustomerDetails,
  CustomerDetailsOptional,
  customerDetailsSchema,
} from "@/lib/schemas/customerDetailsSchema";
import { Occasion } from "@/lib/schemas/occasionSchema";
import { Personalisation } from "@/lib/schemas/personalisationSchema";
import { useGlobalStore } from "@/lib/store/global";
import { useStepNavigation } from "@/lib/utils/useStepNavigation";
import { InputTextGroup } from "@/modules/personalisation/components/text-input-group";
import { getSuggestions } from "@/modules/personalisation/suggestions";

const CURRENT_STEP = STEPS.STEP_5 as keyof typeof STEPS;

// const customerDetailsPageSchema = childDetailsSchema
//   .merge(occasionSchema)
//   .merge(personalisationSchema)
//   .merge(customerDetailsSchema);

export function CustomerDetailsPage() {
  const { getFormData, images } = useGlobalStore();
  const data = getFormData(childDetailsSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data || images.length === 0) {
      if (process.env.NODE_ENV === "development") {
        console.log("CustomerDetailsPage data:", { data, images });
      }
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <CustomerDetailsForm data={data} /> : null;
}

function getDedicationSuggestionsSafeData(
  data: ChildDetails & Occasion & Personalisation & CustomerDetailsOptional
) {
  // Only include fields that the API accepts according to the error message
  // Available fields are: country_iso2, hero_name, hero_dob, hero_gender, hero_location,
  // previous_suggestions, occasion, note, people, animals, interests, themes, goals, places, styles
  return {
    hero_name: data.hero_name,
    hero_dob: data.hero_dob,
    hero_gender: data.hero_gender,
    hero_location: data.hero_location || "",
    occasion: data.occasion || "",
    note:
      "personalisation_note" in data
        ? data.personalisation_note
        : (data.note ?? ""),
    people: data.people || [],
    animals: data.animals || [],
    interests: data.interests || [],
    themes: data.themes || [],
    goals: data.goals || [],
    places: data.places || [],
    // styles: data.styles || [],
  };
}

function CustomerDetailsForm({
  data,
}: Readonly<{
  data: ChildDetails & CustomerDetailsOptional;
}>) {
  const { saveFormData, formData } = useGlobalStore();
  const {
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CustomerDetails>({
    resolver: zodResolver(customerDetailsSchema),
    defaultValues: {
      delivery_country: "United Kingdom",
      ...data,
    },
  });

  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);

  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending } = useSubmitFormData(CURRENT_STEP);
  const onSubmit = () => {
    submitData(formData.user_email).finally(() => goToNextStep());
  };

  return (
    <Layout>
      <FormHeader
        title="Your Contact Details"
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="h-fit">
              <div className="relative mx-auto">
                <Banner src={bannerImage} />
              </div>

              <FormField
                inputId="br_order_user_name"
                label="Your Name"
                description={`The author of ${data.hero_name}'s book.`}
              >
                <Input
                  {...register("user_name")}
                  id="br_order_user_name"
                  placeholder="Ex. Margaret"
                  defaultValue={formData.user_name || formData.first_name}
                  autoComplete="first_name"
                  required
                  isError={!!(errors.user_name || errors.first_name)}
                />
              </FormField>

              <FormField
                inputId="br_order_user_email"
                label="Your Email"
                description="We'll send a link so you can return to your book preview."
              >
                <Input
                  {...register("user_email")}
                  id="br_order_user_email"
                  placeholder="Ex. margaret.thompson@gmail.com"
                  defaultValue={formData.user_email}
                  autoComplete="email"
                  required
                  type="email"
                  isError={!!errors.user_email}
                />
              </FormField>

              <FormField
                label={`Dedication message`}
                description="Share your dedication or best wishes to be printed in the book."
                inputId="br_order_book_dedication_message"
                optional
              >
                <PersonalMessageInput
                  childName={data.hero_name}
                  setFormValue={setValue}
                  error={!!errors.book_dedication_message}
                />
              </FormField>
            </div>
          </div>
          <SubmitButton isLoading={isPending} />
        </FormBody>
      </form>

      {!formState.isSubmitting && !formState.isSubmitted && (
        <ContinueWhereYouLeftOff currentStep={CURRENT_STEP} />
      )}
    </Layout>
  );
}

function PersonalMessageInput({
  childName,
  setFormValue,
  error,
}: Readonly<{
  childName: string;
  setFormValue: UseFormSetValue<CustomerDetails>;
  error?: boolean;
}>) {
  const { order_reference_id, formData } = useGlobalStore();
  const queryClient = useQueryClient();

  const [inputValue, setInputValue] = useState(
    formData?.book_dedication_message ?? ""
  );

  // Handle input changes locally first, then propagate to form
  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(value);
      setFormValue("book_dedication_message", value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const suggestText = useCallback(async () => {
    // Make sure data is properly cast to expected type
    const safeData = getDedicationSuggestionsSafeData(formData);
    try {
      const suggestions = await getSuggestions(
        "book_dedication_message",
        order_reference_id,
        safeData,
        queryClient,
        1
      );

      const suggestion =
        suggestions[0] ||
        `Dear ${childName}, this book was made especially for you with love`;
      setFormValue("book_dedication_message", suggestion);
      return suggestion;
    } catch (error) {
      console.error(
        "Error fetching dedication message suggestion:",
        error,
        JSON.stringify(safeData, null, 2)
      );
      return `Dear ${childName}, this book was made especially for you with love`;
    }
  }, [order_reference_id, setFormValue, childName, queryClient, formData]);

  return (
    <InputTextGroup
      value={inputValue}
      onChange={handleInputChange}
      placeholder={`My dearest ${childName}, ...`}
      rows={3}
      maxLength={300}
      multiline={true}
      autoExpand={true}
      showCounter={true}
      className={`pb-0 text-xl leading-normal ${error ? "border-destructive" : ""}`}
      suggestText={suggestText}
    />
  );
}
