export const MAX_SIZE_MB = 25;

export const isImageFile = (file: File): boolean => {
  const validMimeTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/avif",
    "image/webp",
    "image/heic",
  ];

  // Check MIME type if available
  if (file.type && !validMimeTypes.includes(file.type)) {
    return false;
  }

  // If MIME type is empty, check file extension as fallback
  if (!file.type) {
    const extension = file.name.split(".").pop()?.toLowerCase() || "";
    const validExtensions = ["png", "jpg", "jpeg", "avif", "webp", "heic"];
    if (!validExtensions.includes(extension)) {
      return false;
    }
  }

  // Size check
  const maxSize = MAX_SIZE_MB * 1024 * 1024;
  if (file.size > maxSize) {
    console.log(
      `File too large: ${(file.size / (1024 * 1024)).toFixed(2)}MB exceeds ${MAX_SIZE_MB}MB limit`
    );
    return false;
  }

  return true;
};

export const verifyImage = (file: File): Promise<boolean> => {
  return new Promise((resolve) => {
    // Special handling for HEIC files - skip browser verification
    if (
      file.type === "image/heic" ||
      (!file.type && file.name.toLowerCase().endsWith(".heic"))
    ) {
      resolve(true);
      return;
    }

    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      URL.revokeObjectURL(objectUrl);
      resolve(true);
    };

    img.onerror = () => {
      URL.revokeObjectURL(objectUrl);
      resolve(false);
    };

    img.src = objectUrl;
  });
};

export const compressImage = (
  file: File,
  maxSizeMB = MAX_SIZE_MB
): Promise<File> => {
  return new Promise((resolve, reject) => {
    // If file is already under the size limit, return it as is
    if (file.size <= maxSizeMB * 1024 * 1024) {
      resolve(file);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target?.result as string;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        // Calculate scaling factor to reduce size
        const maxScale = Math.sqrt((maxSizeMB * 1024 * 1024) / file.size);
        const scale = Math.min(maxScale, 1); // Don't upscale

        width *= scale;
        height *= scale;

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx?.drawImage(img, 0, 0, width, height);

        // Convert to blob with reduced quality if needed
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Compression failed"));
              return;
            }

            const newFile = new File([blob], file.name, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });

            resolve(newFile);
          },
          "image/jpeg",
          0.8 // Quality factor (0.8 is a good balance)
        );
      };
    };
    reader.onerror = () => reject(new Error("File reading failed"));
  });
};
