interface StreamReaderOptions {
  onData: (data: unknown) => void;
  onComplete: () => void;
}

export const createStreamReader = ({
  onData,
  onComplete,
}: StreamReaderOptions) => {
  let buffer = '';

  const read = async (
    reader: ReadableStreamDefaultReader<Uint8Array>,
  ): Promise<void> => {
    const decoder = new TextDecoder();

    return reader.read().then(({ done, value }) => {
      if (done) {
        onComplete();
        return;
      }

      buffer += decoder.decode(value);
      const lines = buffer.split('\n');
      buffer = lines.pop() || '';

      for (const line of lines) {
        if (line.trim()) {
          try {
            const value = JSON.parse(line);
            onData(value);
          } catch (error) {
            console.warn('Failed to parse chunk:', line, error);
          }
        }
      }

      return read(reader);
    });
  };

  return { read };
};
