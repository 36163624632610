/**
 * Stores all URL parameters in sessionStorage.
 * @param searchParams URLSearchParams object containing all URL parameters
 * @param keysToExclude Optional array of parameter keys to exclude from storage
 */
export const storeAllParams = (
  searchParams: URLSearchParams,
  keysToExclude: string[] = []
) => {
  try {
    const allParams: Record<string, string> = {};
    let hasParams = false;

    // Iterate through all parameters
    searchParams.forEach((value, key) => {
      if (!keysToExclude.includes(key)) {
        // Skip excluded keys
        allParams[key] = value;
        hasParams = true;
      }
    });

    if (hasParams) {
      const paramsString = JSON.stringify(allParams);
      sessionStorage.setItem("url_params", paramsString);
      sessionStorage.setItem("url_params_date", new Date().toISOString());
    }
  } catch {
    // ignore errors
  }
};

/**
 * Retrieves all URL parameters from sessionStorage.
 * @returns Object containing all URL parameters or null if not found/invalid
 *
 * Example output:
 * {
 *   utm_source: "google",
 *   utm_medium: "cpc",
 *   utm_campaign: "spring_sale",
 *   utm_content: "banner_ad",
 *   utm_term: "buy_books",
 *   utm_id: "campaign123",
 *   referrer: "partner_site",
 *   coupon: "SAVE20"
 * }
 *
 * @see https://ga-dev-tools.google/campaign-url-builder/ for information on UTM parameters.
 */
export const getAllParams = () => {
  const paramsString = sessionStorage.getItem("url_params");
  if (paramsString) {
    try {
      return JSON.parse(paramsString);
    } catch {
      // ignore
    }
  }
  return null;
};

export const getAllURLSearchParams = () => {
  const storedParams = getAllParams();
  const urlParams = new URLSearchParams();
  Object.entries(storedParams || {}).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      urlParams.append(key, String(value));
    }
  });
  return urlParams;
};

/**
 * Clears all URL parameters from sessionStorage.
 */
export const clearAllParams = () => {
  sessionStorage.removeItem("url_params");
  sessionStorage.removeItem("url_params_date");
};

// TODO: Not used - remove if not needed

/**
 * Utility functions to handle campaign parameters in URL.
 * @param searchParams URLSearchParams object containing URL parameters
 */
export const storeCampaignParams = (searchParams: URLSearchParams) => {
  const source = searchParams.get("utm_source");
  if (source) {
    try {
      const medium = searchParams.get("utm_medium");
      const name = searchParams.get("utm_campaign");
      const content = searchParams.get("utm_content");
      const term = searchParams.get("utm_term");
      const id = searchParams.get("utm_id");
      const campaignParams = {
        source,
        medium,
        name,
        content,
        term,
        id,
      };
      const campaignParamsString = JSON.stringify(campaignParams);
      sessionStorage.setItem("campaign_params", campaignParamsString);
      sessionStorage.setItem("campaign_params_date", new Date().toISOString());
    } catch {
      // ignore
    }
  }
};

/**
 * Retrieves campaign parameters from sessionStorage.
 * @returns Campaign parameters object or null if not found/invalid
 *
 * Example output:
 * {
 *   source: "google",
 *   medium: "cpc",
 *   name: "spring_sale",
 *   content: "banner_ad",
 *   term: "buy_books",
 *   id: "campaign123"
 * }
 *
 * @see https://ga-dev-tools.google/campaign-url-builder/ for more information on UTM parameters.
 */
export const getCampaignParams = () => {
  const campaignParamsString = sessionStorage.getItem("campaign_params");
  if (campaignParamsString) {
    try {
      const campaignParams = JSON.parse(campaignParamsString);
      return campaignParams;
    } catch {
      // ignore
    }
  }
  return null;
};
