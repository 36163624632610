import { useEffect, useState } from "react";

interface UseDetectApiLoadOptions {
  /**
   * Threshold in milliseconds to distinguish between cache and API loads
   * Loads faster than this are considered from cache
   */
  threshold?: number;
}

/**
 * Hook to detect if data is loaded from API or cache based on loading time
 * @param isLoading Current loading state
 * @param hasData Boolean indicating if data is available
 * @param options Configuration options
 * @returns Object containing loadedFromApi state
 */
export function useDetectApiLoad(
  isLoading: boolean,
  hasData: boolean,
  options: UseDetectApiLoadOptions = {}
) {
  const { threshold = 200 } = options;

  const [loadingStartTime, setLoadingStartTime] = useState<number | null>(null);
  const [loadedFromApi, setLoadedFromApi] = useState(false);
  const [loadTimeMs, setLoadTimeMs] = useState<number | null>(null);

  // Start timing when loading begins
  useEffect(() => {
    if (isLoading) {
      setLoadingStartTime(Date.now());
    }
  }, [isLoading]);

  // Determine load source when loading completes
  useEffect(() => {
    if (loadingStartTime && !isLoading && hasData) {
      const loadTime = Date.now() - loadingStartTime;
      setLoadTimeMs(loadTime);

      // If load time is greater than threshold, consider it an API load
      const isApiLoad = loadTime > threshold;

      if (isApiLoad) {
        setLoadedFromApi(true);
      }
    }
  }, [isLoading, hasData, loadingStartTime, threshold]);

  return {
    loadedFromApi,
    loadTimeMs,
    isLoading,
  };
}
