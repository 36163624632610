import { z } from 'zod';
import { customerDetailsSchema } from './customerDetailsSchema';
import { dedicationMessageSchema } from './dedicationMessageSchema';
import { personalisationSchema } from './personalisationSchema';
import { themeSchema } from './themeSchema';
import { occasionSchema } from './occasionSchema';
import { childDetailsSchema } from './childDetailsSchema';

export const cartSchema = z.object({
  is_audio_book: z.boolean(),
  book_quantity: z.number(),
  is_paid_shipping: z.boolean(),
});
export type Cart = z.infer<typeof cartSchema>;

export const cartOptionalSchema = z.object({
  is_audio_book: z.boolean().optional(),
  book_quantity: z.number().optional(),
  is_paid_shipping: z.boolean().optional(),
});
export type CartOptional = z.infer<typeof cartOptionalSchema>;

export const cartPageSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(themeSchema)
  .merge(personalisationSchema)
  .merge(dedicationMessageSchema)
  .merge(customerDetailsSchema)
  .merge(cartOptionalSchema);
