import { Button } from "./ui/button";
import * as Popover from "@radix-ui/react-popover";
import { Info } from "lucide-react";
import { z } from "zod";
import { cartPageSchema } from "@/lib/schemas/cartSchema";

export const StorybookDetails: React.FC<{
  data: z.infer<typeof cartPageSchema>;
}> = ({ data }) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex-col gap-1">
        <h3 className="align-baseline text-2xl">Book Details</h3>
        <p className="max-w-[40ch] text-pretty font-light leading-snug text-secondary-foreground">
          Great stories are crafted with love and care. Here are the details of
          your bespoke book.
        </p>
      </div>

      <div className="flex flex-col gap-3">
        <Detail
          label="Occasion"
          explainer="Special occasion for which this book is being crafted."
          content={data.occasion}
        />

        {data.book_theme && data.book_theme?.length > 0 && (
          <Detail
            label="Theme"
            explainer="Bespoke adventures will be brought to life through stories inspired by this theme."
            content={data.book_theme}
          />
        )}

        {data.personalisation_note && (
          <Detail
            label="Personalisation Note"
            explainer="Unique touches that make each story truly engaging."
            content={data.personalisation_note}
          />
        )}

        {data.book_dedication_message && (
          <Detail
            label="Message"
            explainer="This message will be printed in one of the first pages."
            content={data.book_dedication_message}
          />
        )}
      </div>
    </div>
  );
};

const Label = ({ content }: { content: string }) => (
  <p className="tracking-wide text-foreground">{content}</p>
);

const Explainer = ({ content }: { content: string }) => (
  <div className="mt-1">
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button
          variant="ghost"
          size="none"
          className="h-4 w-4 text-muted-foreground"
        >
          <Info />
        </Button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side="top"
          sideOffset={2}
          align="start"
          collisionPadding={20}
          className="relative max-w-64 rounded border bg-white px-3 py-2 drop-shadow-lg"
        >
          <p>{content}</p>
          <Popover.Arrow className="fill-white" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  </div>
);

const Detail = ({
  label,
  explainer,
  content,
}: {
  label: string;
  explainer: string;
  content: string;
}) => (
  <div>
    <div className="flex items-center gap-2 pb-1">
      <Label content={label} />
      <Explainer content={explainer} />
    </div>
    <p className="rounded-lg border bg-white/80 px-3 py-2">
      {Array.isArray(content) ? content.join(", ") : content}
    </p>
  </div>
);
