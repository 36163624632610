import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { WandSparkles } from "lucide-react";
import { AnimatePresence, motion } from "motion/react";
import { useForm } from "react-hook-form";
import bannerImage from "@/assets/banners/2.avif";
import { Banner } from "@/components/Banner";
import { FormBody } from "@/components/FormBody";
import { FormHeader } from "@/components/FormHeader";
import { SubmitButton } from "@/components/SubmitButton";
import { ContinueWhereYouLeftOff } from "@/components/orderForm/continueWhereYouLeft";
import { Layout } from "@/components/ui/Layout";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Textarea } from "@/components/ui/textarea";
import { STEPS } from "@/lib/config/steps";
import { useDetectApiLoad } from "@/lib/hooks/useDetectApiLoad";
import { useSubmitFormData } from "@/lib/hooks/useSubmitFormData";
import {
  ChildDetails,
  childDetailsSchema,
} from "@/lib/schemas/childDetailsSchema";
import {
  Occasion,
  OccasionOptional,
  occasionOptionalSchema,
  occasionSchema,
} from "@/lib/schemas/occasionSchema";
import { useOccasionSuggestions } from "@/lib/services/suggestions/occasion/useOccasionSuggestions";
import { useGlobalStore } from "@/lib/store/global";
import { cn } from "@/lib/utils";
import { useStepNavigation } from "@/lib/utils/useStepNavigation";
import { FormSectionHeading } from "@/modules/personalisation/form-section-heading";

const CURRENT_STEP = STEPS.STEP_2 as keyof typeof STEPS;

const loadingTags = Array.from({ length: 9 }).map(() =>
  Math.floor(Math.random() * (200 - 100) + 100)
);

export function OccasionPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(childDetailsSchema.merge(occasionOptionalSchema));
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <OccasionForm data={data} /> : null;
}

function OccasionForm({
  data,
}: Readonly<{ data: ChildDetails & OccasionOptional }>) {
  const { saveFormData } = useGlobalStore();
  const [suggestionsRefreshCounter, setSuggestionsRefreshCounter] = useState(0);
  const {
    register,
    handleSubmit,
    formState,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Occasion>({
    resolver: zodResolver(occasionSchema),
    defaultValues: data,
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending } = useSubmitFormData(CURRENT_STEP);
  const onSubmit = () => submitData().finally(goToNextStep);

  const occasion = watch("occasion");

  const {
    suggestions,
    loading: loadingSuggestions,
    loadingMore: loadingMoreSuggestions,
    fetchMore: fetchMoreSuggestions,
  } = useOccasionSuggestions(data);

  // Filter out duplicate suggestions
  const uniqueSuggestions = useMemo(
    () => [...new Set(suggestions)],
    [suggestions]
  );

  const { loadedFromApi } = useDetectApiLoad(
    loadingSuggestions,
    uniqueSuggestions.length > 0,
    { threshold: 150 }
  );

  const [shouldAnimate, setShouldAnimate] = useState(false);
  useEffect(() => {
    if (loadedFromApi) {
      setShouldAnimate(true);
    }
  }, [loadedFromApi]);

  return (
    <Layout>
      <FormHeader
        title={
          data.hero_name ? `${data.hero_name}'s Adventure` : `Bespoke Adventure`
        }
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 pb-3 lg:max-h-fit">
            <div className="flex h-fit flex-col gap-6">
              <div className="relative mx-auto">
                <Banner src={bannerImage} />
              </div>
              <FormSectionHeading
                title="What's the occasion?"
                description="Tell us the occasion and we'll make the book extra special."
                childName={data.hero_name}
              />
              <Textarea
                {...register("occasion")}
                id="br_order_occasion"
                placeholder="Write your occasion..."
                required
                rows={1}
                style={{ overflowY: "hidden" }}
                className="h-12"
                showClearButton
                onClear={() => setValue("occasion", "")}
                isError={!!errors.occasion}
              />
              <div className="-mx-2 flex flex-row items-start justify-start gap-2">
                <div className="flex flex-wrap gap-2">
                  {loadingSuggestions ? (
                    loadingTags.map((width, i) => (
                      <Skeleton
                        key={i}
                        className={`h-10 rounded-md`}
                        style={{ width: `${width}px` }}
                      />
                    ))
                  ) : (
                    <>
                      <AnimatePresence>
                        {uniqueSuggestions.map((suggestion, index) => {
                          const isSelected = occasion === suggestion;
                          return (
                            <motion.div
                              key={`${suggestion}.${suggestionsRefreshCounter}.${index}.${shouldAnimate ? "1" : "0"}`}
                              initial={
                                shouldAnimate
                                  ? { opacity: 0, scale: 0.8 }
                                  : false
                              }
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{ delay: index * 0.05 }}
                              style={{ willChange: "transform opacity" }}
                            >
                              <Button
                                type="button"
                                variant="tag"
                                selected={isSelected}
                                onClick={() => {
                                  setValue("occasion", suggestion);
                                }}
                                className={cn(
                                  "m-[1px] pl-3",
                                  isSelected &&
                                    "m-0 cursor-auto border-2 border-primary/40"
                                )}
                              >
                                {suggestion}
                              </Button>
                            </motion.div>
                          );
                        })}
                      </AnimatePresence>

                      {!loadingSuggestions && (
                        <motion.div
                          key={`new-ideas.${suggestionsRefreshCounter}.${shouldAnimate ? "1" : "0"}`}
                          initial={shouldAnimate ? { opacity: 0 } : false}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.5 }}
                        >
                          <Button
                            type="button"
                            variant="tag"
                            className="flex items-center gap-2 border-2 border-primary/40 bg-primary/5 pl-3 text-primary transition-all hover:bg-white/60"
                            onClick={() => {
                              fetchMoreSuggestions({ replace: true }).finally(
                                () => {
                                  setSuggestionsRefreshCounter(
                                    (prev) => prev + 1
                                  );
                                  setShouldAnimate(true);
                                }
                              );
                            }}
                            disabled={loadingMoreSuggestions}
                          >
                            <WandSparkles className="h-4 w-4" />
                            New ideas
                          </Button>
                        </motion.div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <SubmitButton isLoading={isPending} />
        </FormBody>
      </form>

      {!formState.isSubmitting && !formState.isSubmitted && (
        <ContinueWhereYouLeftOff currentStep={CURRENT_STEP} />
      )}
    </Layout>
  );
}
