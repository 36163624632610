import { useEffect } from "react";
import { STEPS, getStepNumber } from "../config/steps";
import { useGlobalStore } from "../store/global";
import { FetchError } from "../utils/FetchError";
import { getStepRoute } from "../utils/useStepNavigation";
import { fetchImageUrls } from "./fetchImageUrls";
import { fetchLatestState } from "./fetchLatestState";
import { queryKeys } from "./queryKeys";
import { getHeroImages, getHeroImagesQueryKey } from "./useHeroImages";
import { getStories, getStoriesSchema } from "./useStories";
import { getStoryImage } from "./useStoryImage";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";

const normalizeDate = (date: string | undefined) => {
  if (!date) return undefined;
  return date.split("T")[0]; // Extract YYYY-MM-DD part
};

export const useRehydrate = (orderReferenceId: string) => {
  const queryClient = useQueryClient();
  const { resetStore, saveFormData, getFormData, setImages, updateStoryIdeas } =
    useGlobalStore();
  const navigate = useNavigate();
  const { toast } = useToast();
  useEffect(() => {
    const rehydrate = async () => {
      try {
        // Ignore the CancelledError due to ReactStrictMode double-rendering in development mode
        queryClient.resetQueries();
        queryClient.clear();

        const formData = await queryClient.fetchQuery({
          queryKey: [queryKeys.GET_FORM_INFO],
          queryFn: () => fetchLatestState(orderReferenceId),
          retry: false,
          staleTime: 0, // Force a fresh fetch
        });
        // Clean up the date format when fetching form data
        if (formData.hero_dob) {
          formData.hero_dob = normalizeDate(formData.hero_dob);
        }

        // When status is present and not draft or created, reset the store and navigate to the first step
        if (
          ("status" in formData &&
            formData.status !== "draft" &&
            formData.status !== "created" &&
            formData.status !== null) ||
          ("order_status" in formData &&
            formData.order_status !== "draft" &&
            formData.order_status !== "created" &&
            formData.order_status !== null)
        ) {
          resetStore();
          navigate(getStepRoute(getStepNumber(STEPS.STEP_0)));
          return;
        }

        resetStore(orderReferenceId);

        // adaptBackendToFrontendOrder is already called in fetchLatestState
        // const cleanedFormData = adaptBackendToFrontendOrder(formData);
        saveFormData(formData);

        // Restore story outlines
        const storyOutlines = formData.order_meta?.story_outlines ?? {};
        if (storyOutlines) {
          updateStoryIdeas(storyOutlines);
        }

        const dataStories = getFormData(getStoriesSchema);
        const stories = await queryClient.fetchQuery({
          queryKey: [queryKeys.GET_STORIES, orderReferenceId],
          queryFn: () => getStories(orderReferenceId),
          retry: false,
        });

        if (stories) {
          queryClient.setQueryData(
            [queryKeys.GENERATE_STORIES, orderReferenceId, dataStories],
            stories
          );
        }
        const imageUrls = await queryClient.fetchQuery({
          queryKey: [queryKeys.GET_IMAGE_URLS, orderReferenceId],
          queryFn: () => fetchImageUrls(orderReferenceId),
          retry: false,
          staleTime: 0, // Force a fresh fetch
        });

        let images = [];
        if (
          imageUrls.presigned_urls.length > 0 &&
          formData.order_meta?.image_paths?.length > 0
        ) {
          images = imageUrls.presigned_urls.map(
            (url: string, i: 0 | 1 | 2 | 3 | 4) => ({
              original_file_name: `image_${i + 1}`,
              presigned_url: url,
              s3_path: formData.order_meta?.image_paths?.[i]?.optimized,
              s3_path_original: formData.order_meta?.image_paths?.[i]?.original,
            })
          );
          setImages(images);
        } else {
          // Do not continue if there are no images uploaded
          navigate(getStepRoute(getStepNumber(STEPS.STEP_0)));
          return;
        }

        const heroImages = await queryClient.fetchQuery({
          queryKey: [queryKeys.GET_HERO_IMAGES, orderReferenceId],
          queryFn: () => getHeroImages(orderReferenceId),
          retry: false,
          staleTime: 0, // Force a fresh fetch
        });

        if (heroImages) {
          queryClient.setQueryData(
            [
              queryKeys.GENERATE_HERO_IMAGES,
              getHeroImagesQueryKey(
                orderReferenceId,
                stories[0]?.title,
                stories[0]?.text,
                images
              ),
            ],
            true
          );
        }

        const isEnhanced = Object.keys(heroImages?.enhanced ?? {}).length > 0;

        if (isEnhanced) {
          toast({
            title: "Enhanced illustrations available",
            description: `We've done some extra work on your illustrations to increase the likeness of ${formData.hero_name}.`,
          });
          const stepRoute = getStepRoute(getStepNumber(STEPS.STEP_6));
          if (process.env.NODE_ENV === "development") {
            console.log("Navigating to next step:", stepRoute);
          }
          navigate(stepRoute);
          return;
        }

        if (formData.hero_similarity_image_number === null) {
          const stepRoute = getStepRoute(getStepNumber(STEPS.STEP_6));
          if (process.env.NODE_ENV === "development") {
            console.log("Navigating to next step:", stepRoute);
          }
          navigate(stepRoute);
          return;
        }

        const storyImage = await queryClient.fetchQuery({
          queryKey: [
            queryKeys.GET_STORY_IMAGE,
            orderReferenceId,
            heroImages.hero_preview_image_urls_low_res[
              formData.hero_similarity_image_number! - 1
            ],
          ],
          queryFn: () => getStoryImage(orderReferenceId),
          retry: false,
          staleTime: 0, // Force a fresh fetch
        });

        // We currently assume that the currently selected hero image was used to generate the latest story image
        // This may not be the case if the user has changed the hero image after generating the story image
        // To properly address this, we have two options:
        // 1. Implement a full generation history logic for story -> hero images -> story image
        // 2. BE should provide an endpoint to fetch a specific story image by hero image
        if (storyImage) {
          queryClient.setQueryData(
            [
              queryKeys.GENERATE_STORY_IMAGE,
              orderReferenceId,
              heroImages.hero_preview_image_urls_low_res[
                formData.hero_similarity_image_number! - 1
              ],
            ],
            true
          );
        }

        navigate(getStepRoute(getStepNumber(STEPS.STEP_7)));
      } catch (error) {
        if (error instanceof FetchError) {
          console.error(error.message);
          navigate(getStepRoute(getStepNumber(error.navigateTo)));
        } else {
          console.error(error);
          navigate(getStepRoute(getStepNumber(STEPS.STEP_0)));
        }
      }
    };

    rehydrate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// const excludeUndefined = (formData: FormData) => ({
//   ...(formData.hero_name && { hero_name: formData.hero_name }),
//   ...(formData.hero_location && {
//     hero_location: formData.hero_location,
//   }),
//   ...(formData.hero_dob && { hero_dob: normalizeDate(formData.hero_dob) }),
//   ...(formData.hero_gender && { hero_gender: formData.hero_gender }),
//   ...(formData.occasion && {
//     occasion: formData.occasion,
//   }),
//   ...(formData.book_theme && {
//     book_theme: Array.isArray(formData.book_theme)
//       ? formData.book_theme[0]
//       : formData.book_theme,
//   }),
//   ...(formData.note && {
//     note: formData.note,
//   }),
//   ...("personalisation_note" in formData && {
//     personalisation_note: formData.personalisation_note,
//   }),
//   ...(formData.book_dedication_message && {
//     book_dedication_message: formData.book_dedication_message,
//   }),
//   ...(formData.hero_similarity_image_number && {
//     hero_similarity_image_number: formData.hero_similarity_image_number,
//   }),
//   ...(formData.user_name && { user_name: formData.user_name }),
//   ...(formData.user_email && { user_email: formData.user_email }),
//   ...(formData.delivery_country && {
//     delivery_country: formData.delivery_country,
//   }),
//   ...(formData.is_audio_book && {
//     is_audio_book: formData.is_audio_book,
//   }),
//   ...(formData.book_quantity && {
//     book_quantity: formData.book_quantity,
//   }),
//   ...(formData.illustration_feedback_choice && {
//     illustration_feedback_choice: formData.illustration_feedback_choice,
//   }),
//   ...(formData.illustration_feedback && {
//     illustration_feedback: formData.illustration_feedback,
//   }),
// });
