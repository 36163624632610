import { childDetailsSchema } from "@/lib/schemas/childDetailsSchema";
import { occasionSchema } from "@/lib/schemas/occasionSchema";
import { personalisationSchema } from "@/lib/schemas/personalisationSchema";
import type { SuggestionType } from "@/lib/services/suggestions/shared/types";

export const personalisationPageSchema = childDetailsSchema
  .merge(occasionSchema)
  .merge(personalisationSchema);

// Story Elements

export type Categories =
  | "animals"
  | "goals"
  | "interests"
  | "occasion"
  | "people"
  | "places"
  | "themes";

export type CategoryType =
  | Categories
  | `${Categories}s`
  | "location"
  | "hero_location"
  | "note"
  | "personalisation_note"
  | "person";

export const CategoryConfig = {
  allCategories: [
    "animals",
    "goals",
    "interests",
    "occasion",
    "people",
    "places",
    "themes",
  ] as const,
  childFocused: ["people", "animals", "interests"] as const,
  storyElements: ["themes", "goals", "places"] as const,
} as const;

export const categoryLabels: Record<Categories, string> = {
  animals: "Pets & Toys",
  goals: "Goals",
  interests: "Interests",
  occasion: "Occasion",
  people: "People",
  places: "Places",
  themes: "Themes",
};

export const categoryPlaceholders: Record<Categories, string> = {
  animals: "Teddy bear, Dog Max",
  goals: "Learn to share",
  interests: "Reading, Swimming",
  occasion: "Birthday, Christmas",
  people: "Brother Jack",
  places: "Magical Beach",
  themes: "Adventure, Fantasy",
};

export const CATEGORIES_WITH_SUGGESTIONS: Record<
  Extract<Categories, keyof SuggestionType>,
  boolean
> = {
  interests: true,
  themes: true,
  goals: true,
  places: true,
};

// Story Outlines

export type IndexRange = 1 | 2 | 3 | 4 | 5 | 6;
export type TitleKeys = `story_title_${IndexRange}`;
export type SummaryKeys = `story_summary_${IndexRange}`;
export type PersonalisationKeys = `story_${IndexRange}_personalisation_tags`;
