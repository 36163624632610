import { SUGGESTION_REQUEST_TIMEOUT } from "@/lib/config/suggestions";
import { ChildDetails } from "@/lib/schemas/childDetailsSchema";
import { withTimeout } from "@/lib/utils/withTimeout";

export type GetOccasionSuggestionParams = ChildDetails & {
  previous_suggestions?: string[];
};

export const fetchOccasionSuggestions = (
  order_reference_id: string,
  params: GetOccasionSuggestionParams
) =>
  withTimeout(
    fetchFromApi(order_reference_id, params),
    SUGGESTION_REQUEST_TIMEOUT
  );

async function fetchFromApi(
  order_reference_id: string,
  params: GetOccasionSuggestionParams
) {
  const url = new URL("/preview/order-assistant", import.meta.env.VITE_API_URL);
  url.searchParams.append("task", "occasion_suggestions");
  url.searchParams.append("order_reference_id", order_reference_id);

  const inputs = {
    country_iso2: "uk",
    ...params,
  };
  if ("occasion" in inputs) {
    delete inputs.occasion;
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      occasion_inputs: inputs,
      previous_suggestions: params.previous_suggestions || [],
    }),
  });

  if (!response.ok) {
    throw new Error("Fetching occasion suggestions failed");
  }

  const data = await response.json();
  return data.occasion_suggestions as string[];
}
