import * as React from "react";
import { X } from "lucide-react";
import { cn } from "@/lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isError?: boolean;
  showClearButton?: boolean;
  minLengthToShowClear?: number;
  onClear?: () => void;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      isError,
      showClearButton = false,
      minLengthToShowClear = 3,
      onClear,
      ...props
    },
    ref
  ) => {
    const textareaRef = React.useRef<HTMLTextAreaElement | null>(null);
    const [showClear, setShowClear] = React.useState(false);

    function adjustHeight() {
      if (textareaRef.current) {
        textareaRef.current.style.height = "";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }

    React.useEffect(adjustHeight, [textareaRef.current?.value]);

    React.useEffect(() => {
      window.addEventListener("resize", adjustHeight);
      return () => window.removeEventListener("resize", adjustHeight);
    }, []);

    // Check if we should show the clear button based on content length
    React.useEffect(() => {
      if (textareaRef.current && showClearButton) {
        setShowClear(textareaRef.current.value.length >= minLengthToShowClear);
      }
    }, [textareaRef.current?.value, minLengthToShowClear, showClearButton]);

    const handleClear = () => {
      if (textareaRef.current) {
        textareaRef.current.value = "";
        // Trigger any change listeners
        const event = new Event("input", { bubbles: true });
        textareaRef.current.dispatchEvent(event);
        // Focus the textarea after clearing
        textareaRef.current.focus();
        setShowClear(false);
        adjustHeight();
        onClear?.();
      }
    };

    return (
      <div className="relative">
        <textarea
          className={cn(
            "font-inherit -mx-2 w-full resize-none rounded-md border border-b-2 border-input/40 bg-white/40 px-2 py-2 pb-2.5 text-xl ring-offset-background transition-colors focus:bg-white focus:ring-2 focus:ring-primary/50 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50",
            showClearButton && "pr-10",
            isError && "ring-2 ring-destructive/60",
            className
          )}
          ref={(node) => {
            textareaRef.current = node;
            if (typeof ref === "function") {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          style={{ width: "calc(100% + 0.5rem)", ...(props.style || {}) }}
          onChange={(e) => {
            props.onChange?.(e);
            setShowClear(e.target.value.length >= minLengthToShowClear);
          }}
          {...props}
        />
        {showClear && !props.disabled && (
          <button
            type="button"
            className="absolute right-3 top-3.5 -m-2 mr-0 flex items-center justify-center rounded-md p-2 text-muted-foreground hover:text-foreground focus:outline-none focus:ring-2 focus:ring-primary/50"
            onClick={handleClear}
            aria-label="Clear text"
          >
            <X className="h-5 w-5" />
          </button>
        )}
      </div>
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
