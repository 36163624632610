import { Categories } from "./types";
import { QueryClient } from "@tanstack/react-query";

// Store the current/last fetched suggestions
const currentSuggestions: Record<Categories, string[]> = {
  animals: [],
  goals: [],
  interests: [],
  occasion: [],
  people: [],
  places: [],
  themes: [],
};

// Buffer to store unused suggestions
const suggestionsBuffer: Record<string, string[]> = {};

// Track all suggestions that have been used/provided to client
const usedSuggestions: Record<string, Set<string>> = {};

// Minimum number of suggestions to keep in buffer before fetching more
const MIN_BUFFER_SIZE = 3;

type TaskType = Categories | "personalisation_note" | "book_dedication_message";

/**
 * Gets a suggestion from the buffer or fetches more if needed
 */
export const getSuggestion = async (
  type: TaskType,
  orderReferenceId: string,
  formData: any,
  queryClient: QueryClient
): Promise<string> => {
  // Initialize buffer and tracking for this type if they don't exist
  if (!suggestionsBuffer[type]) {
    suggestionsBuffer[type] = [];
  }
  if (!usedSuggestions[type]) {
    usedSuggestions[type] = new Set();
  }

  // If buffer is running low, fetch more suggestions
  if (suggestionsBuffer[type].length < MIN_BUFFER_SIZE) {
    await replenishBuffer(type, orderReferenceId, formData, queryClient);
  }

  // Return a suggestion from the buffer (or empty string if buffer is somehow empty)
  const suggestion = suggestionsBuffer[type].shift() || "";

  // Track this suggestion as used
  if (suggestion) {
    usedSuggestions[type].add(suggestion);
  }

  return suggestion;
};

/**
 * Gets multiple suggestions at once from the buffer
 */
export const getSuggestions = async (
  type: TaskType,
  orderReferenceId: string,
  formData: any,
  queryClient: QueryClient,
  count: number = 5
): Promise<string[]> => {
  // Initialize buffer and tracking for this type if they don't exist
  if (!suggestionsBuffer[type]) {
    suggestionsBuffer[type] = [];
  }
  if (!usedSuggestions[type]) {
    usedSuggestions[type] = new Set();
  }

  // If buffer doesn't have enough suggestions or is running low, fetch more
  if (suggestionsBuffer[type].length < Math.max(count, MIN_BUFFER_SIZE)) {
    await replenishBuffer(type, orderReferenceId, formData, queryClient);
  }

  // Get up to 'count' suggestions from the buffer
  const result = suggestionsBuffer[type].splice(0, count);

  // Track these suggestions as used
  result.forEach((suggestion) => {
    usedSuggestions[type].add(suggestion);
  });

  return result;
};

/**
 * Fetches more suggestions and adds them to the buffer
 */
async function replenishBuffer(
  type: TaskType,
  orderReferenceId: string,
  formData: any,
  queryClient: QueryClient
): Promise<void> {
  const newSuggestions = await fetchSuggestions(
    type,
    orderReferenceId,
    formData,
    queryClient
  );

  // Initialize buffer if needed
  if (!suggestionsBuffer[type]) {
    suggestionsBuffer[type] = [];
  }

  // Add new suggestions to the buffer
  suggestionsBuffer[type] = [...suggestionsBuffer[type], ...newSuggestions];
}

/**
 * Fetches suggestions from the API
 */
export const fetchSuggestions = async (
  type: TaskType,
  orderReferenceId: string,
  formData: any,
  queryClient: QueryClient
): Promise<string[]> => {
  return queryClient.fetchQuery({
    queryKey: ["suggestions", type, orderReferenceId],
    queryFn: async () => {
      const taskKey = getTaskKeyByTaskType(type);

      const url = new URL(
        "/preview/order-assistant",
        import.meta.env.VITE_API_URL
      );
      url.searchParams.append("task", taskKey);
      url.searchParams.append("order_reference_id", orderReferenceId);

      const previousSuggestions =
        type in currentSuggestions
          ? currentSuggestions[type as Categories]
          : [];

      const inputs = getInputsByTaskType(type, formData, previousSuggestions);

      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(inputs),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch suggestions");
      }

      const data = await response.json();
      const suggestions = data[taskKey];

      // Update current suggestions in memory for future reference
      if (type in currentSuggestions) {
        currentSuggestions[type as Categories] = suggestions;
      }

      return suggestions;
    },
    staleTime: 0,
  });
};

const getInputsByTaskType = (
  type: TaskType,
  formData: any,
  previousSuggestions: string[] = []
) => {
  const baseInputs = {
    country_iso2: "uk",
    hero_name: formData.hero_name,
    hero_location: formData.hero_location,
    hero_dob: formData.hero_dob,
    hero_gender: formData.hero_gender,
    occasion: formData.occasion,
    note: formData.personalisation_note || formData.note,
  };

  const categoryInputs = {
    people: formData.people,
    animals: formData.animals,
    interests: formData.interests,
    themes: formData.themes,
    goals: formData.goals,
    places: formData.places,
  };

  const inputsKey = getInputsKeyByTaskType(type);

  // Get all previously used suggestions for this type
  const allUsedSuggestions = usedSuggestions[type]
    ? Array.from(usedSuggestions[type])
    : [];

  // Combine with any other previous suggestions (from currentSuggestions)
  const combinedPreviousSuggestions = [
    ...allUsedSuggestions,
    ...previousSuggestions.filter((s) => !allUsedSuggestions.includes(s)),
  ];

  const inputs = {
    [`${inputsKey}`]: {
      ...baseInputs,
      ...categoryInputs,
      previous_suggestions: combinedPreviousSuggestions,
    },
  };

  return inputs;
};

const getInputsKeyByTaskType = (type: TaskType) => {
  switch (type) {
    case "book_dedication_message":
      return "dedication_inputs";
    case "personalisation_note":
      return "note_inputs";
    case "themes":
      return "theme_inputs";
    default:
      return `${type}_inputs`;
  }
};

const getTaskKeyByTaskType = (type: TaskType) => {
  switch (type) {
    case "book_dedication_message":
      return "dedication_suggestions";
    case "themes":
      return "theme_suggestions";
    default:
      return `${type}_suggestions`;
  }
};

/**
 * Clears the suggestions buffer for all categories except those specified
 * Also clears currentSuggestions and preserves suggestions in usedSuggestions
 * @param except - Array of categories/types to exclude from clearing
 */
export const clearSuggestionsBuffer = (except: TaskType[] = []): void => {
  Object.keys(suggestionsBuffer).forEach((key) => {
    if (!except.includes(key as TaskType)) {
      // Move any remaining suggestions to usedSuggestions before clearing
      if (!usedSuggestions[key]) {
        usedSuggestions[key] = new Set();
      }
      suggestionsBuffer[key].forEach((suggestion) => {
        usedSuggestions[key].add(suggestion);
      });

      // Clear buffer
      suggestionsBuffer[key] = [];

      // Clear currentSuggestions if it's a category type
      const categoryKey = key as Categories;
      if (categoryKey in currentSuggestions) {
        currentSuggestions[categoryKey] = [];
      }
    }
  });
};
