export const STEPS = {
  STEP_0: "Step 0: Order Form Landing",
  STEP_1: "Step 1: Hero Data",
  STEP_2: "Step 2: Occasion",
  STEP_3: "Step 3: Personalisation",
  STEP_4: "Step 4: Hero Photos",
  STEP_5: "Step 5: Customer Details",
  STEP_6: "Step 6: Similarity Selection",
  STEP_7: "Step 7: Overview",
  STEP_8: "Step 8: Paid",
};

export type StepEventName = (typeof STEPS)[keyof typeof STEPS];

export const countAllSteps = Object.keys(STEPS).length - 1;

export const getStepNumber = (step: StepEventName) =>
  Object.keys(STEPS).findIndex(
    (key) => STEPS[key as keyof typeof STEPS] === step
  );
