import { useRef, useState } from "react";
import EditStoryDialog from "./edit-story-dialog";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { toast } from "@/components/ui/use-toast";
import { queryKeys } from "@/lib/hooks/queryKeys";
import {
  GetStoriesParams,
  getParams,
  getStoriesSchema,
} from "@/lib/hooks/useStories";
import { useGlobalStore } from "@/lib/store/global";

export default function FullStoryPreview({
  title,
  text,
  storyIndex,
  story_id,
  className,
}: {
  title: string;
  text: string;
  storyIndex: number;
  story_id: number;
  className?: string;
}) {
  const {
    order_reference_id,
    getFormData,
    updateEditedStory,
    revertEditedStory,
    editedStories,
  } = useGlobalStore();
  const queryClient = useQueryClient();

  const abortControllerRef = useRef<AbortController | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  // Retrieve the edited story by story_id
  const currentEdits = editedStories[story_id];
  const currentTitle = currentEdits?.title || title;
  const currentText = currentEdits?.text || text;

  // For reverting, get the original values
  const originalTitle = currentEdits?.originalTitle || title;
  const originalText = currentEdits?.originalText || text;

  // Check if we have edits for this story
  const hasEdits = !!currentEdits;

  const handleSaveStory = async (
    newTitle: string,
    newText: string,
    index?: number
  ) => {
    if (!story_id) {
      console.error("Story ID is undefined. Cannot save story.");
      return;
    }

    // Abort any pending request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Check if we're reverting to original content
    const isRevertingSave =
      newTitle === originalTitle && newText === originalText;

    if (isRevertingSave) {
      // If we're saving a revert, remove from editedStories
      revertEditedStory(story_id);
    } else {
      // Otherwise store edits in global state
      updateEditedStory(story_id, newTitle, newText, title, text);
    }

    // Create new AbortController for this request
    abortControllerRef.current = new AbortController();
    setIsSaving(true);

    try {
      const dataForStories = getFormData(getStoriesSchema);
      const params = getParams(dataForStories!);
      updateStoryInCache(
        order_reference_id,
        queryClient,
        params,
        storyIndex,
        newTitle,
        newText,
        story_id
      );

      const { error } = await callUpdateStoriesApi(
        order_reference_id,
        {
          index: storyIndex,
          title: newTitle,
          text: newText,
          story_id,
        },
        abortControllerRef.current.signal
      );

      if (error) {
        console.error(`Error updating story (${story_id}):`, error);
        toast({
          title: "Story Edits Not Saved",
          description: `Something went wrong while saving. Please try again.`,
          duration: 5000,
        });
        return;
      }

      // toast({
      //   title: isRevertingSave ? "Story Reverted" : "Story Saved",
      //   description: isRevertingSave
      //     ? "Your story has been restored to its original version."
      //     : "Your changes have been saved successfully.",
      //   duration: 3000,
      // });
    } catch (error) {
      // Handle abort errors silently
      if (error instanceof DOMException && error.name === "AbortError") {
        console.log("Request was aborted");
      } else {
        console.error(`Error updating story (${story_id}):`, error);
        toast({
          title: "Story Edits Not Saved",
          description: `Something went wrong. Please try again.`,
          duration: 5000,
        });
      }
    } finally {
      setIsSaving(false);
    }
  };

  const handleRevertToOriginal = () => {
    // if (!currentEdits) {
    //   toast({
    //     title: "Cannot Revert Story",
    //     description: "No edits have been made to this story.",
    //     duration: 3000,
    //   });
    //   return;
    // }

    const { originalTitle, originalText } = currentEdits;

    // Remove this story from editedStories
    revertEditedStory(story_id);

    // Also revert in cache if needed
    const dataForStories = getFormData(getStoriesSchema);
    const params = getParams(dataForStories!);
    updateStoryInCache(
      order_reference_id,
      queryClient,
      params,
      storyIndex,
      originalTitle,
      originalText,
      story_id
    );
  };

  return (
    <EditStoryDialog
      storyIndex={storyIndex}
      title={currentTitle}
      text={currentText}
      initialTitle={originalTitle}
      initialText={originalText}
      isSaving={isSaving}
      onSave={handleSaveStory}
      onRevert={handleRevertToOriginal}
      hasEdits={hasEdits}
      className={className}
    />
  );
}

function updateStoryInCache(
  orderReferenceId: string,
  queryClient: QueryClient,
  params: GetStoriesParams,
  storyIndex: number,
  newTitle: string,
  newText: string,
  story_id: number
) {
  // Get the current data from cache
  const queryKey = [queryKeys.GET_STORIES, orderReferenceId, params];
  const cachedStories = queryClient.getQueryData([
    queryKeys.GET_STORIES,
    orderReferenceId,
    params,
  ]);

  if (cachedStories) {
    const updatedStories = cachedStories.map((story: any, index: number) => {
      // Only update if story_id matches
      if (story.story_id === story_id) {
        return {
          ...story,
          title: newTitle,
          text: newText,
        };
      }
      return story;
    });

    // Update the cache with the modified data
    queryClient.setQueryData(queryKey, updatedStories);
  }
}

async function callUpdateStoriesApi(
  orderReferenceId: string,
  {
    index,
    title,
    text,
    story_id,
  }: {
    index: number;
    title: string;
    text: string;
    story_id: number;
  },
  signal?: AbortSignal
) {
  const url = new URL("/preview/order-assistant", import.meta.env.VITE_API_URL);
  url.searchParams.append("task", "update_story");
  url.searchParams.append("order_reference_id", orderReferenceId);

  const updateStoryInput = {
    update_story_inputs: {
      order: index,
      title: title,
      text: text,
    },
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateStoryInput),
    signal,
  });

  if (!response.ok) {
    console.error("Error updating story:", response.statusText);
    return {
      success: false,
      error: response.statusText,
    };
  }

  const data = await response.json();
  return {
    success: true,
    data,
  };
}
