interface FormSectionHeadingProps {
  title: string;
  description: string;
  childName?: string;
  markOptional?: boolean;
}

export function FormSectionHeading({
  title,
  description,
  childName,
  markOptional = false,
}: Readonly<FormSectionHeadingProps>) {
  const formattedDescription = childName
    ? description.replace("{childName}", childName)
    : description;

  return (
    <div className="flex-col gap-1">
      <h3 className="align-baseline text-2xl">
        {title}
        {markOptional && (
          <>
            {" "}
            <span className="text-[0.8em] font-light text-secondary-foreground">
              (optional)
            </span>
          </>
        )}
      </h3>
      <p className="max-w-[40ch] text-pretty font-light leading-snug text-secondary-foreground">
        {formattedDescription}
      </p>
    </div>
  );
}
