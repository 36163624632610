import { useCallback, useRef, useState } from "react";
import { ImageUpload } from "../ImageUpload";
import { useToast } from "../ui/use-toast";
import * as Popover from "@radix-ui/react-popover";
import { Check, ImageIcon, LoaderCircle, Trash2, X } from "lucide-react";
import blurryPhoto from "@/assets/examples/photos/blurry.avif";
import faceCoveredPhoto from "@/assets/examples/photos/covered.avif";
import goodPhoto from "@/assets/examples/photos/good.avif";
import singlePersonPhoto from "@/assets/examples/photos/single.avif";
import { UploadedImage } from "@/lib/schemas/uploadedImageSchema";
import { useGlobalStore } from "@/lib/store/global";
import { isImageFile, verifyImage } from "@/lib/utils/image-file";
import { uploadImage } from "@/lib/utils/orderForm/uploadImages";
import { preloadImage } from "@/lib/utils/preloadImage";
import { MAXIMUM_PHOTOS } from "@/pages/photos";

export const ImageStep = ({
  images,
  isError,
  heroName,
}: {
  images: UploadedImage[];
  isError: boolean;
  heroName: string;
}) => {
  const { addImage, removeImage, order_reference_id } = useGlobalStore();
  const { toast } = useToast();
  const [heicImages, setHeicImages] = useState({});

  const onUpload = useCallback(
    async (files: FileList) => {
      const remainingSlots = MAXIMUM_PHOTOS - images.length;
      const filesArray = Array.from(files);

      // First filter by file type and size
      const validFiles = filesArray.filter((file) => isImageFile(file));

      if (validFiles.length < filesArray.length) {
        toast({
          title: "Some files were ignored",
          description:
            "Only image files with proper format and size are accepted.",
          variant: "destructive",
        });
        console.error(
          "Some files were ignored:",
          filesArray
            .filter((file) => !isImageFile(file))
            .map((file) => file.name)
        );
      }

      // Limit to remaining slots
      const filesToUpload = validFiles.slice(0, remainingSlots);

      // Process each file with additional validation
      for (const file of filesToUpload) {
        try {
          // Verify the file can be loaded as an image
          const isValid = await verifyImage(file);
          if (!isValid) {
            toast({
              title: "Invalid image",
              description: `${file.name} could not be loaded as a valid image.`,
              variant: "destructive",
            });
            console.error(
              "Invalid image file:",
              file.name,
              "Could not load as a valid image."
            );
            continue; // Skip this file
          }

          const objectUrl = URL.createObjectURL(file);

          if (file.type === "image/heic") {
            setHeicImages((prev) => ({
              ...prev,
              [objectUrl]: true,
            }));
          }

          addImage({
            presigned_url: objectUrl,
            original_file_name: file.name,
            s3_path: objectUrl,
            s3_path_original: objectUrl,
          });

          uploadImage(order_reference_id, file)
            .then(async (images) => {
              await preloadImage(images[0].presigned_url);
              addImage(images[0]);
            })
            .catch(() => {
              removeImage(file.name);
              toast({
                title: "Upload failed",
                description: `Failed to upload ${file.name}.`,
                variant: "destructive",
              });
              console.error("Upload failed:", file.name);
            });
        } catch (error) {
          console.error("Error processing file:", file.name, error);
          toast({
            title: "Error processing file",
            description: `Could not process ${file.name}.`,
            variant: "destructive",
          });
          console.error("Error processing file:", file.name, "Error:", error);
        }
      }

      if (filesArray.length > remainingSlots) {
        toast({
          title: "Too many photos",
          description: `You can upload up to ${MAXIMUM_PHOTOS} photos. We only used first ${remainingSlots} of the ${filesArray.length} you selected.`,
          duration: 7000,
        });
      }
    },
    [addImage, order_reference_id, removeImage, images.length, toast]
  );

  const uploadInputRef = useRef<HTMLInputElement>(null);
  const onPlaceholderClick = useCallback(() => {
    uploadInputRef.current?.click();
  }, []);

  return (
    <div className="relative mb-4 flex flex-col gap-8">
      <div className="flex-col gap-1">
        <h3 className="align-baseline text-2xl">Photos of {heroName}</h3>
        <p className="max-w-[40ch] text-pretty font-light leading-snug text-secondary-foreground">
          Please upload two high-quality photos of {heroName} to help us create
          the best illustrations.
        </p>
      </div>

      <div className="grid grid-cols-4 gap-2">
        <GuidelinePhoto
          imageUrl={goodPhoto}
          altText={`Use only clear, well lit photos of ${heroName}`}
          isValid
        />
        <GuidelinePhoto
          imageUrl={faceCoveredPhoto}
          altText="Avoid photos with hats, glasses, etc."
        />
        <GuidelinePhoto
          imageUrl={blurryPhoto}
          altText={`${heroName}'s face needs to be clearly visible and in focus`}
        />
        <GuidelinePhoto
          imageUrl={singlePersonPhoto}
          altText={`${heroName} should be the only person in the photo`}
        />
      </div>

      <div className="flex flex-col gap-4">
        {images.length < MAXIMUM_PHOTOS && (
          <ImageUpload
            onUpload={onUpload}
            isError={isError}
            imagesUploaded={images.length}
            ref={uploadInputRef}
          />
        )}
        <ImageList
          images={images}
          onRemove={removeImage}
          onPlaceholderClick={onPlaceholderClick}
          heicImages={heicImages}
        />
      </div>
    </div>
  );
};

const ImageList = ({
  images,
  onRemove,
  onPlaceholderClick,
  heicImages,
}: {
  images: UploadedImage[];
  onRemove: (name: string) => void;
  onPlaceholderClick?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  heicImages: { [key: string]: boolean };
}) => (
  <div className="flex flex-col gap-4">
    {images.map((image) => (
      <div key={image.s3_path} className="flex items-center justify-between">
        <div className="relative aspect-square w-full max-w-24 overflow-hidden">
          <Image
            url={image.presigned_url}
            isHeic={heicImages[image.presigned_url]}
          />
        </div>
        <div className="mr-2 flex items-center p-4">
          {image.s3_path === image.presigned_url ? (
            <LoaderCircle className="animate-spin" />
          ) : (
            <button
              className="cursor-pointer disabled:text-muted-foreground"
              onClick={() => onRemove(image.original_file_name)}
            >
              <Trash2 className="h-6 w-6 text-secondary-foreground hover:text-foreground" />
            </button>
          )}
        </div>
      </div>
    ))}
    {images.length < 1 && <ImagePlaceholder onClick={onPlaceholderClick} />}
    {images.length < 2 && <ImagePlaceholder onClick={onPlaceholderClick} />}
  </div>
);

const ImagePlaceholder = ({
  filledBg = false,
  onClick,
}: {
  filledBg?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) => (
  <div className="relative aspect-square w-full max-w-24 overflow-hidden">
    <div
      className={`flex h-full w-full cursor-pointer items-center justify-center rounded-md border-2 border-border hover:bg-card/40 ${filledBg ? "bg-border" : ""}`}
      onClick={onClick}
    >
      <ImageIcon className="h-8 w-8 text-gray-400" />
    </div>
  </div>
);

const GuidelinePhoto = ({
  altText,
  imageUrl,
  isValid = false,
}: {
  altText: string;
  imageUrl: string;
  isValid?: boolean;
}) => (
  <Popover.Root>
    <Popover.Trigger asChild>
      <div
        className={`flex h-fit cursor-pointer flex-col items-center overflow-hidden rounded-md ${isValid ? "border-valid bg-valid" : "border-primary bg-primary"}`}
        style={{ borderWidth: "1px" }}
      >
        <div className="aspect-[1/2]">
          <img
            src={imageUrl}
            alt={altText}
            className="h-full w-full object-cover"
          />
        </div>
        <div className={`flex h-4 w-full items-center justify-center sm:h-6`}>
          {isValid ? (
            <Check className="p-1.5 text-white sm:p-1" />
          ) : (
            <X className="p-1.5 text-white sm:p-1" />
          )}
        </div>
      </div>
    </Popover.Trigger>
    <Popover.Portal>
      <Popover.Content
        side="top"
        sideOffset={2}
        align="center"
        collisionPadding={20}
        className="relative max-w-64 rounded border bg-white px-3 py-2 outline-none drop-shadow-lg"
      >
        <p>{altText}</p>
        <Popover.Arrow className="fill-white" />
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
);

const Image = ({ url, isHeic }: { url: string; isHeic: boolean }) => {
  if (isHeic) {
    return <ImagePlaceholder filledBg />;
  }

  return (
    <img
      src={url}
      alt="Uploaded image"
      className="h-full w-full rounded-md object-cover"
      style={{ objectPosition: "center 20%" }}
    />
  );
};
