import { UploadedImage } from "../schemas/uploadedImageSchema";
import { OrderFormDataOptional } from "./global";

export interface BackendOrderData {
  user_id?: string | null;
  order_customer_name?: string | null;
  order_customer_surname?: string | null;
  order_customer_email?: string | null;
  order_customer_phone?: string | null;
  order_customer_address?: string | null;
  order_customer_address_2?: string | null;
  order_customer_city?: string | null;
  order_customer_country?: string | null;
  order_customer_zipcode?: string | null;
  order_hero_gender?: string | null;
  order_hero_name?: string | null;
  order_hero_birthday?: string | null;
  order_hero_age?: number | null;
  order_hero_description?: string | null;
  order_hero_location?: string | null;
  order_book_language?: string | null;
  order_book_occasion?: string;
  order_book_people?: string[];
  order_book_pets_and_toys?: string[];
  order_book_interests?: string[];
  order_book_themes?: string[];
  order_book_goals?: string[];
  order_book_places?: string[];
  order_personalisation_note?: string;
  order_dedication_message?: string;
  order_is_audio?: boolean;
  order_book_quantity?: number;
  order_is_paid_shipping?: boolean;
  order_book_public_preview_url?: string | null;
  order_audiobook_url?: string | null;
  order_artist?: string | null;
  order_meta?: Record<string, unknown> | null;
  order_status?: string | null;
  // order_reference_id: string;
}

/**
 * Adapts frontend order data format to the backend API format
 */
export function adaptFrontendToBackendOrder(
  frontendData: OrderFormDataOptional,
  images: UploadedImage[]
): BackendOrderData {
  // FIXME: Duplicate with useSubmitFormData.ts
  // Transform image data format
  const imagePaths =
    images
      .map((img) => {
        // Handle both old and new image formats
        if (img.s3_path && img.s3_path_original) {
          return {
            original: img.s3_path_original,
            optimized: img.s3_path,
          };
        } else if ("optimized" in img && "original" in img) {
          return img;
        }
      })
      .filter((img) => img) || [];

  // Parse themes - handle both "themes" (array) and "book_theme" (string) formats
  const themes =
    frontendData.themes ||
    (Array.isArray(frontendData.book_theme)
      ? frontendData.book_theme
      : frontendData.book_theme
        ? [frontendData.book_theme]
        : []);

  // Safe format date (either 'YYYY-MM-DD' or Date object)
  let formattedBirthday = null;
  if (frontendData.hero_dob) {
    const dateObj = new Date(frontendData.hero_dob);
    if (dateObj instanceof Date && !isNaN(dateObj.getTime())) {
      // Format Date object to 'YYYY-MM-DD'
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getDate()).padStart(2, "0");
      formattedBirthday = `${year}-${month}-${day}`;
    } else {
      // If not a Date object, leave as is
      formattedBirthday = frontendData.hero_dob;
    }
  }

  return {
    // Customer details
    order_customer_name: frontendData.user_name || null,
    order_customer_surname: frontendData.last_name || null,
    order_customer_email: frontendData.user_email || null,
    order_customer_phone: frontendData.phone || null,
    order_customer_address: frontendData.address || null,
    order_customer_address_2: frontendData.address_2 || null,
    order_customer_city: frontendData.city || null,
    order_customer_country: frontendData.delivery_country || null,
    order_customer_zipcode: frontendData.zipcode || null,

    // Hero details
    order_hero_name: frontendData.hero_name || null,
    order_hero_gender: frontendData.hero_gender || null,
    order_hero_birthday: formattedBirthday,
    order_hero_description: frontendData.hero_description || null,
    order_hero_location: frontendData.hero_location || null,

    // Book details
    order_book_language: frontendData.book_language || null,
    order_book_occasion: frontendData.occasion || "",
    order_book_themes: themes,
    order_book_people: frontendData.people || frontendData.book_people || [],
    order_book_pets_and_toys:
      frontendData.animals || frontendData.book_animals || [],
    order_book_interests:
      frontendData.interests || frontendData.book_interests || [],
    order_book_goals: frontendData.goals || frontendData.book_goals || [],
    order_book_places: frontendData.places || frontendData.book_places || [],

    // Messages
    order_personalisation_note:
      frontendData.personalisation_note || frontendData.note || "",
    order_dedication_message: frontendData.book_dedication_message || "",

    // Order options
    order_is_audio: frontendData.is_audio_book || false,
    order_book_quantity: frontendData.book_quantity || 1,
    order_is_paid_shipping: frontendData.is_paid_shipping || false,

    // Optional metadata - can be used for any additional fields
    order_meta: {
      ...(frontendData.order_meta || {}),
      hero_similarity_image_number:
        frontendData.hero_similarity_image_number ||
        frontendData.order_meta?.hero_similarity_image_number ||
        null,
      illustration_feedback: frontendData.illustration_feedback || null,
      illustration_feedback_choice:
        frontendData.illustration_feedback_choice || null,
      // Images
      image_paths: imagePaths.length > 0 ? imagePaths : null,
      // Story outlines
      story_outlines:
        frontendData.order_meta?.story_outlines ||
        frontendData.order_meta?.story_ideas ||
        frontendData.story_outlines ||
        frontendData.story_ideas ||
        null,
    },
  };
}

/**
 * Adapts backend order data format to the frontend format
 */
export function adaptBackendToFrontendOrder(
  backendData: BackendOrderData
): OrderFormDataOptional {
  return {
    // Customer details
    first_name: backendData.order_customer_name || undefined,
    user_name: backendData.order_customer_name || undefined,
    last_name: backendData.order_customer_surname || undefined,
    user_email: backendData.order_customer_email || undefined,
    user_phone: backendData.order_customer_phone || undefined,
    address: backendData.order_customer_address || undefined,
    address_2: backendData.order_customer_address_2 || undefined,
    city: backendData.order_customer_city || undefined,
    delivery_country: backendData.order_customer_country || undefined,
    zipcode: backendData.order_customer_zipcode || undefined,

    // Hero details
    hero_name: backendData.order_hero_name || undefined,
    hero_gender: backendData.order_hero_gender || undefined,
    hero_dob: backendData.order_hero_birthday || undefined,
    hero_age: backendData.order_hero_age || undefined,
    hero_description: backendData.order_hero_description || undefined,
    hero_location: backendData.order_hero_location || undefined,
    hero_similarity_image_number:
      backendData.hero_similarity_image_number ||
      backendData.order_meta?.hero_similarity_image_number ||
      undefined,

    // Book details
    book_language: backendData.order_book_language || undefined,
    occasion: backendData.order_book_occasion || undefined,
    themes: backendData.order_book_themes || undefined,
    book_theme: backendData.order_book_themes || undefined,
    book_people: backendData.order_book_people || undefined,
    book_animals: backendData.order_book_pets_and_toys || undefined,
    book_interests: backendData.order_book_interests || undefined,
    book_goals: backendData.order_book_goals || undefined,
    book_places: backendData.order_book_places || undefined,

    // Messages
    personalisation_note: backendData.order_personalisation_note || undefined,
    book_dedication_message: backendData.order_dedication_message || undefined,

    // Order options
    is_audio_book: backendData.order_is_audio || undefined,
    book_quantity: backendData.order_book_quantity || undefined,
    is_paid_shipping: backendData.order_is_paid_shipping || undefined,
    order_status: backendData.order_status || undefined,

    // Metadata
    order_meta: {
      hero_similarity_image_number:
        backendData.order_meta?.hero_similarity_image_number || undefined,
      illustration_feedback:
        backendData.order_meta?.illustration_feedback || undefined,
      illustration_feedback_choice:
        backendData.order_meta?.illustration_feedback_choice || undefined,
      // Story outlines
      story_outlines:
        backendData.order_meta?.story_outlines ||
        backendData.order_meta?.story_ideas ||
        undefined,
      // Image paths
      image_paths: backendData.order_meta?.image_paths || undefined,
    },
  };
}
