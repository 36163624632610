import { motion } from "motion/react";
import { cn } from "@/lib/utils";

const dotVariants = (delay: number = 0) => ({
  initial: { y: 0 },
  animate: {
    y: [-10, 0, -10],
    transition: { repeat: Infinity, duration: 1, delay },
  },
});

export const LoadingDots = ({ className }: { className?: string }) => {
  return (
    <span
      className={cn(
        "inline-flex items-center gap-1 text-muted-foreground/50",
        className
      )}
    >
      <motion.span
        variants={dotVariants()}
        initial="initial"
        animate="animate"
        className="h-1 w-1 rounded-full bg-current"
      />
      <motion.span
        variants={dotVariants(0.12)}
        initial="initial"
        animate="animate"
        className="h-1 w-1 rounded-full bg-current"
      />
      <motion.span
        variants={dotVariants(0.24)}
        initial="initial"
        animate="animate"
        className="h-1 w-1 rounded-full bg-current"
      />
    </span>
  );
};
