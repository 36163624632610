import { z } from "zod";

export const deliveryCountrySchema = z.object({
  delivery_country: z.string().min(1),
});

export const customerDetailsSchema = deliveryCountrySchema.extend({
  user_name: z.string().min(1),
  user_email: z.string().email().min(5),
  book_dedication_message: z.string().max(300).optional(),
});

export type CustomerDetails = z.infer<typeof customerDetailsSchema>;

export const customerDetailsOptionalSchema = customerDetailsSchema.extend({
  user_name: z.string().optional(),
  user_email: z.string().optional(),
  delivery_country: z.string().optional(),
});
export type CustomerDetailsOptional = z.infer<
  typeof customerDetailsOptionalSchema
>;
