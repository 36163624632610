import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Separator from "@radix-ui/react-separator";
import { addBusinessDays, format } from "date-fns";
import {
  Book,
  Check,
  Circle,
  CircleCheck,
  Headphones,
  HeartHandshake,
  LoaderCircle,
  Plus,
} from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Examples } from "@/components/Examples";
import { FormBody } from "@/components/FormBody";
import { FormHeader } from "@/components/FormHeader";
import FullDiscountBanner from "@/components/FullDiscountBanner";
import { CustomerReviews } from "@/components/Reviews";
import { StorybookDetails } from "@/components/StorybookDetails";
import { StorybookPreview } from "@/components/StorybookPreview";
import { StyleExamples } from "@/components/StyleExamples";
import { ContinueWhereYouLeftOff } from "@/components/orderForm/continueWhereYouLeft";
import { Layout } from "@/components/ui/Layout";
import { Button } from "@/components/ui/button";
import { Dialog, DialogTitle } from "@/components/ui/dialog";
import { getAllParams } from "@/lib/campaign-params";
import { currencySymbol } from "@/lib/config/currency";
import {
  AUDIO_BOOK_PRICE,
  EXPRESS_SHIPPING_PRICE,
  FIRST_BOOK_PRICE,
  STANDARD_SHIPPING_PRICE,
} from "@/lib/config/prices";
import { STEPS } from "@/lib/config/steps";
import { useRedirectToPayment } from "@/lib/hooks/useRedirectToPayment";
import { useSubmitFormData } from "@/lib/hooks/useSubmitFormData";
import { Cart, cartPageSchema, cartSchema } from "@/lib/schemas/cartSchema";
import { childDetailsSchema } from "@/lib/schemas/childDetailsSchema";
import { setOpenedCheckout, useGlobalStore } from "@/lib/store/global";
import { cn } from "@/lib/utils";
import { useStepNavigation } from "@/lib/utils/useStepNavigation";

const CURRENT_STEP = STEPS.STEP_7 as keyof typeof STEPS;

export function OverviewPage() {
  const { getFormData, images } = useGlobalStore();
  // FIXME: Inconsistency with formData saving and retrieval
  const data = getFormData(childDetailsSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data || images.length === 0) {
      if (process.env.NODE_ENV === "development") {
        console.log("Similarity Selection Page data:", { data, images });
      }
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <CartForm data={data} /> : null;
}

function CartForm({ data }: { data: z.infer<typeof cartPageSchema> }) {
  const { saveFormData, order_reference_id, formData } = useGlobalStore();
  const { handleSubmit, watch, setValue, formState } = useForm<Cart>({
    resolver: zodResolver(cartSchema),
    defaultValues: {
      book_quantity: formData.book_quantity ?? 1,
      is_audio_book:
        "is_audio_book" in data ? Boolean(formData.is_audio_book) : false,
      is_paid_shipping:
        "is_paid_shipping" in data ? formData.is_paid_shipping : false,
    },
  });
  useEffect(() => watch(saveFormData).unsubscribe, [watch, saveFormData]);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);
  const { submitData, isPending: isPendingSubmit } =
    useSubmitFormData(CURRENT_STEP);

  const { redirectToPayment, isStripeLoading } = useRedirectToPayment();

  const includeAudioBook = watch("is_audio_book");
  const bookQuantity = watch("book_quantity");
  let expressShipping = watch("is_paid_shipping");

  const storedUrlParams = getAllParams();
  const partnerCode = storedUrlParams?.partner_code;
  const isPartnerCodeValid = Boolean(partnerCode);
  if (isPartnerCodeValid) {
    expressShipping = true;
  }

  const onSubmit = async () => {
    // if (import.meta.env.VITE_ENVIRONMENT !== "production") {
    //   throw new Error("Aborting order confirmation in dev mode");
    // }
    const orderCreated = await submitData();
    if (orderCreated) {
      setOpenedCheckout();
      redirectToPayment(
        includeAudioBook,
        expressShipping,
        bookQuantity,
        "order_customer_email" in formData
          ? (formData.order_customer_email as string)
          : formData.user_email,
        order_reference_id,
        partnerCode
      );
    }
  };

  const getTotal = () =>
    (
      (FIRST_BOOK_PRICE +
        (includeAudioBook ? AUDIO_BOOK_PRICE : 0) +
        (expressShipping ? EXPRESS_SHIPPING_PRICE : STANDARD_SHIPPING_PRICE)) /
      100
    ).toFixed(2);

  return (
    <Layout>
      <FormHeader
        title="Book Preview & Order"
        currentStep={CURRENT_STEP}
        onBack={goToPreviousStep}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <div className="flex w-full flex-col gap-3">
              <StorybookDetails data={formData} />
              <StorybookPreview data={formData} />

              <div className="w-100 flex flex-col gap-5 bg-white p-5 max-sm:-ml-5 max-sm:-mr-5">
                <div className="mb-3 mt-6 w-full text-center">
                  <h2 className="text-2xl font-medium leading-none tracking-tight">
                    Complete Your Order
                  </h2>

                  {!isPartnerCodeValid && <HowToOrderMultipleBooksDialog />}

                  {isPartnerCodeValid && (
                    <div className="mt-6">
                      <FullDiscountBanner />
                    </div>
                  )}
                </div>

                <ul className="flex flex-col gap-3">
                  <li className="flex flex-col gap-3">
                    <div className="flex flex-row items-center justify-between gap-2">
                      <div className="flex items-center gap-3">
                        <Book />
                        <p className="text-lg">{formData.hero_name}'s Book</p>
                      </div>
                      <p className="whitespace-nowrap">
                        {`${currencySymbol} ${(FIRST_BOOK_PRICE / 100).toFixed(2)}`}
                      </p>
                    </div>
                  </li>

                  <Divider />

                  <li className="flex flex-col gap-3">
                    <div className="flex flex-row items-center justify-between gap-2">
                      <div className="flex items-center gap-3">
                        <Headphones />
                        <p className="text-lg">Add Audiobook</p>
                      </div>
                      <p>{`${currencySymbol} ${AUDIO_BOOK_PRICE / 100}`}</p>
                    </div>
                    <div className="flex items-start justify-between gap-8">
                      <p className="font-light text-muted-foreground">
                        Listen to {formData.hero_name}'s Book stories.
                      </p>
                      <Checkmark
                        isChecked={includeAudioBook}
                        onClick={() =>
                          setValue("is_audio_book", !includeAudioBook)
                        }
                      />
                    </div>
                  </li>

                  <Divider />

                  <li className="flex flex-col gap-3">
                    <p className="my-2 text-xl leading-tight">Shipping Speed</p>

                    <div className="flex flex-col">
                      <DeliveryOption
                        title="Standard"
                        subtitle1="Estimated Delivery"
                        subtitle2={formatWorkingDaysRange(8, 16)}
                        price={STANDARD_SHIPPING_PRICE}
                        onClick={() => setValue("is_paid_shipping", false)}
                        checked={!expressShipping}
                        // Disable option if partner code is valid
                        className={
                          isPartnerCodeValid
                            ? "pointer-events-none opacity-50"
                            : ""
                        }
                      />
                      <DeliveryOption
                        title="Express"
                        subtitle1="Estimated Delivery (trackable)"
                        subtitle2={formatWorkingDaysRange(5, 13)}
                        price={EXPRESS_SHIPPING_PRICE}
                        onClick={() => setValue("is_paid_shipping", true)}
                        checked={expressShipping}
                      />
                    </div>
                  </li>

                  <Divider />

                  <li className="flex justify-between">
                    <p className="text-xl font-medium">Total to Pay:</p>
                    <p className="text-xl font-medium">
                      {currencySymbol} {getTotal()}
                    </p>
                  </li>
                </ul>
                <div className="flex justify-center">
                  <CheckoutButton
                    isLoading={isPendingSubmit || isStripeLoading}
                  />
                </div>

                <div className="flex flex-col gap-4 px-6 py-4">
                  <div className="flex items-center justify-center gap-3">
                    <HeartHandshake />
                    <p className="text-lg">100% Satisfaction Guaranteed</p>
                  </div>
                  <ol
                    role="list"
                    className="flex list-decimal flex-col gap-2 text-muted-foreground"
                  >
                    <li className="font-light">
                      Receive the entire digital book to review.
                    </li>
                    <li className="font-light">
                      We’ll make any changes you need.
                    </li>
                    <li className="font-light">
                      Love the book or get a{" "}
                      <a
                        className="font-light text-tertiary"
                        target="_blank"
                        href="https://www.blossomreads.com/return-policy"
                      >
                        full refund ↗
                      </a>
                      .
                    </li>
                  </ol>
                </div>
              </div>

              <CustomerReviews />
              <Examples gender={formData.hero_gender} />
              <StyleExamples />
            </div>
          </div>
        </FormBody>
      </form>

      {!formState.isSubmitting && !formState.isSubmitted && (
        <ContinueWhereYouLeftOff currentStep={CURRENT_STEP} />
      )}
    </Layout>
  );
}

const Checkmark = ({
  isChecked,
  onClick,
}: {
  isChecked: boolean;
  onClick: () => void;
}) => (
  <Button
    type="button"
    size="icon"
    className="min-w-10"
    variant={isChecked ? "default" : "outline"}
    onClick={onClick}
  >
    {isChecked ? <Check /> : <Plus />}
  </Button>
);

const Divider = () => (
  <Separator.Root
    orientation="horizontal"
    className="my-2 h-[1px] w-full bg-border"
  />
);

const CheckoutButton = ({ isLoading }: { isLoading: boolean }) => (
  <Button
    type="submit"
    className="h-12 w-full rounded-md text-lg"
    disabled={isLoading}
  >
    {isLoading ? (
      <LoaderCircle className="animate-spin" />
    ) : (
      "Proceed to Secure Checkout"
    )}
  </Button>
);

const DeliveryOption = ({
  title,
  subtitle1,
  subtitle2,
  price,
  onClick,
  checked,
  className,
}: {
  title: string;
  subtitle1: string;
  subtitle2?: string;
  price: number;
  onClick: () => void;
  checked: boolean;
  className?: string;
}) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <div
      className={cn(
        `flex cursor-pointer items-start justify-between gap-4 rounded-lg py-2`,
        className
      )}
      onClick={handleClick}
    >
      <div className="flex items-start gap-2">
        <div className="min-w-6">{checked ? <CircleCheck /> : <Circle />}</div>
        <div>
          <p>{title}</p>
          <p className="text-sm text-muted-foreground">{subtitle1}</p>
          <p className="text-sm text-muted-foreground">{subtitle2}</p>
        </div>
      </div>
      <p className="whitespace-nowrap">
        {currencySymbol} {(price / 100).toFixed(2)}
      </p>
    </div>
  );
};

const formatWorkingDaysRange = (startDays: number, endDays: number): string => {
  const startDate = addBusinessDays(new Date(), startDays);
  const endDate = addBusinessDays(new Date(), endDays);

  const formattedStartDate = format(startDate, "d MMMM");
  const formattedEndDate = format(endDate, "d MMMM");

  return `${formattedStartDate} - ${formattedEndDate}`;
};

const HowToOrderMultipleBooksDialog = () => (
  <Dialog buttonTitle="Need another book with 30% off?">
    <div className="grid gap-4">
      <div className="space-y-4">
        <DialogTitle>How to Order More Books</DialogTitle>
        <p>You can get 30% off more books once you've completed this order.</p>
        <p className="font-semibold">Here's how it works</p>
        <ul className="flex list-disc flex-col gap-3 pl-5 leading-tight">
          <li className="">
            We'll send you a discount code by email as soon as we receive your
            payment.
          </li>
          <li>The discount code is valid for 24 hours.</li>
          <li>You'll need to use it during checkout for your next order.</li>
          <li>All your books will be shipped together.</li>
        </ul>
        <p>It's as simple as that - happy reading!</p>
      </div>
    </div>
  </Dialog>
);
