import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { FormBody } from "@/components/FormBody";
import { FormHeader } from "@/components/FormHeader";
import { SubmitButton } from "@/components/SubmitButton";
import { ContinueWhereYouLeftOff } from "@/components/orderForm/continueWhereYouLeft";
import { ImageStep } from "@/components/orderForm/imageStep";
import { Layout } from "@/components/ui/Layout";
import { useToast } from "@/components/ui/use-toast";
import { STEPS } from "@/lib/config/steps";
import { queryKeys } from "@/lib/hooks/queryKeys";
import {
  startHeroImagesGeneration,
  useHeroImagesGeneration,
} from "@/lib/hooks/useHeroImages";
import { useSubmitFormData } from "@/lib/hooks/useSubmitFormData";
import {
  ChildDetails,
  childDetailsSchema,
} from "@/lib/schemas/childDetailsSchema";
import { useGlobalStore } from "@/lib/store/global";
import { useStepNavigation } from "@/lib/utils/useStepNavigation";

const CURRENT_STEP = STEPS.STEP_4 as keyof typeof STEPS;

export const MINIMUM_PHOTOS = 2;
export const MAXIMUM_PHOTOS = 5;

export function PhotosPage() {
  const { getFormData } = useGlobalStore();
  const data = getFormData(childDetailsSchema);
  const { goToPreviousStep } = useStepNavigation(CURRENT_STEP);

  useEffect(() => {
    if (!data) {
      goToPreviousStep();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return data ? <PhotosForm data={data} /> : null;
}

function PhotosForm({ data }: Readonly<{ data: ChildDetails }>) {
  const { goToPreviousStep, goToNextStep } = useStepNavigation(CURRENT_STEP);
  const { images, storyIdeas, order_reference_id } = useGlobalStore();
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [shouldGenerateHeroImages, setShouldGenerateHeroImages] =
    useState(false);
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const { submitData, isPending: isPendingSubmit } =
    useSubmitFormData(CURRENT_STEP);
  const {
    isStartHeroImagesGenerationLoading,
    // isStartHeroImagesGenerationSuccess,
    isStartHeroImagesGenerationError,
  } = useHeroImagesGeneration({
    start: shouldGenerateHeroImages,
  });

  // NOTE: Do not wait image generation to go to next step (see submitData bellow)
  // useEffect(() => {
  //   if (submitAttempted && isStartHeroImagesGenerationSuccess) {
  //     goToNextStep();
  //   }
  // }, [submitAttempted, isStartHeroImagesGenerationSuccess, goToNextStep]);

  useEffect(() => {
    if (shouldGenerateHeroImages && isStartHeroImagesGenerationError) {
      setShouldGenerateHeroImages(false);
      queryClient.cancelQueries({ queryKey: [queryKeys.GENERATE_HERO_IMAGES] });
    }
  }, [shouldGenerateHeroImages, isStartHeroImagesGenerationError, queryClient]);

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (images.length < MINIMUM_PHOTOS) {
      const missingImages = MINIMUM_PHOTOS - images.length;

      const getDescription = () => {
        if (missingImages === 1) {
          return "Please upload 1 more photo to continue.";
        }

        return `Please upload ${missingImages} photos to continue.`;
      };

      toast({
        title: "Missing Photos",
        description: getDescription(),
        duration: 5000,
      });

      return;
    }

    setSubmitAttempted(true);
    const isFormSubmitted = await submitData();
    if (isFormSubmitted) {
      // setShouldGenerateHeroImages(true);
      const photosInput = {
        story_title: storyIdeas.story_title_1,
        story_text: storyIdeas.story_summary_1,
        hero_gender: data.hero_gender,
        hero_image_urls: images.map((image) => image.presigned_url),
      };
      // startHeroImagesGeneration(order_reference_id, photosInput);
      queryClient.fetchQuery({
        queryKey: [
          queryKeys.GENERATE_HERO_IMAGES,
          order_reference_id,
          photosInput,
        ],
        queryFn: () =>
          startHeroImagesGeneration(order_reference_id, photosInput),
      });
      if (isStartHeroImagesGenerationError) {
        toast({
          title: "Error",
          description:
            "An error occurred while generating hero images. Please try again.",
          status: "error",
          duration: 5000,
        });
      }
      goToNextStep();
    }
  };

  const isUploadingImages = Object.values(images).some(
    (image) => image.s3_path === image.presigned_url
  );

  return (
    <Layout>
      <FormHeader
        title={`${data.hero_name}'s Photos`}
        currentStep={CURRENT_STEP}
        onBack={isUploadingImages ? undefined : goToPreviousStep}
      />
      <form onSubmit={onSubmit}>
        <FormBody>
          <div className="flex-1 lg:max-h-fit">
            <ImageStep
              images={images}
              isError={submitAttempted && images.length < MINIMUM_PHOTOS}
              heroName={data.hero_name}
            />
          </div>
          <SubmitButton
            disabled={isUploadingImages}
            isLoading={isPendingSubmit || isStartHeroImagesGenerationLoading}
          />
        </FormBody>
      </form>

      <ContinueWhereYouLeftOff currentStep={CURRENT_STEP} />
    </Layout>
  );
}
