"use client";

import {
  useCallback,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ChevronLeft,
  ChevronRight,
  Loader2,
  Plus,
  WandSparkles,
  X,
} from "lucide-react";
import { AnimatePresence, LayoutGroup, motion } from "motion/react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

interface Tag {
  value: string;
  label: string;
  isCustom?: boolean;
}

interface TagInputProps {
  /**
   * Enable or disable the collapsible tags feature
   * @default false
   */
  collapsible?: boolean;
  /**
   * Maximum number of visible tags when collapsed
   * @default 3
   */
  maxVisibleTags?: number;
  /**
   * Selected tags (controlled)
   */
  selectedTags?: Tag[];
  /**
   * Callback when selected tags change
   */
  onTagsChange?: (tags: Tag[]) => void;
  /**
   * Available tags for suggestions
   */
  availableTags?: Tag[];
  /**
   * Callback to get new suggestions
   */
  onSuggestionsRequest?: () => Promise<Tag[]>;
  /**
   * Custom input placeholder
   */
  placeholder?: string;
  /**
   * Label for suggestions section
   */
  suggestionsLabel?: React.ReactNode;
  /**
   * Layout style for the tag input
   * @default "default"
   */
  layout?: "default" | "compact";
  /**
   * Callback when compact input expanded state changes
   */
  onCompactInputExpandedChange?: (expanded: boolean) => void;
  /**
   * Hide suggestions section
   * @default false
   */
  hideSuggestions?: boolean;
  /**
   * Input field variant
   * @default "default"
   */
  variant?: "default" | "transparent";
  /**
   * Maximum length for tag input
   * @default 50
   */
  maxInputLength?: number;
  /**
   * Loading state for suggestions
   */
  isLoading?: boolean;
  /**
   * Allow tags to wrap to multiple lines instead of scrolling horizontally
   * @default false
   */
  wrapTags?: boolean;
  /**
   * Children elements
   */
  children?: React.ReactNode;
  /**
   * Enable or disable tag suggestion animations
   */
  shouldAnimateSuggestions?: boolean;
}

const tagMotion = {
  initial: { scale: 0.8, opacity: 0 },
  animate: ({ delay }: { delay: number } = { delay: 0 }) => ({
    scale: 1,
    opacity: 1,
    transition: { delay },
  }),
  exit: { scale: 0.8, opacity: 0 },
  transition: { type: "spring", stiffness: 1000, damping: 75 },
};

export function TagInput({
  collapsible = false,
  maxVisibleTags = 3,
  selectedTags: externalSelectedTags,
  onTagsChange,
  availableTags: externalAvailableTags,
  onSuggestionsRequest,
  placeholder = "Add a detail here...",
  suggestionsLabel,
  layout = "default",
  onCompactInputExpandedChange,
  hideSuggestions = false,
  variant = "default",
  maxInputLength = 50,
  isLoading: externalIsLoading = false,
  wrapTags = false,
  children,
  shouldAnimateSuggestions = true,
}: Readonly<TagInputProps>) {
  const uid = useId();
  const [inputValue, setInputValue] = useState("");
  const [internalSelectedTags, setInternalSelectedTags] = useState<Tag[]>([]);
  const [internalAvailableTags, setInternalAvailableTags] = useState<Tag[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showLeftGradient, setShowLeftGradient] = useState(false);
  const [showRightGradient, setShowRightGradient] = useState(false);
  const [suggestionsKey, setSuggestionsKey] = useState(0);
  const prevSuggestionsKeyRef = useRef(suggestionsKey);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const selectedTagsRef = useRef<HTMLDivElement>(null);
  const [isCompactInputExpanded, setIsCompactInputExpanded] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const selectedTags = externalSelectedTags || internalSelectedTags;
  const availableTags = externalAvailableTags || internalAvailableTags;

  // Dynamic tag variants that only animate when suggestionsKey changes
  const dynamicTagVariants = useMemo(() => {
    const shouldAnimate = prevSuggestionsKeyRef.current !== suggestionsKey;

    return {
      hidden: shouldAnimate
        ? { scale: 0.8, opacity: 0 }
        : { scale: 1, opacity: 1 },
      visible: {
        scale: 1,
        opacity: 1,
        transition: shouldAnimate
          ? {
              type: "spring",
              stiffness: 600,
              damping: 45,
            }
          : { duration: 0 },
      },
      exit: shouldAnimate
        ? {
            scale: 0.8,
            opacity: 0,
            transition: {
              duration: 0.2,
            },
          }
        : { scale: 1, opacity: 0, transition: { duration: 0.1 } },
      transition: { duration: 0.1 },
    };
  }, [suggestionsKey]);

  // Update the ref after render
  useEffect(() => {
    prevSuggestionsKeyRef.current = suggestionsKey;
  }, [suggestionsKey]);

  // Helper function to normalize tag values by removing category prefixes
  const normalizeTagValue = useCallback((value: string): string => {
    // Strip out category prefixes like [style], [theme], etc.
    return value
      .replace(/^\s*\[[^\]]+\]\s*/, "")
      .trim()
      .toLowerCase();
  }, []);

  const scrollToRight = useCallback(() => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      setTimeout(() => {
        container.scrollTo({
          left: container.scrollWidth,
          behavior: "smooth",
        });
      }, 50);
    }
  }, []);

  useEffect(() => {
    // Only update loading state from external prop without incrementing suggestionsKey
    // This prevents double animations when handleMoreSuggestions already incremented the key
    setIsLoading(externalIsLoading);

    // Only increment suggestionsKey when external loading starts
    // AND we're not already handling it internally
    if (externalIsLoading && !isLoading) {
      setSuggestionsKey((prev) => prev + 1);
    }
  }, [externalIsLoading, isLoading]);

  useEffect(() => {
    if (isExpanded) {
      scrollToRight();
    }
  }, [isExpanded, scrollToRight]);

  // Add a function to handle adding a new tag
  const addNewTag = useCallback(
    (value: string) => {
      const trimmedValue = value.trim();
      if (!trimmedValue) return false;

      // Check if the tag exceeds the maximum length
      if (trimmedValue.length > maxInputLength) return false;

      // Check if tag already exists, using normalized values
      const normalizedNewValue = normalizeTagValue(trimmedValue);
      const tagExists = [...availableTags, ...selectedTags].some(
        (tag) => normalizeTagValue(tag.value) === normalizedNewValue
      );

      if (!tagExists) {
        const customTag: Tag = {
          value: trimmedValue.toLowerCase(),
          label: trimmedValue,
          isCustom: true,
        };

        if (externalSelectedTags && onTagsChange) {
          onTagsChange([...externalSelectedTags, customTag]);
        } else {
          setInternalSelectedTags((current) => [...current, customTag]);
        }

        setInputValue("");
        setTimeout(scrollToRight, 100);
        return true;
      }

      return false;
    },
    [
      availableTags,
      externalSelectedTags,
      normalizeTagValue,
      onTagsChange,
      scrollToRight,
      selectedTags,
      maxInputLength,
    ]
  );

  // Adds a new tag when the input is unmounted
  // (e.g. when the user closes the details category)
  useEffect(() => {
    const input = inputRef.current;
    return () => {
      addNewTag(input?.value ?? "");
    };
  }, [addNewTag]);

  useEffect(() => {
    if (externalAvailableTags) {
      setInternalAvailableTags(externalAvailableTags);
    }
  }, [externalAvailableTags]);

  const filteredAvailableTags = useMemo(() => {
    const filtered = availableTags.filter((tag) => {
      // Normalize both tag values before comparing
      const normalizedTagValue = normalizeTagValue(tag.value);
      // Check if any selected tag matches this tag (after normalization)
      const isSelected = selectedTags.some((selectedTag) => {
        const normalizedSelectedValue = normalizeTagValue(selectedTag.value);
        return normalizedTagValue === normalizedSelectedValue;
      });
      return !isSelected;
    });
    return filtered;
  }, [availableTags, selectedTags, normalizeTagValue]);

  const checkScroll = useCallback(() => {
    if (wrapTags) {
      // No need to check scroll if we're wrapping tags
      setShowLeftGradient(false);
      setShowRightGradient(false);
      return;
    }

    const container = scrollContainerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      setShowLeftGradient(scrollLeft > 0);
      const maxScroll = scrollWidth - clientWidth;
      const isAtEnd =
        scrollWidth <= clientWidth || maxScroll - scrollLeft <= 15;
      setShowRightGradient(!isAtEnd);
    }
  }, [wrapTags]);

  useEffect(() => {
    setTimeout(() => {
      checkScroll();
    }, 100);
  }, [selectedTags, checkScroll]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      checkScroll();

      setTimeout(checkScroll, 100);

      const handleScroll = () => {
        requestAnimationFrame(checkScroll);
      };

      container.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", checkScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", checkScroll);
      };
    }
  }, [checkScroll]);

  const toggleTag = (tag: Tag, event: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const handleTagsChange = (current: Tag[]) => {
      const normalizedTagValue = normalizeTagValue(tag.value);
      const isAdding = !current.some(
        (t) => normalizeTagValue(t.value) === normalizedTagValue
      );

      const newTags = isAdding
        ? [...current, tag]
        : current.filter(
            (t) => normalizeTagValue(t.value) !== normalizedTagValue
          );

      if (isAdding) {
        setTimeout(scrollToRight, 100);
      }

      if (onTagsChange) {
        onTagsChange(newTags);
      }
      return newTags;
    };

    if (externalSelectedTags && onTagsChange) {
      onTagsChange(handleTagsChange(externalSelectedTags));
    } else {
      setInternalSelectedTags(handleTagsChange);
    }
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue && !isLoading) {
      e.preventDefault();
      addNewTag(inputValue);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        selectedTagsRef.current &&
        !selectedTagsRef.current.contains(event.target as Node)
      ) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("mouseup", handleClickOutside);
    return () => document.removeEventListener("mouseup", handleClickOutside);
  }, []);

  const visibleTags =
    !collapsible || isExpanded
      ? selectedTags
      : selectedTags.slice(0, maxVisibleTags);
  const hiddenTagsCount = collapsible
    ? selectedTags.length - maxVisibleTags
    : 0;

  const fetchNewSuggestions = async (): Promise<Tag[]> => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return [];
  };

  const handleMoreSuggestions = async (event: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    try {
      setIsLoading(true);

      if (onSuggestionsRequest) {
        const newSuggestions = await onSuggestionsRequest();

        if (!externalAvailableTags) {
          setInternalAvailableTags(newSuggestions);
        }

        setSuggestionsKey((prev) => prev + 1);
      } else {
        const newSuggestions = await fetchNewSuggestions();
        setInternalAvailableTags(newSuggestions);
        setSuggestionsKey((prev) => prev + 1);
      }
    } catch (error) {
      console.error("Failed to fetch suggestions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeTag = (tagToRemove: Tag, event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const handleTagRemoval = (current: Tag[]) => {
      const newTags = current.filter((tag) => tag.value !== tagToRemove.value);
      if (collapsible && newTags.length <= maxVisibleTags) {
        setIsExpanded(false);
      }
      return newTags;
    };

    if (externalSelectedTags && onTagsChange) {
      onTagsChange(handleTagRemoval(externalSelectedTags));
    } else {
      setInternalSelectedTags(handleTagRemoval);
    }
  };

  const toggleCompactInput = (event?: React.MouseEvent) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const newExpandedState = !isCompactInputExpanded;
    setIsCompactInputExpanded(newExpandedState);

    // Focus input when expanded
    if (newExpandedState) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }

    // Call the callback if provided
    if (onCompactInputExpandedChange) {
      onCompactInputExpandedChange(newExpandedState);
    }
  };

  const handleExpand = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsExpanded(true);

    // Focus input after expanding
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleCollapse = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setIsExpanded(false);
  };

  return (
    <div className="w-full max-w-2xl space-y-4">
      {layout === "compact" && (
        <div>
          <LayoutGroup id={`selected-tags-${uid}`}>
            <div
              className={cn(
                "w-full",
                !wrapTags && "column-gap-1 grid items-baseline",
                !wrapTags && { gridTemplateColumns: "auto 1fr" }
              )}
            >
              <div
                className={cn("relative", wrapTags ? "w-full" : "grid")}
                ref={selectedTagsRef}
              >
                {!wrapTags && (
                  <div
                    className={cn(
                      "pointer-events-none absolute bottom-0 left-0 top-0 z-10 w-8 bg-gradient-to-r from-white to-transparent transition-opacity duration-200",
                      showLeftGradient ? "opacity-100" : "opacity-0"
                    )}
                  />
                )}

                <motion.div
                  ref={scrollContainerRef}
                  className={cn(
                    "flex w-full max-w-full gap-2 pb-2",
                    wrapTags
                      ? "flex-wrap items-center"
                      : "scrollbar-thumb-border scrollbar-track-transparent hover:scrollbar-thumb-muted-foreground/30 overflow-x-auto overflow-y-hidden"
                  )}
                  style={wrapTags ? {} : scrollbarStyles}
                >
                  <AnimatePresence initial={false}>
                    {visibleTags.map((tag) => (
                      <motion.div
                        key={tag.value}
                        layout="position"
                        variants={dynamicTagVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="inline-flex h-8 shrink-0 select-none items-center gap-1 rounded-md border border-border bg-transparent pl-3 pr-1 text-sm text-foreground transition-colors hover:bg-transparent"
                      >
                        <span
                          className="max-w-[160px] select-none truncate"
                          title={tag.label}
                        >
                          {tag.label}
                        </span>
                        <button
                          onClick={(e) => removeTag(tag, e)}
                          className="pointer-events-auto -ml-2 -mr-1 flex h-full w-8 flex-shrink-0 items-center justify-center px-1.5 hover:text-primary"
                          disabled={isLoading}
                        >
                          <X className="h-3.5 w-3.5" />
                        </button>
                      </motion.div>
                    ))}

                    {collapsible && !isExpanded && hiddenTagsCount > 0 && (
                      <motion.button
                        layout="position"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.8, opacity: 0 }}
                        onClick={handleExpand}
                        className="inline-flex shrink-0 select-none items-center gap-1 rounded-md bg-secondary px-3 py-1 pr-2 text-sm text-secondary-foreground transition-colors hover:bg-secondary/80"
                      >
                        <span>{hiddenTagsCount} more</span>
                        <ChevronRight className="h-4 w-4" />
                      </motion.button>
                    )}

                    {collapsible && isExpanded && (
                      <motion.button
                        layout="position"
                        initial={{ scale: 0.8, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.8, opacity: 0 }}
                        onClick={handleCollapse}
                        className="inline-flex shrink-0 select-none items-center gap-1 rounded-md bg-secondary px-3 py-1 text-sm text-secondary-foreground transition-colors hover:bg-secondary/80"
                      >
                        <span>Show less</span>
                        <ChevronLeft className="h-4 w-4" />
                      </motion.button>
                    )}
                  </AnimatePresence>

                  {wrapTags && (
                    // <motion.div
                    //   layout="position"
                    //   transition={{ duration: 0.35 }}
                    // >
                    <Button
                      key={isCompactInputExpanded ? "hide" : "add"}
                      variant="ghost"
                      size="sm"
                      onClick={toggleCompactInput}
                      className={cn(
                        "flexitems-center border-1 flex h-auto items-center gap-1 border border-primary/40 bg-primary/5 px-2 py-1 pr-1.5 text-primary transition-all hover:bg-white/60",
                        isCompactInputExpanded &&
                          "border-secondary-foreground/40 bg-secondary-foreground/5 text-secondary-foreground hover:text-secondary-foreground"
                      )}
                    >
                      <span>{isCompactInputExpanded ? "Hide" : "Add"}</span>
                      {isCompactInputExpanded ? (
                        <X className="h-4 w-4" />
                      ) : (
                        <Plus className="h-4 w-4" />
                      )}
                    </Button>
                    // </motion.div>
                  )}
                </motion.div>

                {!wrapTags && (
                  <div
                    className={cn(
                      "pointer-events-none absolute bottom-0 right-0 top-0 z-10 w-8 bg-gradient-to-l from-white to-transparent transition-opacity duration-200",
                      showRightGradient ? "opacity-100" : "opacity-0"
                    )}
                  />
                )}
              </div>

              {!wrapTags && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={toggleCompactInput}
                  className="ml-2 flex items-center gap-1 justify-self-start px-0"
                >
                  <span className="ml-1">
                    {isCompactInputExpanded ? "Hide" : "Add"}
                  </span>
                  {isCompactInputExpanded ? (
                    <X className="h-4 w-4" />
                  ) : (
                    <Plus className="h-4 w-4" />
                  )}
                </Button>
              )}
            </div>
          </LayoutGroup>

          <AnimatePresence>
            {isCompactInputExpanded && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ type: "spring", stiffness: 1000, damping: 75 }}
                className="overflow-hidden"
                onAnimationComplete={(definition) => {
                  if (definition === "animate") {
                    inputRef.current?.focus();
                  }
                }}
              >
                <div className="flex gap-2">
                  <div className="relative flex-1">
                    <Input
                      ref={inputRef}
                      placeholder={placeholder}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={handleInputKeyDown}
                      maxLength={maxInputLength}
                      className={cn(
                        "text-lg focus:ring-0",
                        variant === "transparent"
                          ? "mx-0 rounded-none border-x-0 border-b border-t-0 px-0 pr-6 focus:border-primary/40"
                          : "focus:border-primary/40"
                      )}
                      style={variant === "transparent" ? { width: "100%" } : {}}
                    />
                    <AnimatePresence>
                      {inputValue.length > 0 && (
                        <motion.button
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.8 }}
                          onClick={(e) => {
                            if (e) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                            addNewTag(inputValue);
                          }}
                          className="absolute right-0 top-1 -mr-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary/10 px-2 hover:bg-primary/15 hover:text-primary"
                        >
                          <Plus className="h-5 w-5 text-primary" />
                        </motion.button>
                      )}
                    </AnimatePresence>
                  </div>
                </div>

                {!hideSuggestions && (
                  <div className="mt-4 flex flex-col gap-3">
                    {suggestionsLabel && (
                      <p className="font-light leading-tight text-secondary-foreground">
                        {suggestionsLabel}
                      </p>
                    )}

                    <div
                      key={suggestionsKey}
                      className={cn(
                        "flex flex-wrap items-center gap-2",
                        isLoading && "pointer-events-none opacity-50"
                      )}
                    >
                      <LayoutGroup>
                        <AnimatePresence mode="popLayout">
                          {filteredAvailableTags.map((tag, i) => (
                            <motion.div
                              key={`tag-${tag.value}`}
                              initial={
                                shouldAnimateSuggestions
                                  ? { opacity: 0 }
                                  : false
                              }
                              animate="animate"
                              exit="exit"
                              variants={tagMotion}
                              custom={{ delay: 0.1 * i }}
                              layout="position"
                            >
                              <Button
                                key={`${tag.value}.${suggestionsKey}`}
                                variant="outline"
                                size="sm"
                                onClick={(e) => toggleTag(tag, e)}
                                disabled={isLoading}
                                className="h-8 select-none transition-colors"
                              >
                                {tag.label}
                              </Button>
                            </motion.div>
                          ))}

                          {onSuggestionsRequest && (
                            <motion.div
                              layout="position"
                              layoutId={uid}
                              className="z-10"
                              initial={
                                shouldAnimateSuggestions
                                  ? { opacity: 0 }
                                  : false
                              }
                              animate={{
                                opacity: 1,
                                transition: { delay: 0.5 },
                              }}
                              exit={{ opacity: 0 }}
                            >
                              <Button
                                key={`new-ideas.${uid}`}
                                variant="default"
                                size="sm"
                                onClick={handleMoreSuggestions}
                                disabled={isLoading || externalIsLoading}
                                className={cn(
                                  "transition-color flex h-8 select-none items-center gap-2 border border-primary/40 bg-[#fef8f7] text-primary hover:bg-white",
                                  externalIsLoading &&
                                    "pointer-events-none opacity-50"
                                )}
                              >
                                {isLoading || externalIsLoading ? (
                                  <Loader2 className="-ml-[0.1em] h-4 w-4 animate-spin" />
                                ) : (
                                  <WandSparkles className="-ml-[0.1em] h-4 w-4" />
                                )}

                                <span>
                                  {isLoading || externalIsLoading
                                    ? "Thinking..."
                                    : "New ideas"}
                                </span>
                              </Button>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </LayoutGroup>
                    </div>
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      )}

      {layout === "default" && (
        <>
          {!wrapTags && (
            <div className="mt-1 flex gap-2">
              <div className="relative flex-1">
                <Input
                  ref={inputRef}
                  placeholder={placeholder}
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleInputKeyDown}
                  maxLength={maxInputLength}
                  className={cn(
                    "text-lg focus:ring-0",
                    variant === "transparent"
                      ? "mx-0 rounded-none border-x-0 border-b border-t-0 px-0 focus:border-primary/40"
                      : "focus:border-primary/40",
                    // when plus button is visible, add padding to input
                    inputValue.length > 0 && "pr-8"
                  )}
                  style={variant === "transparent" ? { width: "100%" } : {}}
                />
                <AnimatePresence>
                  {inputValue.length > 0 && (
                    <motion.button
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.8 }}
                      onClick={(e) => {
                        if (e) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                        addNewTag(inputValue);
                      }}
                      className="absolute right-0 top-1 -mr-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary/10 px-2 hover:bg-primary/15 hover:text-primary"
                    >
                      <Plus className="h-5 w-5 text-primary" />
                    </motion.button>
                  )}
                </AnimatePresence>
              </div>
            </div>
          )}

          <LayoutGroup id={`selected-tags-${uid}`}>
            <div
              className={cn(
                "relative",
                selectedTags.length === 0 && !wrapTags && "hidden"
              )}
              ref={selectedTagsRef}
            >
              {!wrapTags && (
                <div
                  className={cn(
                    "pointer-events-none absolute bottom-0 left-0 top-0 z-10 w-8 bg-gradient-to-r from-white to-transparent transition-opacity duration-200",
                    showLeftGradient ? "opacity-100" : "opacity-0"
                  )}
                />
              )}

              <motion.div
                ref={scrollContainerRef}
                className={cn(
                  "flex w-full max-w-full gap-2 pb-2",
                  wrapTags
                    ? "flex-wrap items-center"
                    : "scrollbar-thumb-border scrollbar-track-transparent hover:scrollbar-thumb-muted-foreground/30 overflow-x-auto overflow-y-hidden"
                )}
                style={wrapTags ? {} : scrollbarStyles}
              >
                <AnimatePresence initial={false}>
                  {visibleTags.map((tag) => (
                    <motion.div
                      key={tag.value}
                      layout="position"
                      variants={dynamicTagVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      className="inline-flex h-8 shrink-0 select-none items-center gap-1 rounded-md border border-border bg-transparent pl-3 pr-1 text-sm text-foreground transition-colors hover:bg-transparent"
                    >
                      <span
                        className="max-w-[100%] select-none truncate"
                        title={tag.label}
                      >
                        {tag.label}
                      </span>
                      <button
                        onClick={(e) => removeTag(tag, e)}
                        className="pointer-events-auto -ml-2 -mr-1 flex h-full w-8 flex-shrink-0 items-center justify-center px-1.5 hover:text-primary"
                        disabled={isLoading}
                      >
                        <X className="h-3.5 w-3.5" />
                      </button>
                    </motion.div>
                  ))}

                  {collapsible && !isExpanded && hiddenTagsCount > 0 && (
                    <motion.button
                      layout="position"
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.8, opacity: 0 }}
                      onClick={handleExpand}
                      className="inline-flex shrink-0 select-none items-center gap-1 rounded-md bg-secondary px-3 py-1 pr-2 text-sm text-secondary-foreground transition-colors hover:bg-secondary/80"
                    >
                      <span>{hiddenTagsCount} more</span>
                      <ChevronRight className="h-4 w-4" />
                    </motion.button>
                  )}

                  {collapsible && isExpanded && (
                    <motion.button
                      layout="position"
                      initial={{ scale: 0.8, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.8, opacity: 0 }}
                      onClick={handleCollapse}
                      className="inline-flex shrink-0 select-none items-center gap-1 rounded-md bg-secondary px-3 py-1 text-sm text-secondary-foreground transition-colors hover:bg-secondary/80"
                    >
                      <span>Show less</span>
                      <ChevronLeft className="h-4 w-4" />
                    </motion.button>
                  )}
                </AnimatePresence>
                {wrapTags && (
                  <div className="relative my-1 h-8 min-w-[8rem] max-w-xs flex-shrink">
                    <Input
                      ref={inputRef}
                      placeholder={placeholder}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={handleInputKeyDown}
                      maxLength={maxInputLength}
                      className="h-8 w-full px-2 text-sm focus:ring-0"
                    />
                    <AnimatePresence>
                      {inputValue.length > 0 && (
                        <motion.button
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.8 }}
                          onClick={(e) => {
                            if (e) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                            addNewTag(inputValue);
                          }}
                          className="absolute right-0 top-1 -mr-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary/10 px-2 hover:bg-primary/15 hover:text-primary"
                        >
                          <Plus className="h-4 w-4 text-primary" />
                        </motion.button>
                      )}
                    </AnimatePresence>
                  </div>
                )}
              </motion.div>

              {!wrapTags && (
                <div
                  className={cn(
                    "pointer-events-none absolute bottom-0 right-0 top-0 z-10 w-8 bg-gradient-to-l from-white to-transparent transition-opacity duration-200",
                    showRightGradient ? "opacity-100" : "opacity-0"
                  )}
                />
              )}
            </div>

            {selectedTags.length === 0 && wrapTags && (
              <div className="relative w-full">
                <div className="flex flex-wrap items-center gap-2">
                  <div className="relative my-1 h-8 min-w-[8rem] max-w-xs">
                    <Input
                      ref={inputRef}
                      placeholder={placeholder}
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)}
                      onKeyDown={handleInputKeyDown}
                      maxLength={maxInputLength}
                      className="h-8 w-full px-2 text-sm focus:ring-0"
                    />
                    <AnimatePresence>
                      {inputValue.length > 0 && (
                        <motion.button
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.8 }}
                          onClick={(e) => {
                            if (e) {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                            addNewTag(inputValue);
                          }}
                          className="absolute right-0 top-1 -mr-1 flex h-8 w-8 items-center justify-center rounded-full bg-primary/10 px-2 hover:bg-primary/15 hover:text-primary"
                        >
                          <Plus className="h-4 w-4 text-primary" />
                        </motion.button>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              </div>
            )}
          </LayoutGroup>

          {!hideSuggestions && (
            <div className="flex flex-col gap-3">
              {suggestionsLabel && (
                <p className="font-light leading-tight text-secondary-foreground">
                  {suggestionsLabel}
                </p>
              )}

              <div
                key={suggestionsKey}
                className={cn(
                  "flex flex-wrap items-center gap-2",
                  isLoading && "pointer-events-none opacity-50"
                )}
              >
                <LayoutGroup>
                  <AnimatePresence mode="popLayout">
                    {filteredAvailableTags.map((tag, i) => (
                      <motion.div
                        key={`tag-${tag.value}`}
                        initial={shouldAnimateSuggestions ? "initial" : false}
                        animate="animate"
                        exit="exit"
                        variants={tagMotion}
                        custom={{ delay: 0.1 * i }}
                        layout="position"
                      >
                        <Button
                          key={`${tag.value}.${suggestionsKey}`}
                          variant="outline"
                          size="sm"
                          onClick={(e) => toggleTag(tag, e)}
                          disabled={isLoading}
                          className="h-8 select-none transition-colors"
                        >
                          {tag.label}
                        </Button>
                      </motion.div>
                    ))}

                    {onSuggestionsRequest && (
                      <motion.div
                        layout="position"
                        layoutId={uid}
                        className="z-10"
                        initial={
                          shouldAnimateSuggestions ? { opacity: 0 } : false
                        }
                        animate={{
                          opacity: 1,
                          transition: { delay: 0.5 },
                        }}
                        exit={{ opacity: 0 }}
                      >
                        <Button
                          key={`new-ideas.${uid}`}
                          variant="default"
                          size="sm"
                          onClick={handleMoreSuggestions}
                          disabled={isLoading || externalIsLoading}
                          className={cn(
                            "transition-color flex h-8 select-none items-center gap-2 border border-primary/40 bg-[#fef8f7] text-primary hover:bg-white",
                            externalIsLoading &&
                              "pointer-events-none opacity-50"
                          )}
                        >
                          {isLoading || externalIsLoading ? (
                            <Loader2 className="-ml-[0.1em] h-4 w-4 animate-spin" />
                          ) : (
                            <WandSparkles className="-ml-[0.1em] h-4 w-4" />
                          )}

                          <span>
                            {isLoading || externalIsLoading
                              ? "Thinking..."
                              : "New ideas"}
                          </span>
                        </Button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </LayoutGroup>
              </div>
            </div>
          )}

          {children}
        </>
      )}
    </div>
  );
}

const scrollbarStyles = {
  scrollBehavior: "smooth",
  scrollbarGutter: "stable",
  scrollbarWidth: "thin",
  scrollbarColor: "rgba(0,0,0,0.1) transparent",
  scrollPaddingInlineEnd: "1rem",
  ["&::WebkitScrollbar"]: {
    "-webkit-appearance": "none",
    width: "4px",
  },
  ["&::WebkitScrollbarThumb"]: {
    backgroundColor: "rgba(0,0,0,0.1)",
    borderRadius: "4px",
  },
  ["&::WebkitScrollbarTrack"]: {
    backgroundColor: "transparent",
  },
};
